import {
  analyticsService,
  mobileBridgeService,
} from '@bfa/nextjs-common/services';
import { AnalyticsEvent, logError } from '@bfa/nextjs-common/utils';
import { useEffect } from 'react';

/**
 * Fetches any queued clickstream events from the mobile app and sends them.
 */
const useFireQueuedCsEventsFromMobileApp = (): void => {
  const fetchQueuedCsEventsFromMobileApp = async () => {
    try {
      // Sends message to native app on mount to fetch queued cs events.
      const {
        csEvents,
      }: {
        csEvents: AnalyticsEvent[];
      } = await mobileBridgeService.sendMessageToNativeApp(
        'SEND_QUEUED_CS_EVENTS'
      );
      if (csEvents) {
        Object.values(csEvents).forEach((eventToSend) => {
          analyticsService.logEvent(eventToSend);
        });
      }
    } catch (error: any) {
      logError('[useFireQueuedCsEvents]', error);
    }
  };

  useEffect(() => {
    fetchQueuedCsEventsFromMobileApp();
  }, []);
};

export default useFireQueuedCsEventsFromMobileApp;
