/**
 * This file exports cookie name constants, which should match the definition in ipsy-util.
 *
 * @see {https://github.com/myglam/ipsy-util/blob/master/src/groovy/com/ipsy/web/CookieNames.groovy}
 */

// Copied from https://github.com/myglam/ipsy-grails-web/blob/master/grails-app/services/com/ipsy/security/AccountService.groovy#L86
export const SESSION_TIMEOUT_SECONDS = 30 * 60; // 30 minutes
export const TTL_24_HOURS_IN_SECONDS = 60 * 60 * 24; // 24 hours
export const GVS_COOKIE_RESET_SECONDS = 5 * 60;
export const ADOBE_INDIVIUAL_EXPERIENCE_EXPIRE_MS =
  6 * 30 * 24 * 60 * 60 * 1000;
export const ADOBE_EXPERINCE_COOKIE_SECONDS = 365 * 24 * 60 * 60;

enum CookieName {
  /** Lpid stands for Lading Page ID, splash page shows different contents based on lpid. */
  LPID_COOKIE_NAME = 'lpid',
  /** Used to track cid and sid of user. Expires after 15mins. */
  IPCUR = 'ipcur',
  REFER_COOKIE_NAME = 'ippntref',
  /** Cookie to identify admin login. */
  IS_ADMIN_LOGIN_AS = 'ip_admin_login_as',
  WISH_LIST_EXPERIENCE = 'wish_list_experience',
  /** Banner related cookies. */
  FREE_BAG_INVALID_BANNER_COOKIE = 'ip_fb_invalid_banner',
  /** Experiment related cookies.  */
  FORCE_PARTICIPATION_COOKIE = 'ipsy_force_experiments',
  EXPERIMENTS = 'ipsy_experiments',
  IPSTR = 'ipstr',
  /** Device info related cookies. */
  DEVICE = 'x-ipsy-device',
  PLATFORM_TYPE = 'x-ipsy-platform_type',
  DEVICE_UNIQUE_ID = 'x-ipsy-device-unique-id',
  DEVICE_MODEL_NAME = 'x-ipsy-device-model-name',
  PLATFORM_VERSION = 'x-ipsy-platform_version',
  DEVICE_MODEL_ID = 'x-ipsy-device-model-identifier',
  IDFA = 'x-ipsy-idfa',
  IDFV = 'x-ipsy-idfv',
  CSRF = 'x-ipsy-csrf',
  IPSY_EXPERIMENTS = 'x-ipsy-experiments',
  FORTER_TOKEN = 'forterToken',
  COHORTS = 'assigned_cohorts',
  PLATFORM_OS = 'x-ipsy-platform_os',
  /** Free bag invite, cookie value is a free bag invite id. */
  IPSY_FREE_BAG_INVITE_COOKIE = 'ip_fb_inv',
  /** Free bag promotion campaign. */
  IPSY_FREE_BAG_PROMO_COOKIE = 'ip_fb_promo',
  /** Gift card id that comes from email invite link. */
  IPSY_GIFT_CARD_ID_COOKIE = 'received_gift_card_id',
  /** Custom cookie to indicate if user is getting a referral gift. */
  IPSY_REFERRAL_GIFT = 'ip_referral_gift',
  /** Custom cookie to indicate if we need to use next image optimization. */
  NEXT_IMAGE_OPTIMIZATION_ENABLED = 'next_image_optimization_enabled',
  /** Custom cookie to indicate if will show 'new' badge text on refreshments navbar item. */
  REFRESHMENT_NEW_BADGE = '_isRefreshmentsNew',
  /** Custom cookie to indicate the number of finished sharing. */
  REFRESHMENT_SHARING_STEP_COOKIE = 'refreshment_sharing_step',
  /** Auth cookie. */
  IPSTKN = 'ipstkn',
  IP_LH = 'ip_lh',
  TOGGLED_SUBSCRIPTION_PROGRAM = 'toggled_subscription_program',
  LAST_VISITED_MEMBERSHIP_TAB = 'last_visited_membership_tab',
  SIGNAL_INTAKE_PRODUCTS = 'signal_intake_products',
  SIGNAL_INTAKE_MODAL = 'signal_intake_modal',

  /** Sub confirmation cookies. */
  SUB_CONFIRMATION_SUCCESS_PROGRAM = 'ip_sub_confirmation_successful_programs',
  SUB_CONFIRMATION_UNSUCCESS_PROGRAM = 'ip_sub_confirmation_unsuccessful_programs',
  SUB_CONFIRMATION_FLOW = 'ip_sub_confirmation_flow',
  SUB_CONFIRMATION_GIFT = 'ip_sub_confirmation_gift',
  SUB_CONFIRMATION_GIFT_BANNER = 'ip_sub_confirmation_gift_banner',
  SUB_CONFIRMATION_PROGRAM = 'ip_sub_confirmation_program',
  SUB_CONFIRMATION_STEPS = 'ip_sub_confirmation_steps',

  /** Custom cookie that takes values from SUB_CONFIRMATION_SUCCESS_PROGRAM to use on Share page. */
  SUB_CONFIRMATION_SUBSCRIBED_PROGRAM = 'ip_sub_confirmation_subscribed_programs',

  /** Custom cookie to indicate which url did user land on one-click page (Upscale experiment). */
  SUB_UPSALE_FLOW = 'ip_sub_upsale_flow',

  /** Sub share cookies. */
  SUBSCRIPTION_SHARE_ACCESSIBLE = 'ip_sub_share_accessible',
  /** Custom cookie that evalues if user comes from upsale flow. */
  SUBSCRIPTION_UPSALE_THANK_YOU = 'ip_sub_upsale_thank_you',
  /** Custom cookie that contains gift card receiver's gift card id. */
  RECEIVED_GIFT_CARD_COOKIE = 'received_gift_card_id',
  /** Custom cookie that stores program and plan. */
  QUIZ_CHOOSE_PACKAGE_TYPE = 'quiz_choose_package_type',
  AFTERPAY_PAYMENT_ID = 'payment_method_id',
  /** Beauty Boost Opt Out Flow. */
  OPT_OUT_STATUS = 'opt_out_status',
  /** Custom cookie that stores the gift product id for the new SPA CPP. */
  CPP_GIFT = 'cpp_gift_pid',
  /** Session custom cookie created after a successful login containing the exact timestamp it happened */
  LAST_LOGIN_TIMESTAMP = 'last_login_timestamp',
  /** User id in cookie for adobe personalized flow */
  ADOBE_TARGET_THIRD_PARTY_COOKIE = 'adobeTargetThirdPartyId',
  /** set adobe configired experiencs in cookie */
  ADOBE_TARGET_IPSY_EXPERIENCE_COOKIE = 'adobeTargetIpsyExperiences',
  BUSINESS_ID = 'business-id',
  /** Custom cookie that indicates that the user completed choice successfully **/
  CHOICE_SUCCESSFULLY_COMPLETED = 'choice_successfully_completed',
  CHOICE_FORCE_EXPERIENCE = 'choice_force_experience',
  REACTIVATE_GIFT = 'reactivate_gift',
  TIKTOK_COOKIE = '_ttp',
  CAPI = 'conversion_api',
  SNAPCHAT = '_scid',
  PINTEREST = '_epik',
  FACEBOOK_CLICK_IDENTIFIER = '_fbc',
  FACEBOOK_BROWSER_IDENTIFIER = '_fbp',
  UPDATE_PAYMENT_FAILURE_REDIRECT = 'update_payment_failure_redirect',
  ANONYMOUS_ID = 'ajs_anonymous_id',
}

export default CookieName;
