import { CampaignResponse } from '../../api/iwc';
import { CAMPAIGN_NAMES } from '../../constants';

/**
 * Different Referral Types exist according to the campaigns.
 */
export enum ReferralType {
  FREE_GIFT,
  ONE_FREE_CREDIT,
  POINTS,
}

/**
 * Matches a campaign name to a referral type.
 *
 * @param res - The response from campaign.
 * @param campaignName - A particular referral campaign name.
 * @param referralType - The referral type to return if a match is found.
 * @returns The referral type provided.
 */
const matchReferralType = (
  res: CampaignResponse,
  campaignName: string,
  referralType: ReferralType
): ReferralType | undefined =>
  res.allCampaignData.find((cmp) => cmp.name === campaignName)
    ? referralType
    : undefined;

/**
 * Function called when a referrer param was provided.
 * It will match with a Referral type according to the campaign name.
 *
 * @param response - The response from campaign.
 * @returns A Referral type.
 */
export const getReferralType = (response: CampaignResponse): ReferralType =>
  matchReferralType(
    response,
    CAMPAIGN_NAMES.REFERRAL_GIFT,
    ReferralType.FREE_GIFT
  ) ??
  matchReferralType(
    response,
    CAMPAIGN_NAMES.REFERRAL_FREE_CREDIT,
    ReferralType.ONE_FREE_CREDIT
  ) ??
  ReferralType.POINTS;
