import { Button, Container } from '@bfa/common-ui';
import React from 'react';

type Props = {
  title: string;
  description: React.ReactNode;
  onClick: () => void;
  buttonText: string;
};

const MessagePageLayout = ({
  title,
  description,
  onClick,
  buttonText,
}: Props) => (
  <div className="bg-white height-full">
    <Container textAlign="center" pt="30">
      <h2 className="margin-bot">{title}</h2>
      <p className="margin-bot">{description}</p>
      <Button main large onClick={onClick}>
        {buttonText}
      </Button>
    </Container>
  </div>
);

export default MessagePageLayout;
