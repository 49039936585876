import { Config } from '@bfa/next-app-config';
import { request } from '@bfa/nextjs-common/utils';
import { ParsedUrlQuery } from 'querystring';

type CampaignRequestBody = {
  // eslint-disable-next-line camelcase
  campaign_id: string;
  // eslint-disable-next-line camelcase
  source_id: string;
  params: Record<string, any>;
};

export type CampaignCookie = {
  name: string;
  value: string;
  // eslint-disable-next-line camelcase
  max_age: number;
};

export type Campaign = {
  id?: string;
  name?: string;
  // eslint-disable-next-line camelcase
  cookie_data?: CampaignCookie[];
  [key: string]: any;
};

export type CampaignRawResponse = {
  // eslint-disable-next-line camelcase
  all_campaign_data: Campaign[];
};

export type CampaignResponse = {
  allCampaignData: Campaign[];
};

class IwcApi {
  private baseUrl: string = Config.public.misc.iwcApiBaseUrl;

  postCampaign = async (
    headers: Record<string, string>,
    page?: string,
    cid = '',
    sid = '',
    params: ParsedUrlQuery = {}
  ): Promise<CampaignResponse> => {
    const data = {
      campaign_id: cid,
      source_id: sid,
      params: {
        page,
        ...params,
      },
    };

    const response = await request.post<CampaignRequestBody>(
      `${this.baseUrl}/campaigns`,
      data,
      {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
      }
    );

    // eslint-disable-next-line camelcase
    const { all_campaign_data } = await response.json();

    return { allCampaignData: all_campaign_data };
  };

  async setSpoilerAlert(
    userId: string,
    program: string,
    cycle: string
  ): Promise<number> {
    try {
      console.log(
        `INFO: calling: ${this.baseUrl}/spoiler/saveSpoilerAlertEvent/${program}/${cycle} for ${userId}`
      );
      const response = await request.post(
        `${this.baseUrl}/spoiler/saveSpoilerAlertEvent/${program}/${cycle}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Ipsy-User-Id': userId,
          },
        }
      );
      return response.status;
    } catch (e) {
      return 422;
    }
  }

  referUser = async (
    referredUserId: string,
    referId: string,
    referralType: string
  ): Promise<string> => {
    const response = await request.post(
      `${this.baseUrl}/referred-users/${referredUserId}/refer-ids/${referId}/referral-types/${referralType}`
    );
    return response.text();
  };
}

export default new IwcApi();
