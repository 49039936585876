/*
NextJS is a framework that allows you to build Static and Server Side Rendered Apps.
It uses NodeJS to render your application and window is not defined in NodeJS.
That means that we need to be careful that our code that accesses the window object is not run in NodeJS.
*/

export const isClient = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
