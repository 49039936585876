import { cookieService } from '@bfa/nextjs-common/services';
import {
  getAuthHeaders,
  getAuthInfo,
  getFirstParam,
  logError,
} from '@bfa/nextjs-common/utils';
import { AppContext } from 'next/app';

import iwcApi, { CampaignResponse } from '../../api/iwc';
import getGvsVar from '../../graphql/utils/getGvsVar';
import CookieName from '../../utils/cookies/cookieName';
import { HeaderName } from '../../utils/types';
import { redirectToSubscribeIfNeeded } from './giftWithSub';
import { setReferralGiftCookie } from './referralGift';
import { getReferralType, ReferralType } from './referrer';
import setCampaignCookies from './setCampaignCookies';

const PUBLIC_DEPRECATE_CAMPAIGNS_CALLS = 'PUBLIC_DEPRECATE_CAMPAIGNS_CALLS';

/**
 * Campaign Middleware.
 * Handles all the redirection, parameters and API call referred to campaigns.
 * If multiple cid/sid or other params are provided the first one will be used.
 * Examples: /?cid=a&cid=b -> "a" will be used.
 *
 * For GiftWithSub:
 * > If the current sid or cid belong to any of the gift with subscription campaign specified,
 * > tries to store their information in the session.
 *
 * For Referrer:
 * > Call Referrer if the campaign is of referral type.
 *
 * For FreeBag:
 * > Renames the freeBagInvite to match the API.
 *
 * @param ctx - AppContext object.
 */
const campaignMiddleware = async (ctx: AppContext) => {
  const { refer, freeBagInvite, freeBagPromoCode, ...query } = ctx.ctx.query;

  const referId = getFirstParam(refer);
  const cid = '';
  const sid = '';

  try {
    const page = ctx.Component.displayName?.toLowerCase();
    const userId = getAuthInfo(ctx.ctx)?.userId;
    const freeBagInviteId = getFirstParam(freeBagInvite);
    const promoCode = getFirstParam(freeBagPromoCode);
    const headers = {
      ...getAuthHeaders(ctx.ctx),
    };

    headers[HeaderName.COOKIE] = ctx.ctx.req?.headers.cookie ?? '';
    headers[HeaderName.EXPERIMENTS] =
      (ctx.ctx.req?.headers[HeaderName.EXPERIMENTS] as string) ?? '';

    if (referId) {
      const stopCampaignsCallFlag = await getGvsVar(
        PUBLIC_DEPRECATE_CAMPAIGNS_CALLS,
        ctx.ctx
      );
      let referralType = 'POINTS';
      let response: CampaignResponse = { allCampaignData: [] };
      if (stopCampaignsCallFlag.toUpperCase() !== 'ON') {
        response = await iwcApi.postCampaign(headers, page, cid, sid, {
          ...query,
          freeBagInviteId,
          promoCode,
        });

        referralType = ReferralType[getReferralType(response)];
      }

      if (userId) {
        await iwcApi.referUser(userId, referId, referralType);
      } else {
        const timestamp = Date.now();

        cookieService.setCookie(
          ctx.ctx,
          CookieName.REFER_COOKIE_NAME,
          `${refer}|${timestamp}|${referralType}`
        );
      }

      if (stopCampaignsCallFlag.toUpperCase() !== 'ON') {
        setCampaignCookies(ctx, response);
        setReferralGiftCookie(ctx, response);
      }
    }
  } catch (error) {
    logError(error);
  }
};

export default campaignMiddleware;
