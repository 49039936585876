import { HeaderPropTypes } from '@bfa/common-ui/dist/types/header';

import { ABSOLUTE_URLS } from '../constants';

export const headerBaseProps: HeaderPropTypes = {
  getLogoUrl: () => '/',
  getLoginUrl: () => '/login',
  getJoinUrl: () => '/join-now',
  getShopUrl: () => '/shop',
  getCartUrl: () => '/cart',
  getPointsUrl: () => '/points?refFrom=navigationPointsbutton',
  getAccountUrl: () => '/account',
  getGlambagUrl: () => ABSOLUTE_URLS.GLAM_BAG,
  getGiftUrl: () => '/gifting',
};

export const headerBrandOfferLog = {
  label: 'Promo Brand Offers',
};
