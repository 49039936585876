import { FaviconsData } from '@bfa/common-ui/dist/components/Favicons';
import { logError } from '@bfa/nextjs-common/utils';
import { NextPageContext } from 'next';

import createApolloClient from '../../apolloClient';
import { GQLOgMetaData } from '../../gql.generated';
import GET_DOCUMENT_META_DATA from '../queries/GET_DOCUMENT_META_DATA';

type LayoutContent = { ogMetaData: GQLOgMetaData } & FaviconsData;

type DocumentMetaDataQuery = {
  me: {
    layout: LayoutContent;
  };
};

const getDocumentMetaData = async (
  ctx?: NextPageContext,
  queryParams?: string
): Promise<LayoutContent | null> => {
  const client = createApolloClient({}, ctx);

  try {
    const result = await client.query<DocumentMetaDataQuery>({
      query: GET_DOCUMENT_META_DATA,
      fetchPolicy: 'network-only',
      variables: {
        queryParams,
      },
    });

    return result.data?.me.layout || null;
  } catch (e) {
    logError('[getDocumentMetaData] Error fetching document meta data', e);
    return null;
  }
};

export default getDocumentMetaData;
