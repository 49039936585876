import { logError } from '@bfa/nextjs-common/utils';
import { NextPageContext } from 'next';

import createApolloClient from '../../apolloClient';
import GET_GVS_VAR from '../queries/GET_GVS_VAR';

type GvsContent = { gvsVar?: string };

type GvsQuery = {
  me: {
    layout: GvsContent;
  };
};

const getGvsVar = async (
  key: string,
  ctx?: NextPageContext
): Promise<string> => {
  const client = createApolloClient({}, ctx);

  try {
    const result = await client.query<GvsQuery>({
      query: GET_GVS_VAR,
      variables: {
        key,
      },
    });

    return result.data?.me.layout?.gvsVar || '';
  } catch (e) {
    logError('[getGvsVar] Error fetching gvs variable', e);
    return '';
  }
};

export default getGvsVar;
