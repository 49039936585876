import './global-styles.scss';
import '../global.css';

import { getSegmentAnalyticsClient } from '@bfa/capi';
import { ErrorBoundary, Modal, StylesProvider } from '@bfa/common-ui';
import Favicons, {
  FaviconsProps,
} from '@bfa/common-ui/dist/components/Favicons';
import OgMetaData, {
  OgMetaDataProps,
} from '@bfa/common-ui/dist/components/OgMetaData';
import { Config } from '@bfa/next-app-config';
import {
  AuthenticatedProvider,
  DeviceInfoProvider,
  NextImageOptimizationProvider,
  ScrollBackProvider,
} from '@bfa/nextjs-common/contexts';
import { usePerfLogger } from '@bfa/nextjs-common/hooks';
import {
  FacebookPixelScript,
  GoogleTagManagerHeadScript,
} from '@bfa/nextjs-common/scripts';
import QualarooScript from '@bfa/nextjs-common/scripts/qualaroo';
import {
  clientConfigurationService,
  cookieService,
  googleTagManagerService,
} from '@bfa/nextjs-common/services';
import {
  AuthInfo,
  getAuthInfo,
  getCurrentUriParts,
  getDeviceInfo,
  getSessionInfo,
  isBrowser,
  isServer,
  openWindowUrl,
  SessionInfo,
  updateAuthInfo,
  updateDeviceInfo,
  updateSessionInfo,
} from '@bfa/nextjs-common/utils';
import { IpsyTheme } from '@bfa/themes';
import { AbstractIntlMessages, NextIntlProvider } from 'next-intl';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import React, { ComponentProps, useEffect, useRef } from 'react';

import ErrorScreen from '../components/Error';
import ServiceInitialization from '../components/ServiceInitialization';
import { BusinessId, BusinessIdProvider } from '../contexts/BusinessId';
import usePublishExperimentEvent from '../graphql/hooks/usePublishExperimentEvent';
import getDocumentMetaData from '../graphql/utils/getDocumentMetaData';
import getUserInfo from '../graphql/utils/getUserInfo';
import useFireQueuedCsEventsFromMobileApp from '../hooks/useFireQueuedCsEventsFromMobileApp';
import campaignMiddleware from '../middlewares/campaign';
import {
  getPastAdobeExperienceCookieByTid,
  refreshAdminLogin,
  setBusinessIdCookie,
  setCookiesByQuery,
  setStagepass,
  updateAdobeCookie,
  updateAdobeExperienceCookie,
} from '../utils/cookies';
import { AdobeExpDetailProps, AdobeExpProps, DeviceInfo } from '../utils/types';

type Props = AppProps &
  FaviconsProps &
  OgMetaDataProps & {
    deviceInfo: DeviceInfo;
    sessionInfo: SessionInfo;
    authInfo?: AuthInfo;
    userInfo?: ComponentProps<typeof ServiceInitialization>['user'];
    headerBusinessId: BusinessId | null;
    pageProps?: {
      messages?: AbstractIntlMessages;
    };
  };

const MyApp = ({
  Component,
  pageProps,
  authInfo,
  deviceInfo,
  sessionInfo,
  userInfo,
  favicons,
  ogMetaData,
  currentHref,
  headerBusinessId,
}: Props): JSX.Element | null => {
  const initialized = useRef<boolean>(false);
  if (!initialized.current && isBrowser) {
    initialized.current = true;

    // Update info before component mounts so children have access to values during render
    updateAuthInfo(authInfo);
    updateDeviceInfo(deviceInfo);
    updateSessionInfo(sessionInfo);
    Modal.setAppElement('body');
  }

  useEffect(() => {
    const segmentClient = getSegmentAnalyticsClient();
    const anonymousId = cookieService.getCookie(null, 'ipstr');

    const userId = authInfo?.userId;
    segmentClient.load({
      writeKey: Config.public.misc.segmentApiToken,
    });
    segmentClient.identify(userId, {}, { anonymousId });
  }, []);

  const useUpdateAdobeCookie = (event: AdobeExpProps) => {
    const adobeExpDetailProps: AdobeExpDetailProps[] = event?.detail?.execution;
    const [, pastCohortAtValue] = getPastAdobeExperienceCookieByTid();
    usePublishExperimentEvent(adobeExpDetailProps, pastCohortAtValue);
    updateAdobeExperienceCookie(null, adobeExpDetailProps);
  };

  const processLinkWithTargetBlank = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    const tagName = targetElement.tagName;
    const target = targetElement.getAttribute('target');
    const url = targetElement.getAttribute('href');
    const nonBlankTargets = ['_self', '_parent', '_top'];
    if (
      tagName === 'A' &&
      target != null &&
      !nonBlankTargets.includes(target) &&
      url &&
      clientConfigurationService.isInNativeApp()
    ) {
      event.preventDefault();
      openWindowUrl({ url, target });
    }
  };

  useEffect(() => {
    if (window.adobe && window.adobe.target) {
      document.addEventListener(
        window.adobe.target.event.CONTENT_RENDERING_SUCCEEDED,
        useUpdateAdobeCookie
      );
    }

    document.addEventListener('click', processLinkWithTargetBlank);
    return () => {
      if (window.adobe && window.adobe.target) {
        document.removeEventListener(
          window.adobe.target.event.CONTENT_RENDERING_SUCCEEDED,
          useUpdateAdobeCookie
        );
      }
      document.removeEventListener('click', processLinkWithTargetBlank);
    };
  }, []);

  useEffect(() => {
    // Add basic info to GTM dataLayer
    if (sessionInfo.clientIp) {
      googleTagManagerService.logEvent({
        event: 'ipEvent',
        ipAddress: sessionInfo.clientIp,
      });
    }
  }, [sessionInfo]);

  usePerfLogger();
  useFireQueuedCsEventsFromMobileApp();

  return (
    <StylesProvider theme={IpsyTheme}>
      <DeviceInfoProvider value={deviceInfo}>
        <AuthenticatedProvider initialValue={authInfo}>
          <ScrollBackProvider>
            <NextImageOptimizationProvider value>
              <BusinessIdProvider initialBusinessId={headerBusinessId}>
                <Head>
                  {/* Favicons and Shortcut icons */}
                  {favicons && <Favicons favicons={favicons} />}
                </Head>
                {/* Open Graph Metadata */}
                <OgMetaData ogMetaData={ogMetaData} currentHref={currentHref} />
                <ServiceInitialization user={userInfo} />
                <FacebookPixelScript userData={userInfo} />
                <GoogleTagManagerHeadScript />
                <QualarooScript userData={userInfo} />
                <NextIntlProvider locale="en" messages={pageProps.messages}>
                  <ErrorBoundary fallback={<ErrorScreen />}>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </NextIntlProvider>
              </BusinessIdProvider>
            </NextImageOptimizationProvider>
          </ScrollBackProvider>
        </AuthenticatedProvider>
      </DeviceInfoProvider>
    </StylesProvider>
  );
};

MyApp.getInitialProps = async (ctx: AppContext) => {
  refreshAdminLogin(ctx.ctx);
  setCookiesByQuery(ctx.ctx);
  setStagepass(ctx.ctx); // Only for local development

  const headerBusinessId =
    (ctx.ctx.req?.headers['x-ipsy-business-id'] as BusinessId | '') || null;
  setBusinessIdCookie(ctx.ctx, headerBusinessId);

  let userInfo;
  const authInfo = getAuthInfo(ctx.ctx);
  const deviceInfo = getDeviceInfo(ctx.ctx);
  const sessionInfo = getSessionInfo(ctx.ctx);

  const { href, queryString } = getCurrentUriParts(ctx.ctx.req);
  let docMeta;

  if (isServer) {
    if (authInfo) {
      userInfo = await getUserInfo(ctx.ctx);
      updateAdobeCookie(ctx.ctx, userInfo?.id);
    }

    docMeta = await getDocumentMetaData(ctx.ctx, queryString);

    await campaignMiddleware(ctx);
  }

  if (!authInfo) {
    updateAdobeCookie(ctx.ctx, userInfo?.id);
  }

  const appProps = await App.getInitialProps(ctx);

  return {
    ...appProps,
    headerBusinessId,
    deviceInfo,
    authInfo,
    sessionInfo,
    userInfo: {
      ...userInfo,
    },
    favicons: docMeta,
    ogMetaData: {
      ...docMeta?.ogMetaData,
      ...appProps.pageProps?.ogMetaData,
    },
    currentHref: href,
  };
};

export default MyApp;
