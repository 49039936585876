import { Box } from '@bfa/common-ui';
import Router from 'next/router';
import React from 'react';

import MessagePageLayout from '../MessagePageLayout';

type Props = {
  message?: string;
};

const ErrorContent = ({
  message = 'Sorry, it looks like something went wrong',
}: Props) => (
  <Box pt="3rem">
    <MessagePageLayout
      title="Oops"
      description={
        <>
          {`${message}. Please try again by `}
          <span className="pink-ipsy">refreshing the page.</span>
        </>
      }
      onClick={Router.reload}
      buttonText="TRY AGAIN"
    />
  </Box>
);

export default ErrorContent;
