/* eslint-disable sonarjs/no-duplicate-string */

export enum AnalyticsCategory {
  ACCOUNT_UPDATE = 'Account Update',
  ADD_ONS = 'Add-Ons',
  EXTREME_CUSTOMIZATION = 'extreme customization',
  CUSTOMIZATION = 'Customization',
  COMMERCE = 'Commerce',
  ADDONS_PDP = 'PDP',
  ADDONS_LANDING_PAGE_ACTION = 'Add-ons Landing Page',
  CHECKOUT = 'Checkout',
  COUPONS = 'Coupons',
  GLAM_BAG = 'Glam Bag',
  GLAM_BAG_PAGE = 'Glam Bag Page',
  INTERSTITIAL = 'Interstitial',
  BAGBOOST = 'BagBoost',
  ANNUAL_UPGRADE = 'Annual Upgrade',
  MEMBERSHIP_FLOW = 'Membership Flow',
  GROWTH_FEATURES = 'Growth Features',
  REVIEWS = 'Reviews',
  SUBSCRIPTION_FLOW = 'Subscription Flow',
  WISH_LIST = 'Wish List',
  PROFILE = 'Profile',
  UNIVERSAL_CONFIRM_PAGE = 'Universal Confirm Page',
  REFRESHMENTS = 'Refreshments',
  SPLASH_PAGE = 'Splash Page',
  BANNER = 'Banner',
  UPSALE_ADDONS = 'Upsale Addons',
  UPSALE_UP = 'Upsale UP',
  NAVIGATION = 'Navigation',
  GIFTING = 'Gifting',
  GB_PAGE_ANNUAL_CTA = 'GB page Annual CTA',
  GBP_PAGE_ANNUAL_CTA = 'GBP page Annual CTA',
  ACCOUNT_MANAGEMENT = 'Account Management',
  THANK_YOU = 'Thank You',
  BEAUTY_BOOST = 'Beauty Boost',
  GENERIC_INTERSTITIAL = 'Generic Interstitial',
  BOXY_HOME = 'Boxy Home',
  SEO = 'SEO',
  SEO_BLOG_ARTICLE_V2 = 'SEO',
  PRODUCT_PAGE = 'Product Page',
  LOGIN_CONFIRMATION = 'Login Confirmation',
  CANCELLATION = 'Cancellation',
  SIGNUP = 'Signup',
  ERROR_PAGE = 'Error Page',
}

// Added placeholder because action wil be passed in logEvent
const ANALYTICS_EVENT_MAP = {
  EXTREME_REVEAL_PAGE: {
    category: AnalyticsCategory.EXTREME_CUSTOMIZATION,
    action: 'Extreme Reveal Page',
  },
  EXTREME_REVEAL_PDP: {
    category: AnalyticsCategory.EXTREME_CUSTOMIZATION,
    action: 'Extreme Reveal PDP',
  },
  EXTREME_CHOICE_PAGE: {
    category: AnalyticsCategory.EXTREME_CUSTOMIZATION,
    action: 'Extreme Choice Selection Page',
  },
  ACCOUNT_UPDATE_CHANGE_PASSWORD: {
    category: AnalyticsCategory.ACCOUNT_UPDATE,
    action: 'Update Password',
  },
  ADDONS_FILTERS: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Filters',
  },
  ADDONS_PAGE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Add Ons',
  },
  ADDONS_ABANDONED_CART_MODAL: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Abandoned Cart Modal',
  },
  ADDONS_SKIP_MODAL: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Skip Modal',
  },
  ADDONS_SOLDOUT_MODAL: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Sold-out Modal',
  },
  ADDONS_PAYMENTERROR_MODAL: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Payment Error Modal',
  },
  ADDONS_CART_EMPTY: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Cart Is Empty',
  },
  ADDONS_PAGE_UP: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Add-ons Landing Page',
  },
  ADDONS_CHECKOUT: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Checkout',
  },
  ADDONS_HANDLING_FEE: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Add-ons Landing Page',
  },
  ADDONS_PDP: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Pdp',
  },
  ADDONS_PDP_PAGE: {
    category: AnalyticsCategory.ADDONS_PDP,
    action: 'ViewContent',
  },
  ADDONS_THANK_YOU_PAGE: {
    category: AnalyticsCategory.ADD_ONS,
    action: 'Add-Ons Order Confirmation Page',
  },
  BAGBOOST_LANDING_PAGE: {
    category: AnalyticsCategory.BAGBOOST,
    action: 'Landing Page',
  },
  COMMERCE_LANDING_PAGE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Commerce Landing Page',
  },
  COMMERCE_CHECKOUT: {
    category: AnalyticsCategory.COMMERCE,
    action: AnalyticsCategory.CHECKOUT,
  },
  COMMERCE_PAYMENT_LIST_PAYPAL_CLICKED_OPTION: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Clicked PayPal As Payment Method',
  },
  COMMERCE_PAYMENT_LIST_PAYPAL_DISPLAY_OPTION: {
    category: AnalyticsCategory.COMMERCE,
    action: 'PayPal As Payment Method',
  },
  COMMERCE_PAYMENT_LIST_PAYPAL_DISPLAY_BUTTON: {
    category: AnalyticsCategory.COMMERCE,
    action: 'PayPal CTA Is Displayed',
  },
  COMMERCE_PAYMENT_BRAINTREE_PM_CREATION_FAILURE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'PayPal payment method creation failed',
  },
  COMMERCE_PAYMENT_BRAINTREE_PM_CREATION_SUCCESS: {
    category: AnalyticsCategory.COMMERCE,
    action: 'PayPal payment method creation succeed',
  },
  COMMERCE_LAST_CRAVING: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Last Craving',
  },
  COMMERCE_CART: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Cart',
  },
  COMMERCE_PROCEED_TO_CHECKOUT: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Proceed to Checkout',
  },
  COMMERCE_REMOVE_PRODUCT_MODAL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Remove Product Modal',
  },
  INTERSTITIAL: {
    category: AnalyticsCategory.INTERSTITIAL,
    action: 'Add Ons Viewed Landing Page',
  },
  INTERSTITIAL_THANK_YOU_PAGE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Add Ons Viewed Confirmation Page',
  },
  EXTREME_CHOICE_PDP: {
    category: AnalyticsCategory.EXTREME_CUSTOMIZATION,
    action: 'Extreme Choice PDP',
  },
  EXTREME_THANK_YOU_PAGE: {
    category: AnalyticsCategory.EXTREME_CUSTOMIZATION,
    action: 'Extreme Add-Ons Order Confirmation Page',
  },
  SIGNED_OUT_LANDING_EXPERIENCE: {
    category: AnalyticsCategory.MEMBERSHIP_FLOW,
    action: 'Signed Out Landing Experience',
  },
  FREE_BAGS: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Free Bags',
  },
  CHAINED_REVIEW: {
    category: AnalyticsCategory.REVIEWS,
    action: 'Reviews',
  },
  CHOOSE_SUB_PACKAGE: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Choose Sub Package',
  },
  GBX_ONE_CLICK_UPGRADE: {
    category: AnalyticsCategory.GLAM_BAG,
    action: 'GBX upgrade campaign interstitial',
  },
  GLAMBAG_X_CONDENSED: {
    category: AnalyticsCategory.GLAM_BAG,
    action: 'GBX upgrade campaign interstitial - condensed view',
  },
  GLAMBAG_PAGE_RETAILER_3P: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: '3P Retailer Link',
  },
  GLAMBAG_PAGE_PRODUCTS: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Products',
  },
  GLAMBAG_PAGE_BAGS: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Bags',
  },
  GLAMBAG_MARKETING_SLOT_1: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Marketing Slot 1',
  },
  GLAMBAG_MARKETING_SLOT_2: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Marketing Slot 2',
  },
  GLAMBAG_SNEAK_PEAK: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Sneak Peak',
  },
  GLAMBAG_PAGE_BAG_SHARING: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Share Bag',
  },
  GLAMBAG_PAGE_TIMELINE: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Timeline',
  },
  NAVIGATION_TABS: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Toggle Subscription',
  },
  GLAMBAG_PAGE_NAVIGATION: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'In-page navigation',
  },
  GLAMBAG_PAGE_REVIEWS: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Reviews',
  },
  GLAMBAG_PAGE_ABOUT_YOUR_BAG: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'About Your Bag',
  },
  GLAMBAG_PAGE_HERO_IMAGE: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Hero Image Click',
  },
  GLAMBAG_PAGE_BLOG: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Blogs',
  },
  GLAMBAG_PAGE_NAVIGATION_TABS: {
    category: AnalyticsCategory.GLAM_BAG_PAGE,
    action: 'Subscription Tabs',
  },
  REACTIVATE: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Re-Activate',
  },
  REACTIVATE_PROMO: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Re-Activate on Popup',
  },
  REFERRAL_BANNER: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Referral Lading Page Banner',
  },
  PRODUCT_PAGE_TEASER_QUESTION: {
    category: AnalyticsCategory.PRODUCT_PAGE,
    action: 'Teaser Question',
  },
  SHIPMENT_WEEK_CAMPAIGN: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Shipment Week Campaigns',
  },
  COMMERCE_WISH_LIST: {
    category: AnalyticsCategory.WISH_LIST,
    action: 'Wish List Experience',
  },
  // Quiz
  NO_QUIZ: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'No Quiz',
  },
  SHORT_QUIZ: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Short Quiz',
  },
  FULL_QUIZ: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Full Quiz',
  },
  QUIZ_NEW_USER: {
    category: AnalyticsCategory.MEMBERSHIP_FLOW,
    action: 'Quiz (New User)',
  },
  QUIZ_RETAKE: {
    category: AnalyticsCategory.PROFILE,
    action: 'Quiz (Retake)',
  },
  QUIZ_COMPLETION: {
    category: AnalyticsCategory.PROFILE,
    action: 'Quiz (Completion)',
  },
  MEMBERSHIP_CONFIRMATION: {
    category: AnalyticsCategory.UNIVERSAL_CONFIRM_PAGE,
    action: 'Confirmation Flow',
  },
  REFRESHMENTS_LANDING: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Landing Page',
  },
  REFRESHMENTS_FAUX_LANDING: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Faux Landing Page',
  },
  REFRESHMENTS_ACQUISITION: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Acquisition Page',
  },
  REFRESHMENTS_CANCELLATION: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Cancellation flow',
  },
  REFRESHMENTS_CANCELLATION_EMAIL: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Cancel Subscription',
  },
  REFRESHMENTS_PDP: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Product Page',
  },
  REFRESHMENTS_SHARE: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Share Page',
  },
  REFRESHMENTS_ONBOARDING_MODAL: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Start Modal',
  },
  REFRESHMENTS_ONBOARDING_MODAL_STEP_1: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Tour Step 1',
  },
  REFRESHMENTS_ONBOARDING_MODAL_STEP_2: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Tour Step 2',
  },
  REFRESHMENTS_ONBOARDING_MODAL_STEP_3: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Tour Step 3',
  },
  REFRESHMENTS_ONBOARDING_MODAL_STEP_4: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Tour Step 4',
  },
  REFRESHMENTS_ONBOARDING_MODAL_STEP_5: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Tour Step 5',
  },
  REFRESHMENTS_SUBFORM_CONFIRMATION: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Subform Confirmation',
  },
  // Navigation
  NAVIGATION_MAIN_MENU: {
    category: AnalyticsCategory.NAVIGATION,
    action: 'Main Menu',
  },
  REFRESHMENTS_ITEM_CONFIGURATION: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Item Configuration',
  },
  REFRESHMENTS_ITEM_OFFBOARD: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Item Offboard Screen',
  },
  REFRESHMENTS_CANCEL_BY_REMOVAL: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Cancel By Removal Screen',
  },
  REFRESHMENTS_SHIPPING_INFORMATION: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Shipping Information Page',
  },
  REFRESHMENTS_EDIT_SHIPPING_ADDRESS: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Edit Shipping Address',
  },
  REFRESHMENTS_ACCOUNT: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'Account Page',
  },
  REFRESHMENTS_ONE_CLICK_SUBSCRIBE: {
    category: AnalyticsCategory.REFRESHMENTS,
    action: 'One-click Subscribe',
  },
  SIGNED_OUT_SPLASH_SPA: {
    category: AnalyticsCategory.SPLASH_PAGE,
    action: 'Open',
  },
  SUBSCRIBE_ALERT: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Subscribe Alert',
  },
  // Banner
  BANNER: {
    category: AnalyticsCategory.BANNER,
    action: 'Placeholder',
  },
  // Popup
  POPUP_SHOPPER: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Shopper Promotion Pop Up',
  },
  ANNUAL_UPGRADE: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Upgrades',
  },
  REFERRAL_GIFT: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Referral Share',
  },
  FLASH_SALES_POPUP: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Flash Sales Pop Up',
  },
  GIFTING_POPUP: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Gifting Pop Up',
  },
  // Reviews
  REVIEWS: {
    category: AnalyticsCategory.REVIEWS,
    action: 'Reviews',
  },
  UPSALE_ADDONS: {
    category: AnalyticsCategory.UPSALE_ADDONS,
    action: 'Upsale Addons',
  },
  UPSALE_UP: {
    category: AnalyticsCategory.UPSALE_UP,
    action: 'Upsale UP',
  },
  SUB_FORM: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Sub Form',
  },
  SWITCH_FULLSIZE: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Switch Glam Bag Plus',
  },
  SWITCH_GLAMBAG: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Switch Glam Bag',
  },
  ADD_FULLSIZE: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Add Glam Bag Plus',
  },
  ADD_GLAMBAG: {
    category: AnalyticsCategory.SUBSCRIPTION_FLOW,
    action: 'Add Glam Bag',
  },
  SCROLL_PRODUCTS: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Scroll Products',
  },
  PHONE_NUMBER_SAVED: {
    category: AnalyticsCategory.MEMBERSHIP_FLOW,
    action: 'Phone number saved',
  },
  SMS_OPT_IN: {
    category: AnalyticsCategory.MEMBERSHIP_FLOW,
    action: 'SMS opt-in',
  },
  // Sticky Promo
  Sticky_Promo: {
    category: AnalyticsCategory.GROWTH_FEATURES,
    action: 'Sticky Promo',
  },
  GIFTING_GIVER: {
    category: AnalyticsCategory.GIFTING,
    action: 'Gift giver home',
  },
  GIFTING_GIVER_CONFIRMATION: {
    category: AnalyticsCategory.GIFTING,
    action: 'Gift giver confirmation page',
  },
  GIFTING_ACTIVATION: {
    category: AnalyticsCategory.GIFTING,
    action: 'Activation',
  },
  GIFTING_REDEEM: {
    category: AnalyticsCategory.GIFTING,
    action: 'Redeem',
  },
  ANNUAL_UPGRADE_CALLOUT_GB: {
    category: AnalyticsCategory.GB_PAGE_ANNUAL_CTA,
    action: 'GB Page Annual View',
  },
  ANNUAL_UPGRADE_CALLOUT_GBP: {
    category: AnalyticsCategory.GBP_PAGE_ANNUAL_CTA,
    action: 'GBP Page Annual View',
  },
  ANNUAL_UPGRADE_CALLOUT_GB_CLICK: {
    category: AnalyticsCategory.GB_PAGE_ANNUAL_CTA,
    action: 'GB Page Annual Click',
  },
  ANNUAL_UPGRADE_CALLOUT_GBP_CLICK: {
    category: AnalyticsCategory.GBP_PAGE_ANNUAL_CTA,
    action: 'GBP Page Annual Click',
  },
  ANNUAL_UPGRADE_LANDING: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Viewed',
  },
  ANNUAL_UPGRADE_EDIT_INFO_CLICK: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Clicked Edit Your Info',
  },
  ANNUAL_UPGRADE_CHECKOUT_CLICK: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Clicked Upgrade Membership',
  },
  ANNUAL_UPGRADE_AFTERPAY_CHECKOUT_CLICK: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Clicked Upgrade with Afterpay',
  },
  ANNUAL_UPGRADE_AFTERPAY_INFO_CLICK: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Clicked AfterPay Information Modal',
  },
  ANNUAL_UPGRADE_AFTERPAY_SUCCESS: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Successfully Upgraded with Afterpay',
  },
  ANNUAL_UPGRADE_AFTERPAY_ERROR: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Unsuccessful Upgrade with Afterpay',
  },
  ANNUAL_UPGRADE_CHECKOUT_CREDIT_SUCCESS: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Successful Upgrade with Creditcard',
  },
  ANNUAL_UPGRADE_CHECKOUT_CREDIT_ERROR: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Viewed Payment Error',
  },
  ANNUAL_UPGRADE_CONFIRMATION_LANDING: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Lands',
  },
  ANNUAL_UPGRADE_HOMEPAGE_REDIRECT_CLICK: {
    category: AnalyticsCategory.ANNUAL_UPGRADE,
    action: 'Clicked Redirect to Home',
  },
  ACCOUNT_MANAGEMENT_VIEWED_UPDATE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Landed on the Stripe Update Billing',
  },
  ACCOUNT_MANAGEMENT_CLICKED_UPDATE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked on update payment method CTA',
  },
  ACCOUNT_MANAGEMENT_SUCCESSFUL_PAYMENT_CREATION: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Successfully created payment method',
  },
  ACCOUNT_MANAGEMENT_SUCCESSFUL_STRIPE_INTENT_PAYMENT_CREATION: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Successfully created stripe payment intent',
  },
  ACCOUNT_MANAGEMENT_VIEWED_SUCCESSFUL_UPDATE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Landed on Success page for Update Billing',
  },
  ACCOUNT_MANAGEMENT_SUCCESSFUL_UPDATE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Successfully updated payment method',
  },
  ACCOUNT_MANAGEMENT_VIEWED_ERROR_UPDATE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Landed on Error page for Update Billing',
  },
  ACCOUNT_MANAGEMENT_FAILED_PAYMENT_CREATION: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Failed to create payment method',
  },
  ACCOUNT_MANAGEMENT_ERROR_UPDATING_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Failed to update payment method',
  },
  ACCOUNT_MANAGEMENT_CLICKED_ADD_ADDRESS: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked on New Address CTA',
  },
  ACCOUNT_MANAGEMENT_VIEWED_ADD_ADDRESS: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Viewed Add Address',
  },
  ACCOUNT_MANAGEMENT_CLICKED_SAVE_ADDRESS: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked Save Address',
  },
  ACCOUNT_MANAGEMENT_VIEWED_ERROR_NEW_ADDRESS: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Viewed Error Update Payment',
  },
  ACCOUNT_MANAGEMENT_ERROR_ADD_ADDRESS: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Error Add Address',
  },
  ACCOUNT_MANAGEMENT_SUCCESSFUL_ADD_ADDRESS: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Successful Add Address',
  },
  ACCOUNT_MANAGEMENT_SUCCESSFUL_RECHARGE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Successfully immediately billed',
  },
  ACCOUNT_MANAGEMENT_FAILED_RECHARGE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Failed to immediately bill',
  },
  ACCOUNT_MANAGEMENT_CLICKED_RECHARGE_PAYMENT: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked on Update CTA and attempt to immediately bill',
  },
  BEAUTY_BOOST_ONE_CLICK_UPGRADE: {
    category: AnalyticsCategory.BEAUTY_BOOST,
    action: 'BB upgrade campaign interstitial',
  },
  BEAUTY_BOOST_ORGANIC_LANDING: {
    category: AnalyticsCategory.BEAUTY_BOOST,
    action: 'organic signup',
  },
  BEAUTY_BOOST_ORGANIC_CHECKOUT: {
    category: AnalyticsCategory.BEAUTY_BOOST,
    action: 'organic signup',
  },
  BEAUTY_BOOST_OPT_OUT: {
    category: AnalyticsCategory.BEAUTY_BOOST,
    action: 'cancellation',
  },
  ACCOUNT_MANAGEMENT_ORDER_HISTORY: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Order History',
  },
  COMMERCE_HOME_PAGE_RAIL_SHOP_ALL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Home Page',
  },
  COMMERCE_HOME_PAGE_RAIL_CLICK_PRODUCT: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Home Page',
  },
  COMMERCE_HOME_PAGE_RAIL_SCROLL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Home Page',
  },
  COMMERCE_HOME_PAGE_TOP_BANNER: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Home Page',
  },
  COMMERCE_CHECKOUT_VIEW_CHECKOUT: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Checkout',
  },
  COMMERCE_CHECKOUT_APPLY_COUPON: {
    category: AnalyticsCategory.COUPONS,
    action: 'Apply Coupon',
  },
  COMMERCE_CHECKOUT_REMOVE_COUPON: {
    category: AnalyticsCategory.COUPONS,
    action: 'Remove Coupon',
  },
  COMMERCE_CHECKOUT_TYPE_COUPON: {
    category: AnalyticsCategory.COUPONS,
    action: 'Change Coupon',
  },
  COMMERCE_CHECKOUT_OPEN_PDP: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Checkout',
  },
  COMMERCE_CHECKOUT_REDUCE_QUANTITY: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Checkout',
  },
  COMMERCE_CHECKOUT_INCREASE_QUANTITY: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Checkout',
  },
  COMMERCE_CHECKOUT_REMOVE_FROM_CART: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Checkout',
  },
  COMMERCE_CHECKOUT_UPDATE_QUANTITY: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Checkout',
  },
  COMMERCE_PLACE_ORDER_CLICK_ON_PLACE_ORDER: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Place Order',
  },
  COMMERCE_PLACE_ORDER_CLICK_ON_PLACE_ORDER_WITH_PAYPAL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Place Order',
  },
  COMMERCE_SHIPPING_ADDRESS_CLICK_ON_SHIPPING_ADDRESS: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Shipping Address',
  },
  COMMERCE_PAYMENT_METHOD_CLICK_ON_PAYMENT_METHOD: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Payment Method',
  },
  COMMERCE_ORDER_DETAILS_CLICK_TO_EXPAND: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Order Details',
  },
  COMMERCE_ORDER_DETAILS_CLICK_TO_COLLAPSE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Order Details',
  },
  COMMERCE_ORDER_DETAILS_VIEW_ORDER_DETAIL_PAGE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Order Details',
  },
  COMMERCE_ORDER_DETAILS_EXIT_ORDER_DETAIL_PAGE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Order Details',
  },
  COMMERCE_LEAVE_CHECKOUT_GO_BACK_ARROW: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Leave Checkout',
  },
  COMMERCE_REMOVE_PRODUCT_MODAL_VIEW_PRODUCT_REMOVE_MODAL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Remove Product Modal',
  },
  COMMERCE_REMOVE_PRODUCT_MODAL_REMOVE_PRODUCT: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Remove Product Modal',
  },
  COMMERCE_REMOVE_PRODUCT_MODAL_DONT_REMOVE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Remove Product Modal',
  },
  COMMERCE_CATEGORY_NAVIGATION_CLICK_ON_CATEGORY: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Category Navigation',
  },
  COMMERCE_CATEGORY_NAVIGATION_CLICK_ON_SIDE_ARROW: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Category Navigation',
  },
  COMMERCE_CATEGORY_NAVIGATION_VIEW: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Category Navigation',
  },
  COMMERCE_CATEGORY_NAVIGATION_GO_TO_CART: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Category Navigation',
  },
  ACCOUNT_MANAGEMENT_LANDED_ON_SELECT_ADDRESS_PAGE: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Viewed Select Your Address',
  },
  ACCOUNT_MANAGEMENT_CHANGED_SELECT_ADDRESS_CHOOSEN_ITEM: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked Different Address',
  },
  ACCOUNT_MANAGEMENT_CLICKED_ON_CHOOSE_YOUR_SELECT_ADDRESS_UPDATE_CTA: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked Update Address',
  },
  ACCOUNT_MANAGEMENT_CLICKED_ON_CHOOSE_YOUR_SELECT_ADDRESS_ADD_PAYMENT_CTA: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked Add Address',
  },
  ACCOUNT_MANAGEMENT_CLICKED_ON_CHOOSE_YOUR_SELECT_ADDRESS_BACK_BUTTON: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Clicked Back From Address',
  },
  GENERIC_INTERSTITIAL: {
    category: AnalyticsCategory.GENERIC_INTERSTITIAL,
    action: '',
  },
  COMMERCE_CANNOT_SHIP_MODAL_CLICK_ON_REMOVE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Cannot Ship Modal',
  },
  COMMERCE_OUT_OF_STOCK_MODAL_CLICK_ON_DISMISS: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Out of Stock Modal',
  },
  COMMERCE_CANNOT_SHIP_MODAL_VIEW_CANNOT_SHIP_MODAL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Cannot Ship Modal',
  },
  COMMERCE_OUT_OF_STOCK_MODAL_VIEW_OOS_MODAL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Out of Stock Modal',
  },
  COMMERCE_PRODUCT_FILTERS_VIEW: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_SEE_ALL: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_CLICK: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_OPTION_CLICK: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_OPTION_UNCLICK: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_CLEAR: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_APPLY: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_SEARCH_CHANGE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_SEARCH_CLEAR: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_OPEN: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_CLOSE: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_CLEAR_APPLIED_FILTER: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PRODUCT_FILTERS_CLEAR_ALL_APPLIED_FILTERS: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Filtering',
  },
  COMMERCE_PAGINATION_CLICK_ON_PAGE_NUMBER: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Pagination',
  },
  COMMERCE_PAGINATION_CLICK_ON_FOWARD_ARROW: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Pagination',
  },
  COMMERCE_PAGINATION_CLICK_ON_BACKWARD_ARROW: {
    category: AnalyticsCategory.COMMERCE,
    action: 'Pagination',
  },
  LANDED_IN_BOXY: {
    category: AnalyticsCategory.BOXY_HOME,
    action: 'page-navigation',
  },
  JOIN_FROM_BANNER: {
    category: AnalyticsCategory.BOXY_HOME,
    action: 'join',
  },
  BLOG_ARTICLE_WIDGET: {
    category: AnalyticsCategory.SEO_BLOG_ARTICLE_V2,
    action: 'Widget',
  },
  BLOG_ARTICLE_CLICKED_ON_SHARE_BUTTON: {
    category: AnalyticsCategory.SEO_BLOG_ARTICLE_V2,
    action: 'Blog Article V2',
  },
  SEO_BLOG_ARTICLE_PAGE_V2: {
    category: AnalyticsCategory.SEO_BLOG_ARTICLE_V2,
    action: 'Blog Article V2',
  },
  SEO_BLOG_ARTICLE_PAGE_V2_CLICKED_ON_PAGE: {
    category: AnalyticsCategory.SEO_BLOG_ARTICLE_V2,
    action: 'Blog Article V2 Clicked on Page',
  },
  SEO_BLOG_ARTICLE_QUIZ_LINK_CLICK: {
    category: AnalyticsCategory.SEO_BLOG_ARTICLE_V2,
    action: 'in_article_body_quiz_cta_click',
  },
  SEO_BLOG_ARTICLE_REFERAL_LINK_CLICK: {
    category: AnalyticsCategory.SEO_BLOG_ARTICLE_V2,
    action: 'in_article_body_referral_share_click',
  },
  SEO_BLOG_V2_ARTICLE_PAGE_VIEWED: {
    category: AnalyticsCategory.SEO_BLOG_ARTICLE_V2,
    action: 'Blog Article V2 Viewed Page',
  },
  SEO_BLOG_HOME_PAGE_LANDED: {
    category: AnalyticsCategory.SEO,
    action: 'Blog Home Page',
  },
  CHOICE_GLAMBAG: {
    category: AnalyticsCategory.CUSTOMIZATION,
    action: 'Glambag Choice',
  },
  COUPON_INTERSTITIAL: {
    category: AnalyticsCategory.COUPONS,
    action: 'shop-awareness',
  },
  LOGIN_CONFIRMATION_FORM: {
    category: AnalyticsCategory.LOGIN_CONFIRMATION,
    action: 'Form',
  },
  IOS_PUSH_NOTIFICATION: {
    category: AnalyticsCategory.ACCOUNT_MANAGEMENT,
    action: 'Push Notification',
  },
  CANCELLATION: {
    category: AnalyticsCategory.CANCELLATION,
    action: '',
  },
  SIGNUP: {
    category: AnalyticsCategory.SIGNUP,
    action: 'Signup',
  },
  ERROR_PAGE: {
    category: AnalyticsCategory.ERROR_PAGE,
    action: 'Error',
  },
};

export const ANALYTICS_EVENT: {
  [key in keyof typeof ANALYTICS_EVENT_MAP]: {
    category: AnalyticsCategory;
    action: string;
  };
} = ANALYTICS_EVENT_MAP;

export type AnalyticsEventKey = keyof typeof ANALYTICS_EVENT;
