import { Flex, FlexItem, Header } from '@bfa/common-ui';
import noop from 'lodash/noop';
import React, { MouseEvent } from 'react';

import { headerBaseProps } from '../../utils/header';
import ErrorContent from '../ErrorContent';

type Props = {
  onCrossClick?: (event?: MouseEvent) => void;
  onLogoClick?: (event?: MouseEvent) => void;
  children?: React.ReactNode;
};

const ErrorScreen = ({ onCrossClick, onLogoClick = noop, children }: Props) => (
  <Flex flexDirection="column" minHeight="100vh">
    <Header
      {...headerBaseProps}
      minimum
      cross
      loading={!onCrossClick}
      onLogoClick={onLogoClick}
      onCrossClick={onCrossClick}
    />
    <FlexItem className="ux" flex="1">
      {children || <ErrorContent />}
    </FlexItem>
  </Flex>
);

export default ErrorScreen;
