import { gql } from '@apollo/client';
import { logError, logInfo } from '@bfa/nextjs-common/utils';
import { NextPageContext } from 'next';

import createApolloClient from '../../apolloClient';
import { GQLUserDetails } from '../../gql.generated';

type UserInfoQuery = {
  me: {
    details: Pick<
      GQLUserDetails,
      'id' | 'email' | 'firstName' | 'lastName' | 'age' | 'registrationDate'
    >;
  };
};

const USER_INFO_QUERY = gql`
  query getUserInfo {
    me {
      details {
        id
        email
        firstName
        lastName
        age
        registrationDate
      }
    }
  }
`;

const getUserInfo = async (
  ctx: NextPageContext
): Promise<UserInfoQuery['me']['details'] | undefined> => {
  const client = createApolloClient({}, ctx);

  try {
    const result = await client.query<UserInfoQuery>({
      query: USER_INFO_QUERY,
      fetchPolicy: 'network-only',
    });

    if (
      !!result.data?.me?.details?.id &&
      !result.data?.me?.details?.registrationDate
    ) {
      logInfo(
        `[getUserInfo]: ${result.data?.me?.details?.id} does not have registration date`
      );
    }

    return result.data?.me.details;
  } catch (e) {
    logError('[getUserInfo] Error fetching user information', e);
  }

  return undefined;
};

export default getUserInfo;
