import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { RECAPTCHA_HEADER_NAME } from '@bfa/accounts-recaptcha';
import { Config } from '@bfa/next-app-config';
import { clientLogging, cookieService } from '@bfa/nextjs-common/services';
import {
  devLogLink,
  errorMiddleware,
  getFirstParam,
  getRuleBasedEngineLogParams,
  HeaderName,
  isDev,
  isServer,
  teamMiddleware,
} from '@bfa/nextjs-common/utils';
import { NextPageContext } from 'next';
import { v4 as uuid } from 'uuid';

import {
  loadCancellationNavigation,
  storeCancellationNavigation,
} from './features/Cancellation/helpers/cancellationStepsDataStorage';
import { CancellationNavigationData } from './features/Cancellation/types';
import {
  messagesToAvoidLogging,
  queriesToAvoidLogging,
} from './utils/apollo/onError';

const getHeaders = (ctx?: NextPageContext) => {
  const cmsPreviewPass = ctx && getFirstParam(ctx.query?.previewpass);

  const headers = {
    ...ctx?.req?.headers,
    accept: 'application/json',
    [HeaderName.PREVIEWPASS]: cmsPreviewPass,
  };
  delete headers.host;

  if (ctx?.req) {
    return {
      ...headers,
      referer: `https://${ctx.req.headers.host}${ctx.req.url}`,
    };
  }

  return headers;
};

const getTrackingHeaders = (ctx?: NextPageContext) => {
  let tid = cookieService.getCookie(ctx, 'ipstr');

  if (!tid && isDev) {
    tid = `itr-${uuid()}`;
    cookieService.setCookie(ctx, 'ipstr', tid);
  }

  return {
    'x-ipsy-tid': tid,
    'Ipsy-Tracking-Id': tid,
  };
};

const errorLink = onError(({ graphQLErrors, operation }) => {
  const { operationName } = operation;
  const isQuery = operation.query.definitions.some(
    (d) => 'operation' in d && d.operation === 'query'
  );

  if (!graphQLErrors || !isQuery) {
    return;
  }

  if (!isDev && queriesToAvoidLogging.includes(operationName)) {
    return;
  }

  graphQLErrors.forEach(({ message, locations, path }) => {
    if (
      message &&
      !messagesToAvoidLogging.some((msg) => message.includes(msg))
    ) {
      const contextInfo = {
        message,
        location: locations,
        path,
        query: operationName,
      };

      clientLogging.logError(
        Error(
          `[FATAL ERROR (IPSY NextJS SPA)] ${Object.entries(contextInfo)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([key, value]) => key && value !== undefined)
            .map(([key, value]) => `${key}: ${value}`)
            .join(' | ')}`
        )
      );
    }
  });
});

/**
 * Creates an instance of Apollo Client.
 *
 * @param initialState - Initial app state.
 * @param ctx - App context.
 * @returns An instance of apollo client.
 */
export default function createApolloClient(
  initialState: NormalizedCacheObject,
  ctx?: NextPageContext
) {
  // This is necessary so the server can reuse the EGW headers sent to NextJS.
  // When SSR is done we send the auth headers so we use the internal URL
  // On CSR we use the cookie so we need EGW
  const defaultUri =
    isServer && !isDev
      ? Config.public.url.internalGqlApi
      : Config.public.url.externalGqlApi;

  const cache = new InMemoryCache({
    typePolicies: {
      LayoutContent: {
        merge: true,
      },
      Navigation: {
        merge: true,
      },
      RefreshmentsContent: {
        merge: true,
      },
      RefreshmentsAcquisitionContent: {
        merge: true,
      },
      MyData: {
        queryType: true,
        fields: {
          cancellationNavigation: {
            /**
             * Provide query response data from browser storage.
             * This piece of data is a `@client` Apollo data, so we
             * need to fulfill it locally, in this case from Browser
             * Sessionstorage, so it is available troughout the whole
             * cancellation flow.
             * Also, we use writeQuery to update it when needed during
             * the flow, and to refresh it in the Sessionstorage.
             *
             * The initial fresh data is stored when the /cancellation
             * page loads the steps map from PGQL server.
             */
            read() {
              return loadCancellationNavigation();
            },
            merge(_, incoming: CancellationNavigationData) {
              storeCancellationNavigation(incoming);
              return incoming;
            },
          },
        },
      },
      Commerce: {
        merge: true,
      },
    },
    dataIdFromObject(responseObject) {
      // We need this to prevent interstitial main collection to be overriden because it allways returns the same id
      switch (responseObject.__typename) {
        case 'CollectionCollections':
          return `CollectionCollectionsCustomCatching:${
            responseObject.id
          }-${uuid()}`;
        case 'CollectionInterstitialProducts':
          return `CollectionInterstitialProducts:${
            responseObject.id
          }-${uuid()}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  }).restore(initialState);

  const headersRemoveUndefined = (
    headers: Record<string, string | string[] | undefined>
  ) =>
    Object.entries(headers).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value.toString();
      }
      return acc;
    }, {} as Record<string, string>);

  const recaptchaLink = new ApolloLink((operation, forward) => {
    const { headers, recaptchaToken } = operation.getContext();

    if (recaptchaToken) {
      operation.setContext({
        headers: {
          ...headers,
          [RECAPTCHA_HEADER_NAME]: recaptchaToken,
        },
      });
    }
    return forward(operation);
  });

  const CONTENTFUL_ACCESS_TOKEN =
    ctx?.query?.contentPreview === 'true'
      ? Config.public.misc.contentfulPreviewAccesstToken
      : Config.public.misc.contentfulContentAccesstToken;
  const contentfulGraphqlLink = new HttpLink({
    uri: `${Config.public.misc.contentfulGrapghqlUrl}/${Config.public.misc.contentfulSpaceId}/environments/${Config.public.misc.contentfulEnvironmentId}`,
    headers: {
      Authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
    },
  });

  const publicGraphqlLink = new HttpLink({
    uri: defaultUri,
    credentials: 'include',
    // Copy request headers
    headers: {
      ...headersRemoveUndefined(getHeaders(ctx)),
      ...headersRemoveUndefined(getTrackingHeaders(ctx)),
      ...(getRuleBasedEngineLogParams(ctx) as Record<string, string>),
    },
  });

  const pgqlRelatedLinks = from([
    teamMiddleware(ctx),
    errorMiddleware,
    recaptchaLink,
    publicGraphqlLink,
  ]);

  const graphqlEndpoints = ApolloLink.split(
    (operation) => operation.getContext().isContentful === 'true',
    contentfulGraphqlLink,
    pgqlRelatedLinks
  );

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    connectToDevTools: isDev,
    ssrMode: isServer,
    link: ApolloLink.from([errorLink, devLogLink, graphqlEndpoints]),
    cache,
  });
}
