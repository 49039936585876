import gql from 'graphql-tag';

const GET_SUBSCRIPTIONS = gql`
  query getSubScriptions {
    me {
      subscriptions {
        id
        status
        program
        lastLifeCyclePaymentStatus
        name
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS;
