import { amplitudeService } from '@bfa/amplitude-events';
import { isBrowser } from '@bfa/nextjs-common/utils';
import createApolloClient from 'apolloClient';

import GET_SUBSCRIPTIONS from '../queries/GET_SUBSCRIPTIONS';
import {
  createSubObjForAmplitude,
  filterOutInvalidPrograms,
} from './subscriptionsHelpers';

const publishSubccriptionsAmplitude = async (userId: string | undefined) => {
  if (isBrowser && userId) {
    const apolloClient = createApolloClient({});
    const result = await apolloClient.query({
      query: GET_SUBSCRIPTIONS,
      fetchPolicy: 'network-only',
    });
    const subscriptions = filterOutInvalidPrograms(
      result.data?.me?.subscriptions || []
    );

    const subscriptionsObject = createSubObjForAmplitude(subscriptions);
    const localStorageSubObject = localStorage.getItem(
      `subsObjectAmplitude_${userId}`
    );

    if (
      Object.keys(subscriptionsObject).length > 0 &&
      JSON.stringify(subscriptionsObject) !== localStorageSubObject
    ) {
      localStorage.setItem(
        `subsObjectAmplitude_${userId}`,
        JSON.stringify(subscriptionsObject)
      );
      amplitudeService.subscriptionsUserProperties(subscriptionsObject);
    }
  }
};

export default publishSubccriptionsAmplitude;
