import gql from 'graphql-tag';

const GET_DOCUMENT_META_DATA = gql`
  query getDocumentMetaData($queryParams: String) {
    me {
      layout {
        favicon: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "favicon.ico" }
        )
        touchIcon: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "touch-icon.png" }
        )
        iPadTouchIcon: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "touch-icon-ipad.png" }
        )
        iPadTouchIconRetina: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "touch-icon-ipad-retina.png" }
        )
        iPhoneTouchIcon: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "touch-icon-iphone.png" }
        )
        iPhoneTouchIconRetina: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "touch-icon-iphone-retina.png" }
        )
        ogMetaData(queryParams: $queryParams) {
          type
          url
          imageUrl
          title
          description
          imageAlt
          width
          height
        }
      }
    }
  }
`;

export default GET_DOCUMENT_META_DATA;
