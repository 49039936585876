export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: any; output: any };
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: { input: Date; output: Date };
  /** A JSON scalar */
  JSON: { input: any; output: any };
};

export type GQLAddressManagementMutation = {
  saveNewPhysicalAddress: GQLPhysicalAddress;
  updateSubscriptionShippingAddress?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
};

export type GQLAddressManagementMutationsaveNewPhysicalAddressArgs = {
  physicalAddress: GQLPhysicalAddressInput;
};

export type GQLAddressManagementMutationupdateSubscriptionShippingAddressArgs =
  {
    addressId: Scalars['String']['input'];
    subIds: Array<Scalars['String']['input']>;
  };

/** Represent an AfterPay payment method */
export type GQLAfterPay = GQLPaymentMethod & {
  cardType?: Maybe<GQLCreditCardType>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastFour?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents the information about the After Pay payment option */
export type GQLAfterPayPriceConfig = {
  /** Currency allowed for transaction. Format ISO 4217 */
  currency: Scalars['String']['output'];
  /** Maximum value for product allowed for afterpay transaction. It will be in the lowest unit. Example USD then Cents */
  maximumAmount: Scalars['Int']['output'];
  /** Minimum value for product required for afterpay transaction. It will be in the lowest unit. Example USD then Cents */
  minimumAmount: Scalars['Int']['output'];
};

export type GQLAfterPayResponse = {
  expires: Scalars['String']['output'];
  id: Scalars['String']['output'];
  redirectCheckoutUrl: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** Represent a full screen alert to be displayed */
export type GQLAlert = {
  body: Scalars['String']['output'];
  confirmLabel: Scalars['String']['output'];
  confirmUrl: Scalars['String']['output'];
  dismissLabel?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image: GQLSimpleImage;
  title: Scalars['String']['output'];
};

export type GQLAllowedPaymentMethods = {
  paypal?: Maybe<Scalars['Boolean']['output']>;
  stripe?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLAlternativeButton = {
  buttonActionValue?: Maybe<Scalars['String']['output']>;
  buttonLabel?: Maybe<Scalars['String']['output']>;
  cmsIdentifier?: Maybe<Scalars['String']['output']>;
  csButton?: Maybe<Scalars['String']['output']>;
  isRedirect?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLAlternativeButtons = {
  buttons?: Maybe<Array<Maybe<GQLAlternativeButton>>>;
  cmsIdentifier?: Maybe<Scalars['String']['output']>;
};

export type GQLAnalyticsEventInput = {
  browser?: InputMaybe<GQLBrowserInfoInput>;
  businessId?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  clientIp?: InputMaybe<Scalars['String']['input']>;
  clientTs?: InputMaybe<Scalars['Float']['input']>;
  device?: InputMaybe<GQLDeviceInfoInput>;
  eventTimestamp?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  schemaVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  tid?: InputMaybe<Scalars['String']['input']>;
  timingData?: InputMaybe<Scalars['JSON']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLAnalyticsMutation = {
  logEvent: Scalars['Boolean']['output'];
  logV0Event: Scalars['Boolean']['output'];
};

export type GQLAnalyticsMutationlogEventArgs = {
  event: GQLAnalyticsEventInput;
};

export type GQLAnalyticsMutationlogV0EventArgs = {
  event: GQLAnalyticsV0EventInput;
};

export type GQLAnalyticsV0EventInput = {
  browser?: InputMaybe<GQLBrowserV0InfoInput>;
  cid?: InputMaybe<Scalars['String']['input']>;
  clientIp?: InputMaybe<Scalars['String']['input']>;
  clientTs?: InputMaybe<Scalars['Float']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
  eventPayload?: InputMaybe<Scalars['JSON']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  instanceId?: InputMaybe<Scalars['String']['input']>;
  instrumentationType?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  requestUri?: InputMaybe<Scalars['String']['input']>;
  schemaVersionNumber?: InputMaybe<Scalars['String']['input']>;
  serverTimestamp?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sid?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  tid?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLAnnualUpgradeResponse = {
  error: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  isWithGift: Scalars['Boolean']['output'];
};

/** Represent an apple pay payment method */
export type GQLApplePay = GQLPaymentMethod & {
  cardType: GQLCreditCardType;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastFour?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GQLAssignBagToUserSubscriptionLifecycleRequest = {
  bfs?: InputMaybe<Scalars['Boolean']['input']>;
  cycleId: Scalars['String']['input'];
  delayBfs?: InputMaybe<Scalars['Boolean']['input']>;
  glamBagId: Scalars['String']['input'];
  partial?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  warehouse: Scalars['String']['input'];
};

export type GQLAssignBagToUserSubscriptionLifecycleResponse = {
  status?: Maybe<GQLUserSubscriptionLifecycleStatus>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GQLAttributeInformation = {
  id: Scalars['String']['output'];
  isDeprecated?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Represents user session information retrieved by the authentication process */
export type GQLAuthentication = {
  /** Used to prevent login and update the password */
  passwordExpired: Scalars['Boolean']['output'];
  /** A hashed token represents partial login user */
  token: Scalars['String']['output'];
  /** The user id */
  userId: Scalars['String']['output'];
};

export type GQLAuthor = {
  bio?: Maybe<Scalars['String']['output']>;
  image?: Maybe<GQLAuthorImage>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GQLAuthorImage = {
  alt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Aggregate of review ratings */
export type GQLAverageRating = {
  averageRating?: Maybe<Scalars['Float']['output']>;
  totalReviews?: Maybe<Scalars['Int']['output']>;
};

export enum GQLBBCheckoutErrorType {
  ASSIGN_BAG_ERROR = 'ASSIGN_BAG_ERROR',
  FULFILLMENT_ERROR = 'FULFILLMENT_ERROR',
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVENTORY_ERROR = 'INVENTORY_ERROR',
}

export type GQLBBCheckoutResponse = {
  error?: Maybe<GQLBBCheckoutErrorType>;
  success: Scalars['Boolean']['output'];
};

/** represents a user cycle Bag */
export type GQLBag = {
  media?: Maybe<Scalars['JSON']['output']>;
  product?: Maybe<Scalars['JSON']['output']>;
};

/** Represents a website banner */
export type GQLBanner = {
  name: Scalars['String']['output'];
};

/** Represents a website banner with raw (JSON) content */
export type GQLBannerRaw = GQLBanner & {
  name: Scalars['String']['output'];
  /** A plain object with dynamic properties */
  properties?: Maybe<Scalars['JSON']['output']>;
};

export type GQLBeautyQuizUserAnswer = {
  answerCmsIds: Array<Scalars['String']['output']>;
  questionId: Scalars['String']['output'];
  questionType: Scalars['String']['output'];
};

export type GQLBfaOrder = {
  coupon?: Maybe<GQLBfaOrderCoupon>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  discount?: Maybe<GQLMoney>;
  discountedSubtotal?: Maybe<GQLMoney>;
  fulfillmentStatus?: Maybe<Scalars['String']['output']>;
  fulfillmentSubscriptionData?: Maybe<GQLFulfillmentOrderSubData>;
  handlingFee?: Maybe<GQLMoney>;
  hasSubscription?: Maybe<Scalars['Boolean']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  orderItemTypes?: Maybe<Array<Maybe<GQLBfaOrderItemType>>>;
  orderItems?: Maybe<Array<Maybe<GQLBfaOrderItem>>>;
  payload?: Maybe<GQLBfaOrderPayload>;
  paymentMethods?: Maybe<Array<Maybe<GQLPaymentMethod>>>;
  shipmentInfo?: Maybe<Array<Maybe<GQLBfaOrderShipment>>>;
  shipping?: Maybe<GQLMoney>;
  shippingAddress?: Maybe<GQLPhysicalAddress>;
  status?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<GQLMoney>;
  tax?: Maybe<GQLMoney>;
  total?: Maybe<GQLMoney>;
  totalSavings?: Maybe<GQLMoney>;
};

/** Represents coupon information */
export type GQLBfaOrderCoupon = {
  code?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<GQLMoney>;
  discountMagnitude?: Maybe<Scalars['String']['output']>;
  orderSubtotalWithDiscount?: Maybe<GQLMoney>;
  orderSubtotalWithoutDiscount?: Maybe<GQLMoney>;
  promotionName?: Maybe<Scalars['String']['output']>;
  promotionType?: Maybe<GQLBfaPromotionType>;
};

export type GQLBfaOrderItem = {
  category?: Maybe<GQLBfaOrderItemCategory>;
  discount?: Maybe<Scalars['Float']['output']>;
  discountedPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isGift?: Maybe<Scalars['Boolean']['output']>;
  isShipping?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<GQLProduct>;
  productId?: Maybe<Scalars['String']['output']>;
  productPriceId?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<GQLUserSubscriptionSummary>;
  subtotal?: Maybe<GQLMoney>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<GQLMoney>;
  type?: Maybe<GQLBfaOrderItemType>;
};

export enum GQLBfaOrderItemCategory {
  GOODS_AND_SERVICES = 'GOODS_AND_SERVICES',
  HANDLING = 'HANDLING',
  MANIPULATED_TAX_GOODS_AND_SERVICES = 'MANIPULATED_TAX_GOODS_AND_SERVICES',
  SHIPPING = 'SHIPPING',
}

export enum GQLBfaOrderItemType {
  ADDON = 'ADDON',
  COMMERCE = 'COMMERCE',
  GIFT = 'GIFT',
  OTHER = 'OTHER',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export type GQLBfaOrderPayload = {
  createLifeCycle?: Maybe<Scalars['Boolean']['output']>;
  cycleId?: Maybe<Scalars['String']['output']>;
  fulfillmentOrderId?: Maybe<Scalars['String']['output']>;
  lifecycleId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  subId?: Maybe<Scalars['String']['output']>;
  subOrderType?: Maybe<Scalars['String']['output']>;
  subscriptionPaymentOrderId?: Maybe<Scalars['String']['output']>;
};

export type GQLBfaOrderShipment = {
  carrierId?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  shipmentId?: Maybe<Scalars['String']['output']>;
  shippedDate?: Maybe<Scalars['DateTime']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type GQLBfaOrdersResponse = {
  orders?: Maybe<Array<Maybe<GQLBfaOrder>>>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Represents a type of discount */
export enum GQLBfaPromotionType {
  FREE_SHIPPING = 'FREE_SHIPPING',
  ITEM_DISCOUNT_ABSOLUTE = 'ITEM_DISCOUNT_ABSOLUTE',
  ITEM_DISCOUNT_PERCENTAGE = 'ITEM_DISCOUNT_PERCENTAGE',
  ORDER_DISCOUNT_ABSOLUTE = 'ORDER_DISCOUNT_ABSOLUTE',
  ORDER_DISCOUNT_PERCENTAGE = 'ORDER_DISCOUNT_PERCENTAGE',
}

/** Blog Article Block */
export type GQLBlogArticleBlock = {
  articleBodyBlockQuote?: Maybe<Scalars['String']['output']>;
  articleBodyBlockText?: Maybe<Scalars['String']['output']>;
  /** article body */
  articleBodyBlockTitle?: Maybe<Scalars['String']['output']>;
  averageRating?: Maybe<GQLAverageRating>;
  /** desktop image */
  desktopBodyBlockImage?: Maybe<GQLSimpleImage>;
  desktopBodyBlockImageAsset?: Maybe<GQLSimpleImage>;
  desktopBodyBlockImageCaption?: Maybe<Scalars['String']['output']>;
  desktopBodyBlockImageDescription?: Maybe<Scalars['String']['output']>;
  desktopBodyBlockImageSize1?: Maybe<GQLBlogImageSize>;
  /** FAQ template type */
  faqParagraph?: Maybe<Scalars['String']['output']>;
  isAvailableForShopping?: Maybe<Scalars['Boolean']['output']>;
  /** mobile image */
  mobileBodyBlockImage?: Maybe<GQLSimpleImage>;
  mobileBodyBlockImageAsset?: Maybe<GQLSimpleImage>;
  mobileBodyBlockImageCaption?: Maybe<Scalars['String']['output']>;
  mobileBodyBlockImageDescription?: Maybe<Scalars['String']['output']>;
  mobileBodyBlockImageSize?: Maybe<GQLBlogImageSize>;
  order?: Maybe<Scalars['Int']['output']>;
  productBrand?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  productFinalPrice?: Maybe<Scalars['String']['output']>;
  /** LISTICLE template type */
  productId?: Maybe<Scalars['String']['output']>;
  /** how-to template type */
  productImage?: Maybe<Scalars['String']['output']>;
  productImageAsset?: Maybe<GQLSimpleImage>;
  productImageDescription?: Maybe<Scalars['String']['output']>;
  /** how-to and listicle template types */
  productName?: Maybe<Scalars['String']['output']>;
  productOriginPrice?: Maybe<Scalars['String']['output']>;
  productUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type GQLBlogArticleList = {
  items?: Maybe<Array<Maybe<GQLBlogArticleV2>>>;
};

export type GQLBlogArticleV2 = {
  articleDescription?: Maybe<Scalars['String']['output']>;
  articleMetaDescription?: Maybe<Scalars['String']['output']>;
  articleTitle?: Maybe<Scalars['String']['output']>;
  articleTitleTag?: Maybe<Scalars['String']['output']>;
  authors?: Maybe<Array<Maybe<GQLAuthor>>>;
  blocks?: Maybe<Array<Maybe<GQLBlogArticleBlock>>>;
  categories?: Maybe<Array<Maybe<GQLBlogCategory>>>;
  contentName?: Maybe<Scalars['String']['output']>;
  desktopHeaderImage?: Maybe<GQLSimpleImage>;
  desktopHeaderImageAsset?: Maybe<GQLSimpleImage>;
  desktopHeaderImageCaption?: Maybe<Scalars['String']['output']>;
  desktopHeaderImageDescription?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Date']['output']>;
  mobileHeaderImage?: Maybe<GQLSimpleImage>;
  mobileHeaderImageAsset?: Maybe<GQLSimpleImage>;
  mobileHeaderImageDescription?: Maybe<Scalars['String']['output']>;
  publicationDate?: Maybe<Scalars['Date']['output']>;
  redirectArticleName?: Maybe<Scalars['String']['output']>;
  subcategories?: Maybe<Array<Maybe<GQLBlogCategory>>>;
  templateType?: Maybe<GQLBlogTemplateType>;
  title?: Maybe<Scalars['String']['output']>;
  youtubeVideoUrl?: Maybe<Scalars['String']['output']>;
};

export type GQLBlogCategory = {
  contentName?: Maybe<Scalars['String']['output']>;
  displayText?: Maybe<Scalars['String']['output']>;
};

export type GQLBlogCategoryList = {
  items?: Maybe<Array<Maybe<GQLBlogCategory>>>;
};

export enum GQLBlogImageSize {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export enum GQLBlogTemplateType {
  FAQ = 'FAQ',
  HOWTO = 'HOWTO',
  LISTICLE = 'LISTICLE',
  SIMPLE = 'SIMPLE',
}

export type GQLBraintreeDataAccount = {
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paypalEmail: Scalars['String']['output'];
};

/** Represents a brand */
export type GQLBrand = {
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  logo?: Maybe<GQLSimpleImage>;
  name: Scalars['String']['output'];
  promoCode?: Maybe<Scalars['String']['output']>;
  promoDiscount?: Maybe<Scalars['Float']['output']>;
};

export type GQLBrowserInfoInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type GQLBrowserV0InfoInput = {
  browser?: InputMaybe<Scalars['String']['input']>;
  browserVersion?: InputMaybe<Scalars['String']['input']>;
  device?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  platformVersion?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a group of products. Eg: Glam Bag */
export type GQLBundle = {
  productById?: Maybe<GQLBundleProduct>;
  products?: Maybe<Array<Maybe<GQLBundleProduct>>>;
  /** The price the user paid for all the products in the bundle */
  subtotal: GQLMoney;
  /** The taxes the user paid for all the products in the bundle */
  taxes: GQLMoney;
  /** The total price (including taxes) the user paid for all the products in the bundle */
  total: GQLMoney;
  /**
   * The sum of the values of the items in the bag including Choosen products and Add Ons
   * @deprecated this name doesn't represent the value returned
   */
  totalCost: GQLMoney;
};

/** Represents a group of products. Eg: Glam Bag */
export type GQLBundleproductByIdArgs = {
  id: Scalars['String']['input'];
};

/** Represents a bundled product */
export type GQLBundleProduct = {
  product: GQLProduct;
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  quiz?: Maybe<GQLQuiz>;
  /** The price the user paid for this product */
  subtotal: GQLMoney;
  /** The taxes the user paid for this product */
  taxes: GQLMoney;
  /** The total price (including taxes) the user paid for this product */
  total: GQLMoney;
  type: GQLBundleProductType;
};

/** States the different type of products in a bundle */
export enum GQLBundleProductType {
  ADD_ON = 'ADD_ON',
  BAG = 'BAG',
  BASE = 'BASE',
  CHOICE_ASSIGNED = 'CHOICE_ASSIGNED',
  CHOICE_SELECTED = 'CHOICE_SELECTED',
}

/** Represents information to show the user if is elegible for Bogo */
export type GQLBuyOneGetOne = {
  discountValue?: Maybe<Scalars['Float']['output']>;
  enableDiscount?: Maybe<Scalars['Boolean']['output']>;
  minCartLengthForDiscount?: Maybe<Scalars['Int']['output']>;
  minCartSubTotalForDiscount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<GQLBuyOneGetOneTypes>;
};

/** States the different ipsy subscription programs */
export enum GQLBuyOneGetOneTypes {
  CREDITS = 'CREDITS',
  DEFAULT = 'DEFAULT',
}

/** Represents a marketing campaign */
export type GQLCampaign = {
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
};

export type GQLCancelSubscriptionInstructions = {
  decrementCredits?: InputMaybe<Scalars['Boolean']['input']>;
  refund?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLCancelSubscriptionRequest = {
  instructions?: InputMaybe<GQLCancelSubscriptionInstructions>;
  itemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasons?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCancelSubscriptionResponse = {
  subscription: GQLCanceledSubscription;
  success: Scalars['Boolean']['output'];
};

export type GQLCanceledSubscription = {
  id: Scalars['String']['output'];
  status: GQLSubscriptionStatus;
};

export type GQLCancellationFlow = {
  cancellationStep?: Maybe<GQLCancellationStepResponse>;
  cancellationSteps?: Maybe<Scalars['JSON']['output']>;
};

export type GQLCancellationFlowcancellationStepArgs = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  program?: InputMaybe<GQLProgram>;
};

export type GQLCancellationStepContent = {
  alternativeButtonLabel?: Maybe<Scalars['String']['output']>;
  alternativeButtonStep?: Maybe<Scalars['String']['output']>;
  cmsIdentifier?: Maybe<Scalars['String']['output']>;
  csAction?: Maybe<Scalars['String']['output']>;
  csAlternativeButton?: Maybe<Scalars['String']['output']>;
  csCategory?: Maybe<Scalars['String']['output']>;
  csClosed?: Maybe<Scalars['String']['output']>;
  csCtaButton?: Maybe<Scalars['String']['output']>;
  csViewed?: Maybe<Scalars['String']['output']>;
  ctaButtonLabel?: Maybe<Scalars['String']['output']>;
  ctaButtonStep?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  desktopImage?: Maybe<GQLSimpleImage>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  mobileImage?: Maybe<GQLSimpleImage>;
  onCloseRedirectUrl?: Maybe<Scalars['String']['output']>;
  options?: Maybe<GQLOptions>;
  secondDescription?: Maybe<Scalars['String']['output']>;
  secondSubtitle?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GQLCancellationStepResponse = {
  content?: Maybe<GQLCancellationStepContent>;
  errorType?: Maybe<GQLErrorType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Represents a shopping cart */
export type GQLCart = {
  bogoDiscount?: Maybe<Scalars['Float']['output']>;
  couponDiscount?: Maybe<Scalars['Float']['output']>;
  coupons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  discount?: Maybe<Scalars['Float']['output']>;
  freeGiftStatusBar?: Maybe<Scalars['Float']['output']>;
  freeShippingStatusBar?: Maybe<Scalars['Float']['output']>;
  giftDiscount?: Maybe<GQLCartGiftDiscount>;
  gwpEligible: Scalars['Boolean']['output'];
  handlingFee?: Maybe<GQLCartHandlingFee>;
  hashCode?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invalidReasons?: Maybe<Array<Maybe<GQLCartInvalidReason>>>;
  isFreeShippingAvailable?: Maybe<Scalars['Boolean']['output']>;
  items: Array<GQLCartItem>;
  itemsCount?: Maybe<Scalars['Int']['output']>;
  lastCraving?: Maybe<GQLCollection>;
  maxItemsAllowed?: Maybe<Scalars['Int']['output']>;
  maxItemsReached: Scalars['Boolean']['output'];
  missingValueToFreeGiftThreshold?: Maybe<Scalars['Float']['output']>;
  missingValueToFreeShippingThreshold?: Maybe<Scalars['Float']['output']>;
  paymentMethod?: Maybe<GQLPaymentMethod>;
  paymentMethodInfo?: Maybe<Scalars['Boolean']['output']>;
  savingsWithoutDiscount?: Maybe<Scalars['Float']['output']>;
  shipping?: Maybe<Scalars['Float']['output']>;
  shippingAddress?: Maybe<GQLPhysicalAddress>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalMemberSavings?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Represents gift discount info */
export type GQLCartGiftDiscount = {
  currentBalance: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  remainingBalance: Scalars['Float']['output'];
};

/** Represents a handling fee */
export type GQLCartHandlingFee = {
  note: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type GQLCartInvalidReason = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Represents an item inside a cart */
export type GQLCartItem = {
  id: Scalars['ID']['output'];
  invalidReasons?: Maybe<Array<Maybe<GQLCartItemInvalidReason>>>;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  product?: Maybe<GQLProduct>;
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  regularPrice?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
};

export type GQLCartItemInvalidReason = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Represents a generic category */
export type GQLCategory = {
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** Represents a chained review experience with its url */
export type GQLChainedReviewExperience = {
  hasPendingReviews: Scalars['Boolean']['output'];
  reviewUrl?: Maybe<Scalars['String']['output']>;
};

export enum GQLChangePlanFlow {
  DOWNGRADE = 'DOWNGRADE',
  UPGRADE = 'UPGRADE',
}

export enum GQLChannelType {
  ADD_ONS = 'ADD_ONS',
  BEAUTY_BOOST = 'BEAUTY_BOOST',
  CHOICE = 'CHOICE',
  ECOMMERCE = 'ECOMMERCE',
  ECOMMERCE_SV = 'ECOMMERCE_SV',
  SA = 'SA',
  SO = 'SO',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ULTRA_ADD_ONS_GBP = 'ULTRA_ADD_ONS_GBP',
  ULTRA_ADD_ONS_GBX = 'ULTRA_ADD_ONS_GBX',
  ULTRA_CHOICE_GBP = 'ULTRA_CHOICE_GBP',
  ULTRA_CHOICE_GBX = 'ULTRA_CHOICE_GBX',
}

export type GQLChargeMeNowResponse = {
  status?: Maybe<Scalars['String']['output']>;
};

export type GQLCheckoutAfterPayResponse = {
  afterpay?: Maybe<GQLAfterPayResponse>;
  id?: Maybe<Scalars['String']['output']>;
};

/** Represents checkout experience error */
export type GQLCheckoutExperienceError = {
  cart?: Maybe<GQLCart>;
  msg?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<GQLProductErrorInfo>>>;
};

export type GQLCheckoutExperienceResponse =
  | GQLCheckoutExperienceError
  | GQLCheckoutExperienceSuccess;

/** Represents checkout experience on success */
export type GQLCheckoutExperienceSuccess = {
  result?: Maybe<Scalars['String']['output']>;
};

/** Currently only for collections of IPSY products */
export enum GQLChildrenLoaderClass {
  DEFAULT = 'DEFAULT',
  PARAMETERIZED_USER = 'PARAMETERIZED_USER',
}

export type GQLChoiceBannerInfo = {
  choiceProducts?: Maybe<Array<GQLProduct>>;
  firstname?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  targetDate?: Maybe<Scalars['String']['output']>;
};

export type GQLChooseChoiceItemRequest = {
  glamItemIds: Array<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  subscriptionItemId: Scalars['String']['input'];
};

export type GQLChooseChoiceItemResponse = {
  error?: Maybe<Scalars['Boolean']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  inventorySuccess?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Maybe<GQLChooseItemApiResponseItem>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLChooseItemApiResponseItem = {
  businessId?: Maybe<Scalars['String']['output']>;
  cycleId?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['String']['output']>;
  glamItemId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionItemId?: Maybe<Scalars['String']['output']>;
};

/** Prices not listed here (membershipcosts) */
export type GQLChoosePackagePage = {
  disclaimer?: Maybe<Scalars['String']['output']>;
  imageCentricStyle?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offerings?: Maybe<Array<Maybe<GQLChoosePackagePageOffering>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  withUpgradeBanner?: Maybe<Scalars['Boolean']['output']>;
};

/** the associated gift info if applicable */
export type GQLChoosePackagePageOffering = {
  banner?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  gift?: Maybe<GQLChoosePackagePageOfferingGift>;
  packageTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GQLChoosePackagePageOfferingGift = {
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  mystery?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GQLChoosePackagePageRedirect = {
  gift?: Maybe<GQLChoosePackagePageOfferingGift>;
  name?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type GQLChoosePackagePageResult =
  | GQLChoosePackagePage
  | GQLChoosePackagePageRedirect;

/** Represents a generic collection */
export type GQLCollection = {
  childrenLoaderClass?: Maybe<GQLChildrenLoaderClass>;
  id: Scalars['ID']['output'];
  /** Values to track ML results to what is shown */
  runId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents a collection of categories */
export type GQLCollectionCategories = GQLCollection & {
  children: Array<GQLCategory>;
  childrenLoaderClass?: Maybe<GQLChildrenLoaderClass>;
  id: Scalars['ID']['output'];
  runId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents a collection of collections */
export type GQLCollectionCollections = GQLCollection & {
  children: Array<Maybe<GQLCollection>>;
  childrenLoaderClass?: Maybe<GQLChildrenLoaderClass>;
  id: Scalars['ID']['output'];
  pageInformation?: Maybe<GQLPageInformation>;
  runId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** so we're going to create a new collection type in the meantime */
export type GQLCollectionInterstitialProducts = GQLCollection & {
  children: Array<Maybe<GQLProduct>>;
  childrenLoaderClass?: Maybe<GQLChildrenLoaderClass>;
  id: Scalars['ID']['output'];
  runId?: Maybe<Scalars['String']['output']>;
  tierPrice?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents a collection of products */
export type GQLCollectionProducts = GQLCollection & {
  children: Array<Maybe<GQLProduct>>;
  childrenLoaderClass?: Maybe<GQLChildrenLoaderClass>;
  id: Scalars['ID']['output'];
  runId?: Maybe<Scalars['String']['output']>;
  tierPrice?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents commerce */
export type GQLCommerce = {
  /** Commerce order details by order id */
  bfaOrder?: Maybe<GQLBfaOrder>;
  /** Commerce cart */
  cart?: Maybe<GQLCart>;
  /** Commerce Categories */
  categories: Array<GQLCommerceCategory>;
  event?: Maybe<GQLEvent>;
  /** Product search filters */
  filters: Array<GQLProductFilter>;
  /** Glambag Choice banner info */
  glambagChoiceBannerInfo?: Maybe<GQLChoiceBannerInfo>;
  /** Gift With Purchase */
  gwpInfo?: Maybe<GQLGiftWithPurchase>;
  maxUnitsPerOrder?: Maybe<Scalars['Int']['output']>;
  /** Commerce navigation links */
  navigation?: Maybe<GQLCommerceNavigation>;
  /** Commerce order details by order id */
  order?: Maybe<GQLOrder>;
  /** Product details */
  product?: Maybe<GQLProduct>;
  /** Product Availability */
  productAvailability: Array<Maybe<GQLProductAvailability>>;
  /** All shopper interstitials */
  productRails: Array<GQLProductRail>;
  /** Product search */
  search?: Maybe<GQLCommerceSearch>;
  /** Skinny Banner */
  skinnyBanner?: Maybe<GQLSkinnyBanner>;
  sorting: GQLCommerceSorting;
  /** User Campaigns */
  userCampaigns: Array<GQLCommerceUserCampaign>;
};

/** Represents commerce */
export type GQLCommercebfaOrderArgs = {
  orderId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents commerce */
export type GQLCommercecartArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

/** Represents commerce */
export type GQLCommercecategoriesArgs = {
  onlyActives?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents commerce */
export type GQLCommerceeventArgs = {
  slug: Scalars['String']['input'];
};

/** Represents commerce */
export type GQLCommercefiltersArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  searchCriteria?: InputMaybe<GQLProductSearchInput>;
};

/** Represents commerce */
export type GQLCommercenavigationArgs = {
  previewForDate?: InputMaybe<Scalars['String']['input']>;
};

/** Represents commerce */
export type GQLCommerceorderArgs = {
  orderId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents commerce */
export type GQLCommerceproductArgs = {
  id: Scalars['String']['input'];
};

/** Represents commerce */
export type GQLCommerceproductAvailabilityArgs = {
  commerceExperienceType: GQLCommerceExperienceType;
  productIds: Array<Scalars['String']['input']>;
};

/** Represents commerce */
export type GQLCommerceproductRailsArgs = {
  referer?: InputMaybe<Scalars['String']['input']>;
};

/** Represents commerce */
export type GQLCommercesearchArgs = {
  searchCriteria?: InputMaybe<GQLCommerceSearchCriteria>;
};

/** Represents commerce */
export type GQLCommerceskinnyBannerArgs = {
  language?: InputMaybe<GQLLanguageIds>;
};

/** Represents commerce */
export type GQLCommerceuserCampaignsArgs = {
  previewForDate?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCommerceAppliedFilter = {
  filter: Scalars['String']['output'];
  values: Array<GQLCommerceAppliedFilterValue>;
};

export type GQLCommerceAppliedFilterValue = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQLCommerceBanner = {
  image?: Maybe<GQLResponsiveImage>;
  link?: Maybe<GQLCommerceBannerLink>;
};

export type GQLCommerceBannerLink = {
  filter: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQLCommerceCarousel = {
  banners?: Maybe<Array<GQLCommerceBanner>>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLCommerceCartError = {
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum GQLCommerceCartReferer {
  CART = 'CART',
  CHECKOUT = 'CHECKOUT',
}

/** Represents the cart operation response */
export type GQLCommerceCartResponse = {
  cart?: Maybe<GQLCart>;
  error?: Maybe<GQLCommerceCartError>;
};

export type GQLCommerceCategory = {
  categoryUrl?: Maybe<Scalars['String']['output']>;
  cta: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  filterAttributeId?: Maybe<Scalars['String']['output']>;
  filterType?: Maybe<Scalars['String']['output']>;
  showIcon: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  type: Scalars['String']['output'];
};

/** Represents the checkout cart operation response */
export type GQLCommerceCheckoutCartResponse = {
  cart?: Maybe<GQLCart>;
  error?: Maybe<GQLCommerceCheckoutError>;
  order?: Maybe<GQLBfaOrder>;
};

/** Represents commerce checkout error */
export type GQLCommerceCheckoutError = {
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLCommerceCheckoutResponse =
  | GQLCommerceCheckoutError
  | GQLCommerceCheckoutSuccess;

/** Represents commerce checkout success */
export type GQLCommerceCheckoutSuccess = {
  orderId?: Maybe<Scalars['String']['output']>;
};

/** Represents a commerce experience with its contents and configuration */
export type GQLCommerceExperience = {
  content?: Maybe<GQLCollection>;
  experienceName: GQLCommerceExperienceName;
  selectionLimit?: Maybe<Scalars['Int']['output']>;
};

/** States the different commerce experience names */
export enum GQLCommerceExperienceName {
  WISH_LIST = 'WISH_LIST',
}

export enum GQLCommerceExperienceType {
  ADDONS = 'ADDONS',
  COMMERCE = 'COMMERCE',
}

export type GQLCommerceFilter = {
  filter: Scalars['String']['output'];
  multiselect: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  options: Array<GQLCommerceFilterOption>;
};

/** Represents CommerceFilterInput input type */
export type GQLCommerceFilterInput = {
  filter: Scalars['String']['input'];
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQLCommerceFilterOption = {
  count?: Maybe<Scalars['Int']['output']>;
  isSelected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQLCommerceMutation = {
  addToCart: GQLCommerceCartResponse;
  applyCoupon: GQLCommerceCartResponse;
  /** @deprecated use checkoutCart instead */
  checkout: GQLCommerceCheckoutResponse;
  checkoutCart: GQLCommerceCheckoutCartResponse;
  removeCoupon: GQLCommerceCartResponse;
  removeFromCart: GQLCommerceCartResponse;
  removeUnavailableFromCart: GQLCommerceCartResponse;
  updateCartItem: GQLCommerceCartResponse;
  updateCartPaymentMethod: GQLCommerceCartResponse;
  updateCartShippingAddress: GQLCommerceCartResponse;
};

export type GQLCommerceMutationaddToCartArgs = {
  cartId: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  programType?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

export type GQLCommerceMutationapplyCouponArgs = {
  cartId: Scalars['String']['input'];
  couponCode: Scalars['String']['input'];
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

export type GQLCommerceMutationcheckoutArgs = {
  lastSeenTotal?: InputMaybe<Scalars['Int']['input']>;
  transactionRequestUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCommerceMutationcheckoutCartArgs = {
  commerceExperienceType?: InputMaybe<GQLCommerceExperienceType>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  lastSeenHashCode?: InputMaybe<Scalars['Int']['input']>;
  lastSeenTotal?: InputMaybe<Scalars['Int']['input']>;
  oneTimePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  transactionRequestUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCommerceMutationremoveCouponArgs = {
  cartId: Scalars['String']['input'];
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

export type GQLCommerceMutationremoveFromCartArgs = {
  cartId: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

export type GQLCommerceMutationremoveUnavailableFromCartArgs = {
  cartId: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  invalidReasonCode: Array<InputMaybe<Scalars['String']['input']>>;
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

export type GQLCommerceMutationupdateCartItemArgs = {
  cartId: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

export type GQLCommerceMutationupdateCartPaymentMethodArgs = {
  cartId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  referer?: InputMaybe<GQLCommerceCartReferer>;
};

export type GQLCommerceMutationupdateCartShippingAddressArgs = {
  cartId: Scalars['String']['input'];
  referer?: InputMaybe<GQLCommerceCartReferer>;
  shippingAddressId: Scalars['String']['input'];
};

export type GQLCommerceNavigation = {
  links: Array<GQLCommerceNavigationLink>;
};

export type GQLCommerceNavigationLink = {
  filter?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  showIcon: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Represents CommerceSearch */
export type GQLCommerceSearch = {
  carousel?: Maybe<GQLCommerceCarousel>;
  filters?: Maybe<Array<GQLCommerceFilter>>;
  results?: Maybe<GQLCommerceSearchResults>;
  sorting: GQLCommerceSorting;
};

/** Represents CommerceSearchCriteria input type */
export type GQLCommerceSearchCriteria = {
  filters?: InputMaybe<Array<GQLCommerceFilterInput>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  previewForDate?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the commerce search results */
export type GQLCommerceSearchResults = {
  appliedFilters?: Maybe<Array<Maybe<GQLCommerceAppliedFilter>>>;
  pageInformation?: Maybe<GQLPageInformation>;
  products?: Maybe<Array<Maybe<GQLProduct>>>;
};

export type GQLCommerceSorting = {
  options: Array<GQLCommerceSortingOption>;
};

export type GQLCommerceSortingOption = {
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQLCommerceUserCampaign = {
  filterLabel?: Maybe<Scalars['String']['output']>;
  filterPriority: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isAvailableAsFilter: Scalars['Boolean']['output'];
  isAvailableAsMarketingBadge: Scalars['Boolean']['output'];
  isProductAvailabilityCampaign: Scalars['Boolean']['output'];
  marketingBadgePriority: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Represents a product variant's configurable option */
export type GQLConfigurableOption = {
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<GQLConfigurableOptionType>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum GQLConfigurableOptionType {
  SHADE = 'SHADE',
  SHADE_SWATCH = 'SHADE_SWATCH',
}

export type GQLConfirmationStepAboutUs = {
  bottomImage?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icons?: Maybe<GQLConfirmationStepIcons>;
  list?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GQLConfirmationStepIcons = {
  heart?: Maybe<Scalars['String']['output']>;
  present?: Maybe<Scalars['String']['output']>;
  stars?: Maybe<Scalars['String']['output']>;
};

export type GQLConfirmationStepWhatToExpect = {
  descriptionTitle?: Maybe<Scalars['String']['output']>;
  firstBadgeText?: Maybe<Scalars['String']['output']>;
  firstBadgeTitle?: Maybe<Scalars['String']['output']>;
  secondBadgeText?: Maybe<Scalars['String']['output']>;
  secondBadgeTitle?: Maybe<Scalars['String']['output']>;
  shopAttachmentText?: Maybe<Scalars['String']['output']>;
  shopAttachmentTitle?: Maybe<Scalars['String']['output']>;
  thirdBadgeText?: Maybe<Scalars['String']['output']>;
  thirdBadgeTitle?: Maybe<Scalars['String']['output']>;
};

export type GQLContentConfiguration =
  GQLExtremeCustomizationContentConfiguration;

export enum GQLCountry {
  CA = 'CA',
  US = 'US',
}

export type GQLCouponPageContent = {
  body: Scalars['String']['output'];
  confirmlabel: Scalars['String']['output'];
  confirmurl: Scalars['String']['output'];
  couponcode: Scalars['String']['output'];
  desktopimage?: Maybe<GQLSimpleImage>;
  dismisslabel?: Maybe<Scalars['String']['output']>;
  dismissurl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mobileimage?: Maybe<GQLSimpleImage>;
  title: Scalars['String']['output'];
};

export type GQLCreateAddressInput = {
  /** City name */
  city: Scalars['String']['input'];
  /** Country name */
  country: Scalars['String']['input'];
  /** First name */
  firstName: Scalars['String']['input'];
  /** Last name */
  lastName: Scalars['String']['input'];
  /** First line is mandatory */
  lines: Array<Scalars['String']['input']>;
  /** Postal code. Zip code for US */
  postalCode: GQLPostalCodeInput;
  /** program */
  program: GQLProgram;
  /** State / province name */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateCheckoutAfterPayCommerceInput = {
  cartId: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  redirectCancelUrl: Scalars['String']['input'];
  redirectConfirmUrl: Scalars['String']['input'];
  shippingAddressId: Scalars['String']['input'];
};

export type GQLCreateCheckoutAfterPayInput = {
  cadence: Scalars['Int']['input'];
  period: Scalars['Int']['input'];
  program: Scalars['String']['input'];
  redirectCancelUrl: Scalars['String']['input'];
  redirectConfirmUrl: Scalars['String']['input'];
};

export type GQLCreatePaymentBraintreeInput = {
  paymentMethodNonce: Scalars['String']['input'];
};

export type GQLCreateSubscriptionResponse = {
  successfulChargedPrograms: Array<GQLProgram>;
  successfulPrograms: Array<GQLProgram>;
  unsuccessfulPrograms: Array<GQLProgram>;
  withGift?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLCreateUserSubscriptionInstructions = {
  chargeImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  createLifecycle?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLCreateUserSubscriptionItemDetail = {
  /** implement commerceItems in the future */
  instructions: GQLCreateUserSubscriptionInstructions;
  personalizedItem?: InputMaybe<GQLPersonalizedSubscriptionDetail>;
  shippingAddressId: Scalars['String']['input'];
  singlePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateUserSubscriptionRequest = {
  subscriptionItemDetail: GQLCreateUserSubscriptionItemDetail;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type GQLCreateUserSubscriptionResponse = {
  error: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionStatus?: Maybe<GQLSubscriptionStatus>;
};

/** Represents content/assets of a gift for creators landing page */
export type GQLCreatorGiftProduct = {
  /** Gift product image */
  image: GQLSimpleImage;
  /** Gift product rating */
  rating: Scalars['Float']['output'];
  /** Gift product testimonials */
  testimonials: Array<Scalars['String']['output']>;
};

/** Represents content/assets for creators landing page */
export type GQLCreatorLanding = {
  /** Free GWS description */
  description: Scalars['String']['output'];
  gift: GQLCreatorGiftProduct;
  /** Free GWS head */
  header: Scalars['String']['output'];
};

/** Represent a credit card payment method for either Paymentech or Stripe cards */
export type GQLCreditCard = GQLPaymentMethod & {
  billingAddress?: Maybe<GQLPhysicalAddress>;
  cardType?: Maybe<GQLCreditCardType>;
  description: Scalars['String']['output'];
  expiryMonth?: Maybe<Scalars['String']['output']>;
  expiryYear?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastFour?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** States the supported credit card types */
export enum GQLCreditCardType {
  AMEX = 'AMEX',
  DISCOVER = 'DISCOVER',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

/** Represents an informational notification */
export type GQLCtaNotification = GQLNotification & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  closeable?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<GQLSimpleImage>;
  name: Scalars['String']['output'];
  /** Determines whether or not to show an arrow in UI */
  showArrow?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** Represents a Customization user experience */
export type GQLCustomizationExperience = GQLUserExperience & {
  bogo?: Maybe<GQLBuyOneGetOne>;
  cart: GQLCart;
  configuration: GQLExperienceConfiguration;
  content?: Maybe<GQLCollection>;
  contentConfiguration: GQLContentConfiguration;
  displayTypes?: Maybe<Array<Scalars['String']['output']>>;
  giftWithPurchase?: Maybe<GQLGiftWithPurchase>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nextExperience?: Maybe<Scalars['String']['output']>;
};

/** Represents a program cycle */
export type GQLCycle = {
  /** List of all the products that are part of any of the glambags for this cycle */
  assortment?: Maybe<Array<Maybe<GQLProduct>>>;
  cycleSequenceId?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
};

/** Collection of all products offered in a Cycle. */
export type GQLCycleAssortment = {
  products?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Currently known as a Glambag as described in the Subscriptions API. */
export type GQLCycleBag = {
  bagItems?: Maybe<Array<GQLCycleBagItem>>;
  bagName: Scalars['String']['output'];
  cycle: GQLCycle;
  cycleId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  productId: Scalars['String']['output'];
};

/** Currently known as a GlambagItem as described in the Subscriptions API. */
export type GQLCycleBagItem = {
  availableForChoice: Scalars['Boolean']['output'];
  choiceRank?: Maybe<Scalars['Int']['output']>;
  cycle: GQLCycle;
  cycleId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  product: GQLProduct;
  productId: Scalars['String']['output'];
};

export enum GQLDExperiment {
  GBX_STANDARD = 'GBX_STANDARD',
  GBX_STANDARD_35 = 'GBX_STANDARD_35',
  GBX_STANDARD_44 = 'GBX_STANDARD_44',
}

export type GQLDeviceInfoInput = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  platformVersion?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLErrorType {
  ASSET_NOT_FOUND = 'ASSET_NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type GQLEvent = {
  content?: Maybe<GQLCollection>;
  headerDesktop?: Maybe<Scalars['String']['output']>;
  headerMobile?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  program?: Maybe<GQLEventProgram>;
};

export type GQLEventcontentArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  searchCriteria?: InputMaybe<GQLProductSearchInput>;
};

export type GQLEventProgram = {
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLEventsMutation = {
  publishExperimentEvent?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLEventsMutationpublishExperimentEventArgs = {
  cohortId?: InputMaybe<Scalars['String']['input']>;
  cohortName?: InputMaybe<Scalars['String']['input']>;
  experimentId?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Represents experience configuration */
export type GQLExperienceConfiguration = {
  exitFrictionEnabled: Scalars['Boolean']['output'];
};

export type GQLExtraCycleItem = {
  id?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<GQLMoney>;
  product?: Maybe<GQLProduct>;
  productId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tentativeNextDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLExtremeCustomizationContentConfiguration = {
  bannerDesktop?: Maybe<GQLSimpleImage>;
  bannerMobile?: Maybe<GQLSimpleImage>;
  cancelCta?: Maybe<Scalars['String']['output']>;
  copy: Scalars['String']['output'];
  displayBagInfo: Scalars['Boolean']['output'];
  mainSectionSubtitle?: Maybe<Scalars['String']['output']>;
  mainSectionTitle?: Maybe<Scalars['String']['output']>;
  nextCta: Scalars['String']['output'];
  steps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title: Scalars['String']['output'];
};

export type GQLFacebookInfoInput = {
  authorizedMonthlyShare?: InputMaybe<Scalars['Boolean']['input']>;
  connectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type GQLFallbackPaymentMethodInput = {
  consent: Scalars['Boolean']['input'];
  paymentMethodId: Scalars['String']['input'];
};

export type GQLFallbackPaymentMethodResponse = {
  businessId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** Represents content/assets for featured section */
export type GQLFeatured = {
  logos: Array<GQLSimpleImage>;
  title: Scalars['String']['output'];
};

/** Represents the Footer element in the web page and its dynamic contents */
export type GQLFooter = {
  links: Array<Maybe<GQLLink>>;
  title: Scalars['String']['output'];
};

export type GQLFreeBagContent = {
  discountLabel?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  renewalDisclaimer?: Maybe<Scalars['String']['output']>;
  subheading?: Maybe<Scalars['String']['output']>;
  submitLabel?: Maybe<Scalars['String']['output']>;
};

export type GQLFulfillmentOrderSubData = {
  cycle?: Maybe<GQLCycle>;
  ipsymxGlamBagImage?: Maybe<GQLSimpleImage>;
  month?: Maybe<Scalars['String']['output']>;
  program?: Maybe<GQLProgram>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type GQLGbxOneClickLandingPage = {
  checkoutBannerText?: Maybe<Scalars['String']['output']>;
  checkoutShippingDisclaimer?: Maybe<Scalars['String']['output']>;
  checkoutSubscriptionDisclaimer?: Maybe<Scalars['String']['output']>;
  cmsIdentifier: Scalars['String']['output'];
  landingBulletsPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  landingButtonCta?: Maybe<Scalars['String']['output']>;
  landingChargeCopy?: Maybe<Scalars['String']['output']>;
  landingErrorMsg?: Maybe<Scalars['String']['output']>;
  landingExplanation?: Maybe<Scalars['String']['output']>;
  landingExplanatory?: Maybe<Scalars['String']['output']>;
  landingHeader?: Maybe<Scalars['String']['output']>;
  landingHeroDesktopImg?: Maybe<GQLSimpleImage>;
  landingHeroMobileImg?: Maybe<GQLSimpleImage>;
  landingLearnMoreBulletPoints?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  landingLearnMoreExplanatory?: Maybe<Scalars['String']['output']>;
  landingLearnMoreHeader?: Maybe<Scalars['String']['output']>;
  landingValueProperty?: Maybe<Scalars['String']['output']>;
  noThanksCta?: Maybe<Scalars['String']['output']>;
};

export type GQLGbxOneClickUpgradeResponse = {
  error: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  isPaymentDecline: Scalars['Boolean']['output'];
  isWithGift: Scalars['Boolean']['output'];
};

export type GQLGbxOrganicCheckoutPage = {
  buttonCta?: Maybe<Scalars['String']['output']>;
  cancellation?: Maybe<Scalars['String']['output']>;
  cmsIdentifier: Scalars['String']['output'];
  header?: Maybe<Scalars['String']['output']>;
  info?: Maybe<GQLGbxOrganicCheckoutPageInfo>;
  notificationBulletPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  paymentError?: Maybe<Scalars['Boolean']['output']>;
  paymentErrorMsg?: Maybe<Scalars['String']['output']>;
  paymentIcon?: Maybe<GQLSimpleImage>;
  paymentInfo?: Maybe<Scalars['String']['output']>;
  shippingDisclaimer?: Maybe<Scalars['String']['output']>;
  shippingLearnMore?: Maybe<Scalars['String']['output']>;
  subHeader?: Maybe<Scalars['String']['output']>;
  subscriptionDisclaimer?: Maybe<Scalars['String']['output']>;
  upgradePrice?: Maybe<Scalars['String']['output']>;
  upgradeProgram?: Maybe<Scalars['String']['output']>;
};

export type GQLGbxOrganicCheckoutPageInfo = {
  bulletPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  header?: Maybe<Scalars['String']['output']>;
};

export type GQLGbxOrganicLandingPage = {
  bulletPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  buttonCta?: Maybe<Scalars['String']['output']>;
  cmsIdentifier: Scalars['String']['output'];
  explanatory?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  heroImgDesktop?: Maybe<GQLSimpleImage>;
  heroImgMobile?: Maybe<GQLSimpleImage>;
  learnMore?: Maybe<GQLGbxOrganicLandingPageLearnMore>;
  upgradePrice?: Maybe<Scalars['String']['output']>;
  valueProperty?: Maybe<Scalars['String']['output']>;
};

export type GQLGbxOrganicLandingPageLearnMore = {
  bulletPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  explanatory?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
};

export type GQLGbxOrganicResponse = {
  checkoutPage: GQLGbxOrganicCheckoutPage;
  landingPage: GQLGbxOrganicLandingPage;
};

export type GQLGbxUpgradeMutation = {
  gbxUpgradeTransaction?: Maybe<GQLGbxOneClickUpgradeResponse>;
};

export type GQLGetAvailableItemsForChoiceRequest = {
  subscriptionId: Scalars['String']['input'];
  subscriptionItemId: Scalars['String']['input'];
};

/** Represent a gift card payment method */
export type GQLGiftCard = GQLPaymentMethod & {
  cardType?: Maybe<GQLCreditCardType>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastFour?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GQLGiftCardActivationDetails = {
  balance?: Maybe<GQLGiftCardBalance>;
  giftCard?: Maybe<GQLGiftCardDetails>;
};

export type GQLGiftCardBalance = {
  balance?: Maybe<GQLMoneyInt>;
  businessId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type GQLGiftCardContent = {
  appliedBalance?: Maybe<Scalars['String']['output']>;
  banner?: Maybe<Scalars['String']['output']>;
  currentBalance?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  remainingBalance?: Maybe<Scalars['String']['output']>;
  renewal?: Maybe<Scalars['String']['output']>;
};

/** Represents gift card information */
export type GQLGiftCardDetails = {
  balance?: Maybe<GQLMoneyInt>;
  businessId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currentBalance?: Maybe<GQLMoneyInt>;
  id?: Maybe<Scalars['ID']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  recipientDetails?: Maybe<GQLGiftRecipient>;
  senderDetails?: Maybe<GQLGiftSender>;
  status?: Maybe<Scalars['String']['output']>;
};

export type GQLGiftDetail = {
  productId: Scalars['String']['input'];
};

export type GQLGiftRecipient = {
  email: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  scheduledNotificationTime?: Maybe<Scalars['String']['output']>;
};

/** Represents recipient's info when sending a gift */
export type GQLGiftRecipientInput = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scheduledNotificationTime: Scalars['String']['input'];
};

/** Represents gift giver information */
export type GQLGiftSender = {
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** Represents sender's info when sending a gift */
export type GQLGiftSenderInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents information to show the user if is eligible for GiftWithPurchase */
export type GQLGiftWithPurchase = {
  endDate?: Maybe<Scalars['DateTime']['output']>;
  isGWPEligible: Scalars['Boolean']['output'];
  product: GQLProduct;
  program?: Maybe<GQLProgram>;
  threshold: Scalars['Int']['output'];
};

export type GQLGiftWithSubContent = {
  restrictedAddressMessage?: Maybe<Scalars['String']['output']>;
  summaryAmount?: Maybe<Scalars['String']['output']>;
  summaryLabel?: Maybe<Scalars['String']['output']>;
};

/** Represents response for a successful gift card creation */
export type GQLGifting = {
  balance?: Maybe<Scalars['Float']['output']>;
  giftCardDetails?: Maybe<Array<Maybe<GQLGiftCardDetails>>>;
};

/** Represents response for a successful gift card creation */
export type GQLGiftinggiftCardDetailsArgs = {
  giftCardId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents Gifting mutations */
export type GQLGiftingMutation = {
  generateGift?: Maybe<GQLGiftingResponse>;
  redeemGift?: Maybe<GQLGiftCardActivationDetails>;
  redeemSubscriptionGiftCard?: Maybe<GQLRedeemSubGiftCardResponse>;
};

/** Represents Gifting mutations */
export type GQLGiftingMutationgenerateGiftArgs = {
  balance: GQLMoneyInput;
  recipient: GQLGiftRecipientInput;
  sender: GQLGiftSenderInput;
};

/** Represents Gifting mutations */
export type GQLGiftingMutationredeemGiftArgs = {
  giftId: Scalars['String']['input'];
};

/** Represents Gifting mutations */
export type GQLGiftingMutationredeemSubscriptionGiftCardArgs = {
  giftCardId: Scalars['String']['input'];
  shippingAddress?: InputMaybe<GQLPhysicalAddressInput>;
};

export type GQLGiftingResponse = {
  isSuccessful: Scalars['Boolean']['output'];
};

export type GQLGiftingResponseError = GQLGiftingResponse & {
  code?: Maybe<Scalars['String']['output']>;
  customMessage?: Maybe<Scalars['String']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  messageKey?: Maybe<Scalars['String']['output']>;
};

/** Represents response for a successful gift card creation */
export type GQLGiftingResponseSuccess = GQLGiftingResponse & {
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  giftCardId: Scalars['String']['output'];
  giverEmail: Scalars['String']['output'];
  giverName: Scalars['String']['output'];
  isSuccessful: Scalars['Boolean']['output'];
  recipientEmail: Scalars['String']['output'];
  recipientMessage?: Maybe<Scalars['String']['output']>;
  recipientName: Scalars['String']['output'];
  scheduledNotificationTime: Scalars['String']['output'];
};

export type GQLGlamBagAction = {
  analyticsEvent?: Maybe<Scalars['String']['output']>;
  href?: Maybe<Scalars['String']['output']>;
  labelForAnalytics?: Maybe<Scalars['String']['output']>;
  name: GQLGlamBagActionName;
  title: Scalars['String']['output'];
};

export enum GQLGlamBagActionName {
  GET_TEXT_NOTIFICATIONS = 'GET_TEXT_NOTIFICATIONS',
  PAST_GLAM_BAGS = 'PAST_GLAM_BAGS',
  REVIEW_GLAM_BAG = 'REVIEW_GLAM_BAG',
  SEE_ALL_PRODUCTS = 'SEE_ALL_PRODUCTS',
  SHARE_GLAM_BAG = 'SHARE_GLAM_BAG',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
}

export type GQLGlamBagChoiceMutation = {
  chooseItem?: Maybe<GQLChooseChoiceItemResponse>;
};

export type GQLGlamBagChoiceMutationchooseItemArgs = {
  request: GQLChooseChoiceItemRequest;
};

/** Represents the different glambag page screen contents */
export type GQLGlamBagContent = {
  actionBar: Array<GQLGlamBagAction>;
  bagDetails?: Maybe<GQLBag>;
  bagReviewShareCid?: Maybe<Scalars['String']['output']>;
  bagShareCid?: Maybe<Scalars['String']['output']>;
  gbxTab: GQLSimpleImage;
  noProducts?: Maybe<Scalars['JSON']['output']>;
  productReviewShareCid?: Maybe<Scalars['String']['output']>;
  productReviewsEnabled?: Maybe<Scalars['String']['output']>;
  tabs: Array<GQLGlamBagTab>;
};

/** Represents the different glambag page screen contents */
export type GQLGlamBagContentactionBarArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents the different glambag page screen contents */
export type GQLGlamBagContentbagDetailsArgs = {
  asPastBag?: InputMaybe<Scalars['Boolean']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  program?: InputMaybe<GQLProgram>;
  year?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the different glambag page screen contents */
export type GQLGlamBagContentbagShareCidArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents the different glambag page screen contents */
export type GQLGlamBagContentnoProductsArgs = {
  asPastBag?: InputMaybe<Scalars['Boolean']['input']>;
  cycleMonth?: InputMaybe<Scalars['String']['input']>;
  cycleYear?: InputMaybe<Scalars['String']['input']>;
  program?: InputMaybe<GQLProgram>;
};

/** Represents the different glambag page screen contents */
export type GQLGlamBagContentproductReviewsEnabledArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents a tab item for glambag page */
export type GQLGlamBagTab = {
  href: Scalars['String']['output'];
  label: Scalars['String']['output'];
  program: GQLProgram;
};

export type GQLGlambagChoiceEligibility = {
  bagItems?: Maybe<Array<Maybe<GQLCycleBagItem>>>;
  bagItemsCount?: Maybe<Scalars['Int']['output']>;
  isEligible: Scalars['Boolean']['output'];
  /** Results of individual checks */
  isUesChoiceExperienceEligible: Scalars['Boolean']['output'];
  skinToneCatalog1pFieldName?: Maybe<Scalars['String']['output']>;
};

/** Responses */
export type GQLGwsEligibilityResponse = {
  error?: Maybe<GQLGwsErrorType>;
  globalFeatureOn?: Maybe<Scalars['Boolean']['output']>;
  gwsFeatureOn?: Maybe<Scalars['Boolean']['output']>;
  isEligible?: Maybe<Scalars['Boolean']['output']>;
};

/** Enums */
export enum GQLGwsErrorType {
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
  FEATURE_DISABLED = 'FEATURE_DISABLED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_GWS_GIFT_TYPE = 'INVALID_GWS_GIFT_TYPE',
  INVALID_GWS_TYPE = 'INVALID_GWS_TYPE',
  INVALID_PRODUCT_ID = 'INVALID_PRODUCT_ID',
  NO_INVENTORY = 'NO_INVENTORY',
  PRODUCT_RESTRICTED = 'PRODUCT_RESTRICTED',
  REDEMPTION_CANCELED_BY_ELIGIBILITY = 'REDEMPTION_CANCELED_BY_ELIGIBILITY',
  UNSUPPORTED_FEATURE = 'UNSUPPORTED_FEATURE',
  USER_ID_NOT_FOUND = 'USER_ID_NOT_FOUND',
  USER_SUBSCRIPTION_NOT_FOUND = 'USER_SUBSCRIPTION_NOT_FOUND',
}

/** Mutation */
export type GQLGwsManagementMutation = {
  redeemGiftWithSubscription: GQLGwsRedemptionResponse;
};

/** Mutation */
export type GQLGwsManagementMutationredeemGiftWithSubscriptionArgs = {
  redemptionRequest: GQLGwsRequest;
};

export type GQLGwsRedemptionResponse = {
  error?: Maybe<GQLGwsErrorType>;
  globalFeatureOn?: Maybe<Scalars['Boolean']['output']>;
  gwsFeatureOn?: Maybe<Scalars['Boolean']['output']>;
  gwsType: GQLGwsType;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLGwsRequest = {
  productId?: InputMaybe<Scalars['String']['input']>;
  program?: InputMaybe<GQLProgram>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLGwsType {
  BOXYCHARM_ANNUAL_UPGRADE_GIFT = 'BOXYCHARM_ANNUAL_UPGRADE_GIFT',
  BOXYCHARM_REACTIVATION_EMAIL_GIFT = 'BOXYCHARM_REACTIVATION_EMAIL_GIFT',
  BOXYCHARM_REACTIVATION_POPUP_GIFT = 'BOXYCHARM_REACTIVATION_POPUP_GIFT',
  GENERIC = 'GENERIC',
  GIFT_WITH_SUBSCRIPTION_NEW = 'GIFT_WITH_SUBSCRIPTION_NEW',
  GLAMBAG_ANNUAL_UPGRADE_GIFT = 'GLAMBAG_ANNUAL_UPGRADE_GIFT',
  GLAMBAG_REACTIVATION_EMAIL_GIFT = 'GLAMBAG_REACTIVATION_EMAIL_GIFT',
  GLAMBAG_REACTIVATION_POPUP_GIFT = 'GLAMBAG_REACTIVATION_POPUP_GIFT',
  ICONBOX_UPGRADE_GIFT = 'ICONBOX_UPGRADE_GIFT',
  ICONBOX_UPGRADE_GIFT_EMAIL = 'ICONBOX_UPGRADE_GIFT_EMAIL',
  INVALID_TYPE = 'INVALID_TYPE',
  NEVER_PAST_SUBS_FREE_GIFT = 'NEVER_PAST_SUBS_FREE_GIFT',
  ONE_MONTH_DELIGHT = 'ONE_MONTH_DELIGHT',
  REACTIVATION_GIFT_ONE_CLICK = 'REACTIVATION_GIFT_ONE_CLICK',
  UNPAUSE_GIFT = 'UNPAUSE_GIFT',
}

/** Represents content/assets for the home page */
export type GQLHomepageContent = {
  content: GQLLoggedOutContent;
  creatorLanding?: Maybe<GQLCreatorLanding>;
  featured?: Maybe<GQLFeatured>;
  howItWorks?: Maybe<GQLHowItWorks>;
  /** Whether Get Started cta should be rendered between heading and subHeading. */
  isGetStartedButtonInMiddle: Scalars['Boolean']['output'];
  /** Whether the user is a part of Give and Get Impact Experiment */
  isGiveAndGetImpact: Scalars['Boolean']['output'];
  /**
   * Whether Hero image has pink background.
   * @deprecated It won't be used anymore.
   */
  isHeroBackgroundPink: Scalars['Boolean']['output'];
  /** Whether the facebook button on login form should be hidden */
  shouldShowFbLoginIos: Scalars['Boolean']['output'];
  whyChoose?: Maybe<GQLWhyChoose>;
};

/** Represents content/assets for how it works section */
export type GQLHowItWorks = {
  sections: Array<GQLHowItWorksSection>;
};

/** Represents content/assets for how it works section */
export type GQLHowItWorksSection = {
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQLIconBoxContent = {
  bannerDescription?: Maybe<Scalars['String']['output']>;
  bannerImage?: Maybe<GQLResponsiveImage>;
  bannerTitle?: Maybe<Scalars['String']['output']>;
  iconParentPackage?: Maybe<GQLIconParentPackage>;
  incrementalPriceLabel?: Maybe<Scalars['String']['output']>;
  parentPackageDisclaimer?: Maybe<Scalars['String']['output']>;
};

export enum GQLIconParentPackage {
  GLAMBAGX_QUARTERLY = 'GLAMBAGX_QUARTERLY',
  MONTHLY = 'MONTHLY',
  MONTHLY_FULLSIZE = 'MONTHLY_FULLSIZE',
}

/** Represents retrieved Instagram content */
export type GQLInstagramSocialMediaContent = {
  posts: Array<GQLInstagramSocialMediaPost>;
};

/** Represents retrieved Instagram content */
export type GQLInstagramSocialMediaContentpostsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents a media posts from Instagram */
export type GQLInstagramSocialMediaPost = {
  caption?: Maybe<Scalars['String']['output']>;
  mediaUrl: Scalars['String']['output'];
  permalink: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GQLInterstitialExperience = GQLUserExperience & {
  /** @deprecated Use commerce.cart instead */
  cart: GQLCart;
  commerceConfigMode?: Maybe<Scalars['String']['output']>;
  content?: Maybe<GQLCollection>;
  description?: Maybe<Scalars['String']['output']>;
  displayTypes?: Maybe<Array<Scalars['String']['output']>>;
  giftWithPurchase?: Maybe<GQLGiftWithPurchase>;
  id: Scalars['ID']['output'];
  layout?: Maybe<GQLInterstitialExperienceLayout>;
  name: Scalars['String']['output'];
  nextExperience?: Maybe<Scalars['String']['output']>;
  product?: Maybe<GQLProduct>;
  subhead?: Maybe<Scalars['String']['output']>;
};

export type GQLInterstitialExperiencecontentArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  searchCriteria?: InputMaybe<GQLProductSearchInput>;
};

export type GQLInterstitialExperienceproductArgs = {
  id: Scalars['String']['input'];
};

export type GQLInterstitialExperienceLayout = {
  banner?: Maybe<Scalars['String']['output']>;
  contentCallout?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disclaimerText?: Maybe<Scalars['String']['output']>;
  displayUntil?: Maybe<Scalars['String']['output']>;
  headerImageDesktop?: Maybe<Scalars['String']['output']>;
  headerImageMobile?: Maybe<Scalars['String']['output']>;
  maxUnitsPerOrder?: Maybe<Scalars['Int']['output']>;
  program?: Maybe<GQLInterstitialExperienceProgram>;
  subhead?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  useHeaderComponent?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLInterstitialExperienceProgram = {
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLInterstitialMutation = {
  addToCart: GQLCart;
  checkout: Scalars['String']['output'];
  removeFromCart: GQLCart;
};

export type GQLInterstitialMutationaddToCartArgs = {
  cartId: Scalars['String']['input'];
  cartType: Scalars['String']['input'];
  cartUrl: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  productImageUrl: Scalars['String']['input'];
  programType: Scalars['String']['input'];
};

export type GQLInterstitialMutationcheckoutArgs = {
  byPassGwp?: InputMaybe<Scalars['Boolean']['input']>;
  cartUrl: Scalars['String']['input'];
  transactionRequestUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GQLInterstitialMutationremoveFromCartArgs = {
  cartId: Scalars['String']['input'];
  cartItemId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLJoinNowResponse = {
  url?: Maybe<Scalars['String']['output']>;
};

/** These are the string values identifiers of the languages supported by the CMS */
export enum GQLLanguageIds {
  EN_US = 'EN_US',
  ES_MX = 'ES_MX',
}

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContent = {
  addonsMultiUnitMaxDefaultValue?: Maybe<Scalars['Int']['output']>;
  carousel?: Maybe<Scalars['JSON']['output']>;
  choosePackagePageResult?: Maybe<GQLChoosePackagePageResult>;
  confirmationWithSteps?: Maybe<Scalars['Boolean']['output']>;
  eligibleForFeature?: Maybe<Scalars['Boolean']['output']>;
  footer: GQLFooter;
  gbxOneClickUpgradePage?: Maybe<GQLGbxOneClickLandingPage>;
  gbxOrganicUpgradePage?: Maybe<GQLGbxOrganicResponse>;
  genericCarousel?: Maybe<Scalars['JSON']['output']>;
  genericInterstitial?: Maybe<Scalars['JSON']['output']>;
  glamBag: GQLGlamBagContent;
  globalVariable?: Maybe<Scalars['String']['output']>;
  homepage: GQLHomepageContent;
  joinNow?: Maybe<GQLJoinNowResponse>;
  membershipConfirmation?: Maybe<GQLMembershipConfirmationPage>;
  membershipConfirmationSteps?: Maybe<GQLMembershipConfirmationStepsPage>;
  navigation?: Maybe<GQLNavigation>;
  ogMetaData: GQLOgMetaData;
  productReview: GQLProductReviewContent;
  subscribeCheckoutPage?: Maybe<GQLSubscribeCheckoutPageResult>;
  subscriptionUpsaleExperience?: Maybe<GQLSubscriptionUpsaleExperience>;
  useNewMembershipConfirmationPage?: Maybe<Scalars['Boolean']['output']>;
  vcmsAsset?: Maybe<Scalars['JSON']['output']>;
  /** Fetches CMS asset by structure and properties. Also swaps value of properties[key] with experiment context variable if it exists. */
  vcmsAssetAb?: Maybe<Scalars['JSON']['output']>;
  vcmsAssets?: Maybe<Array<Scalars['JSON']['output']>>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentcarouselArgs = {
  key: Scalars['String']['input'];
  program: GQLProgram;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentchoosePackagePageResultArgs = {
  cid?: InputMaybe<Scalars['String']['input']>;
  sid?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContenteligibleForFeatureArgs = {
  globalKey?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentgbxOrganicUpgradePageArgs = {
  dExperiment?: InputMaybe<GQLDExperiment>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentgenericCarouselArgs = {
  key: Scalars['String']['input'];
  structure: Scalars['String']['input'];
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentgenericInterstitialArgs = {
  id: Scalars['String']['input'];
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentglobalVariableArgs = {
  key: Scalars['String']['input'];
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContenthomepageArgs = {
  freeBagInviteId?: InputMaybe<Scalars['String']['input']>;
  lpid?: InputMaybe<Scalars['String']['input']>;
  referId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentjoinNowArgs = {
  program: GQLProgram;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentmembershipConfirmationArgs = {
  flow?: InputMaybe<Scalars['String']['input']>;
  successfulPrograms: Array<GQLProgram>;
  unsuccessfulPrograms?: InputMaybe<Array<InputMaybe<GQLProgram>>>;
  withGift?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentmembershipConfirmationStepsArgs = {
  successfulPrograms?: InputMaybe<Array<GQLProgram>>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentogMetaDataArgs = {
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentsubscribeCheckoutPageArgs = {
  isFreeBag?: InputMaybe<Scalars['Boolean']['input']>;
  isGift?: InputMaybe<Scalars['Boolean']['input']>;
  packageTypesParam?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentsubscriptionUpsaleExperienceArgs = {
  flow: GQLSubscriptionFlow;
  program: GQLProgram;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentvcmsAssetArgs = {
  language?: InputMaybe<GQLLanguageIds>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  structure: Scalars['String']['input'];
  throwError?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentvcmsAssetAbArgs = {
  key: Scalars['String']['input'];
  language?: InputMaybe<GQLLanguageIds>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  structure: Scalars['String']['input'];
  throwError?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents the content/assets for certain page or global component */
export type GQLLayoutContentvcmsAssetsArgs = {
  language?: InputMaybe<GQLLanguageIds>;
  limit: Scalars['Int']['input'];
  properties?: InputMaybe<Scalars['JSON']['input']>;
  structure: Scalars['String']['input'];
  throwError?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents a user lifecycle */
export type GQLLifecycle = {
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Represents a hyperlink */
export type GQLLink = {
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Represents the content/assets shown to logged out users in the homepage */
export type GQLLoggedOutContent = {
  /** Optional Text shown after the Button */
  belowButtonDisclaimer?: Maybe<Scalars['String']['output']>;
  /** Text for the Subscribe Button */
  button?: Maybe<Scalars['String']['output']>;
  headline: Scalars['String']['output'];
  heroImage?: Maybe<GQLResponsiveImage>;
  rotating?: Maybe<Scalars['String']['output']>;
  subHeading: GQLResponsiveText;
};

export type GQLMarketingBadge = {
  addCountdown: Scalars['Boolean']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type GQLMembershipConfirmationBottomModule = {
  cta: GQLMembershipConfirmationButton;
  disclaimer?: Maybe<Scalars['String']['output']>;
  header: Scalars['String']['output'];
  steps: Array<GQLMembershipConfirmationBottomStep>;
};

export type GQLMembershipConfirmationBottomModulectaArgs = {
  program?: InputMaybe<GQLProgram>;
};

export type GQLMembershipConfirmationBottomStep = {
  description: Scalars['String']['output'];
  header: Scalars['String']['output'];
  step: Scalars['Int']['output'];
};

export type GQLMembershipConfirmationButton = {
  link?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type GQLMembershipConfirmationPage = {
  bottomModule?: Maybe<GQLMembershipConfirmationBottomModule>;
  programDisclaimer?: Maybe<Scalars['String']['output']>;
  programModules?: Maybe<Array<GQLMembershipConfirmationProgramModule>>;
  topModule: GQLMembershipConfirmationTopModule;
  type: GQLMembershipConfirmationPageType;
};

export enum GQLMembershipConfirmationPageType {
  BB_ONLY = 'BB_ONLY',
  BB_ONLY_WAITLIST = 'BB_ONLY_WAITLIST',
  DEFAULT = 'DEFAULT',
  GBX_ONLY = 'GBX_ONLY',
  WELCOME_BAG = 'WELCOME_BAG',
}

export type GQLMembershipConfirmationProgramModule = {
  description: Scalars['String']['output'];
  header: Scalars['String']['output'];
  highlight?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image: GQLSimpleImage;
  isFullSizeImage: Scalars['Boolean']['output'];
  program: GQLProgram;
  status: GQLSubscriptionStatus;
  type: GQLProgramModuleBagType;
};

export type GQLMembershipConfirmationStepPage = {
  aboutUs?: Maybe<GQLConfirmationStepAboutUs>;
  background?: Maybe<Scalars['String']['output']>;
  headerHelpText?: Maybe<Scalars['String']['output']>;
  headerTitle?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  whatToExpect?: Maybe<GQLConfirmationStepWhatToExpect>;
};

export type GQLMembershipConfirmationStepsPage = {
  generalMembershipConfirmationPage?: Maybe<GQLMembershipConfirmationStepPage>;
  refreshmentsMembershipConfirmationPage?: Maybe<GQLMembershipConfirmationStepPage>;
};

export type GQLMembershipConfirmationTopModule = {
  body?: Maybe<Scalars['String']['output']>;
  divider: Scalars['Boolean']['output'];
  errorText?: Maybe<Scalars['String']['output']>;
  giftBannerText?: Maybe<Scalars['String']['output']>;
  giftHeaderText?: Maybe<Scalars['String']['output']>;
  header0?: Maybe<Scalars['String']['output']>;
  header1?: Maybe<Scalars['String']['output']>;
  header2?: Maybe<Scalars['String']['output']>;
  header3?: Maybe<Scalars['String']['output']>;
  topBannerText?: Maybe<Scalars['String']['output']>;
};

export type GQLMembershipCosts = {
  handlingFee?: Maybe<GQLMoney>;
  personalizedProductConfigId?: Maybe<Scalars['String']['output']>;
  /** Price before taxes */
  price?: Maybe<GQLMoney>;
  shippingCost?: Maybe<GQLMoney>;
  /** Taxes cost */
  taxes?: Maybe<GQLMoney>;
};

export type GQLMembershipCostshandlingFeeArgs = {
  country: GQLCountry;
};

export type GQLMembershipCostsshippingCostArgs = {
  country: GQLCountry;
};

export type GQLMigrationJson = {
  migrationDate?: Maybe<Scalars['DateTime']['output']>;
  migrationSrc?: Maybe<Scalars['String']['output']>;
};

/** Represents money Using `ISO 4217` codes */
export type GQLMoney = {
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

/** Represents money expressed in cents. This input corresponds to type MoneyInt */
export type GQLMoneyInput = {
  /** value in cents */
  amount: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
};

/** Represents money in cents to avoid issues with floating numbers */
export type GQLMoneyInt = {
  /** value in cents */
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
};

/** List of supported mutations */
export type GQLMutation = {
  addActiveSubscriptionItem?: Maybe<GQLCreateSubscriptionResponse>;
  addRefreshmentsSubscriptionItem: GQLUserSubscription;
  addRewardsPoints?: Maybe<GQLRewardsPointsMutation>;
  addToCart: GQLCart;
  addressManagement: GQLAddressManagementMutation;
  analytics: GQLAnalyticsMutation;
  assignInvitedUser: Scalars['Boolean']['output'];
  cancelRefreshmentsSubItems: GQLUserSubscription;
  cancelRefreshmentsSubscription: GQLUserSubscription;
  changePassword: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favor of checkoutExperience */
  checkout: Scalars['Boolean']['output'];
  checkoutBeautyBoost: GQLBBCheckoutResponse;
  checkoutExperience: GQLCheckoutExperienceResponse;
  choiceManagement?: Maybe<GQLGlamBagChoiceMutation>;
  commerce?: Maybe<GQLCommerceMutation>;
  createCheckoutAfterPay?: Maybe<GQLCheckoutAfterPayResponse>;
  createCheckoutAfterPayCommerce?: Maybe<GQLCheckoutAfterPayResponse>;
  createPaymentMethodBraintree?: Maybe<GQLPaymentMethodWithBraintreeAccount>;
  createPaymentMethodWithStripe?: Maybe<GQLPaymentMethodStripeResponse>;
  createRefreshmentsSubscription: GQLUserSubscription;
  events: GQLEventsMutation;
  forgotPassword: Scalars['Boolean']['output'];
  gbxUpgrade?: Maybe<GQLGbxUpgradeMutation>;
  giftingExperience?: Maybe<GQLGiftingMutation>;
  gwsManagement: GQLGwsManagementMutation;
  interstitial?: Maybe<GQLInterstitialMutation>;
  login: GQLAuthentication;
  passwordManagement: GQLPasswordManagementMutation;
  payments: GQLPaymentsMutation;
  /** Wrapper of the Payments team owned mutations */
  paymentsManagement: GQLPaymentsManagementMutation;
  productReviewQuiz?: Maybe<GQLProductReviewQuizMutation>;
  purchaseExtraCredits: GQLPurchaseExtraCreditsResponse;
  quiz?: Maybe<GQLQuizMutation>;
  reactivateSubscriptionItem?: Maybe<GQLCreateSubscriptionResponse>;
  removeFromCart: GQLCart;
  signUp: GQLUserDetails;
  skipExperience: Scalars['String']['output'];
  socialAccountManagement: GQLSocialAccountManagementMutation;
  subscriptionManagement: GQLSubscriptionManagement;
  triggerSubscriptionCancelationEmail: Scalars['String']['output'];
  updateRefreshmentsSubItem: GQLSubscriptionItem;
  updateShippingAddress: GQLUserSubscription;
  updateUserDetails: GQLUserDetails;
  upgradeToAnnualSubscription?: Maybe<GQLAnnualUpgradeResponse>;
  userPhoneNumber?: Maybe<GQLUserPhoneNumberMutation>;
  userSubscriptionManagement: GQLUserSubscriptionManagement;
};

/** List of supported mutations */
export type GQLMutationaddActiveSubscriptionItemArgs = {
  packagePeriod?: InputMaybe<GQLPackagePeriod>;
  programs?: InputMaybe<Array<InputMaybe<GQLProgram>>>;
};

/** List of supported mutations */
export type GQLMutationaddRefreshmentsSubscriptionItemArgs = {
  cadence?: InputMaybe<Scalars['Int']['input']>;
  expirationPolicy?: InputMaybe<GQLSubscriptionItemExpirationPolicy>;
  monthsOffset: Scalars['Int']['input'];
  productIds: Array<Scalars['String']['input']>;
};

/** List of supported mutations */
export type GQLMutationaddToCartArgs = {
  experienceName: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  program: GQLProgram;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** List of supported mutations */
export type GQLMutationcancelRefreshmentsSubItemsArgs = {
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
  subscriptionItemIds: Array<Scalars['String']['input']>;
};

/** List of supported mutations */
export type GQLMutationchangePasswordArgs = {
  confirmPassword: Scalars['String']['input'];
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

/** List of supported mutations */
export type GQLMutationcheckoutArgs = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** List of supported mutations */
export type GQLMutationcheckoutBeautyBoostArgs = {
  glamBagId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};

/** List of supported mutations */
export type GQLMutationcheckoutExperienceArgs = {
  experienceName: Scalars['String']['input'];
  products?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  program?: InputMaybe<GQLProgram>;
};

/** List of supported mutations */
export type GQLMutationcreateCheckoutAfterPayArgs = {
  createCheckoutAfterPayInput?: InputMaybe<GQLCreateCheckoutAfterPayInput>;
};

/** List of supported mutations */
export type GQLMutationcreateCheckoutAfterPayCommerceArgs = {
  createCheckoutAfterPayCommerceInput?: InputMaybe<GQLCreateCheckoutAfterPayCommerceInput>;
};

/** List of supported mutations */
export type GQLMutationcreatePaymentMethodBraintreeArgs = {
  createPaymentBraintreeInput: GQLCreatePaymentBraintreeInput;
};

/** List of supported mutations */
export type GQLMutationcreatePaymentMethodWithStripeArgs = {
  billingAddressId: Scalars['String']['input'];
  flow?: InputMaybe<Scalars['String']['input']>;
  stripeMethodId: Scalars['String']['input'];
  walletSource?: InputMaybe<Scalars['String']['input']>;
};

/** List of supported mutations */
export type GQLMutationforgotPasswordArgs = {
  email: Scalars['String']['input'];
};

/** List of supported mutations */
export type GQLMutationgbxUpgradeArgs = {
  dExperiment?: InputMaybe<GQLDExperiment>;
  wg?: InputMaybe<Scalars['Boolean']['input']>;
};

/** List of supported mutations */
export type GQLMutationgiftingExperienceArgs = {
  experienceName: Scalars['String']['input'];
};

/** List of supported mutations */
export type GQLMutationgwsManagementArgs = {
  gwsType: GQLGwsType;
};

/** List of supported mutations */
export type GQLMutationinterstitialArgs = {
  slug: Scalars['String']['input'];
};

/** List of supported mutations */
export type GQLMutationloginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** List of supported mutations */
export type GQLMutationpurchaseExtraCreditsArgs = {
  cadence: Scalars['Int']['input'];
  itemCount: Scalars['Int']['input'];
  paymentMethodId: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  program: GQLProgram;
};

/** List of supported mutations */
export type GQLMutationreactivateSubscriptionItemArgs = {
  packagePeriod?: InputMaybe<GQLPackagePeriod>;
  program?: InputMaybe<GQLProgram>;
};

/** List of supported mutations */
export type GQLMutationremoveFromCartArgs = {
  experienceName: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  program: GQLProgram;
};

/** List of supported mutations */
export type GQLMutationsignUpArgs = {
  input: GQLSignUpInput;
};

/** List of supported mutations */
export type GQLMutationskipExperienceArgs = {
  experienceName: Scalars['String']['input'];
  program?: InputMaybe<GQLProgram>;
};

/** List of supported mutations */
export type GQLMutationtriggerSubscriptionCancelationEmailArgs = {
  program: GQLProgram;
};

/** List of supported mutations */
export type GQLMutationupdateRefreshmentsSubItemArgs = {
  cadence?: InputMaybe<Scalars['Int']['input']>;
  nextDeliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionItemId: Scalars['String']['input'];
};

/** List of supported mutations */
export type GQLMutationupdateShippingAddressArgs = {
  createAddressInput?: InputMaybe<GQLCreateAddressInput>;
};

/** List of supported mutations */
export type GQLMutationupdateUserDetailsArgs = {
  input: GQLUserDetailsInput;
};

/** List of supported mutations */
export type GQLMutationupgradeToAnnualSubscriptionArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents current user's data */
export type GQLMyData = {
  /** Gets the experiment context item using the provided key */
  abResource?: Maybe<Scalars['String']['output']>;
  activeBannerForUnprioritizedExperienceGroup?: Maybe<GQLNotification>;
  /** Represents the information about the After Pay Price Config with Payment order limits */
  afterPayPriceConfig?: Maybe<GQLAfterPayPriceConfig>;
  alert?: Maybe<GQLAlert>;
  banner?: Maybe<GQLBanner>;
  /** Blog Articles */
  blogArticle?: Maybe<GQLBlogArticleV2>;
  blogCategories?: Maybe<GQLBlogCategoryList>;
  calloutAnnualUpgrade?: Maybe<Scalars['Boolean']['output']>;
  /** Used to identify the campaigns that user is active in. */
  campaigns: Array<GQLCampaign>;
  checkUserEligibilityForGws: GQLGwsEligibilityResponse;
  /** Commerce */
  commerce?: Maybe<GQLCommerce>;
  coupon?: Maybe<GQLCouponPageContent>;
  currentProgramCycle?: Maybe<GQLCycle>;
  details?: Maybe<GQLUserDetails>;
  featureFlag: Scalars['Boolean']['output'];
  generalCart?: Maybe<GQLCart>;
  /** Retrieve token from Payments service to initialize Paypal flow */
  getTokenBraintreePaymentMethod?: Maybe<GQLRetrieveTokenResponse>;
  gifting: GQLGifting;
  /** GB Choice Eligibility Checker */
  glambagChoiceEligibility: GQLGlambagChoiceEligibility;
  id?: Maybe<Scalars['ID']['output']>;
  interstitial?: Maybe<GQLInterstitialExperience>;
  isCurrentCycleRolling?: Maybe<Scalars['Boolean']['output']>;
  isEligibleForUESExperience?: Maybe<Scalars['Boolean']['output']>;
  isUserEligibleToSubscribe: GQLUserSubscriptionProgramEligibility;
  /** Gets the latest order tracking information for a user */
  latestTracking?: Maybe<GQLTrackingResponse>;
  layout: GQLLayoutContent;
  membershipCosts?: Maybe<GQLMembershipCosts>;
  orders?: Maybe<GQLMyOrders>;
  paymentMethods?: Maybe<Array<GQLPaymentMethod>>;
  /** Latest PayPal payment method */
  paypalPaymentMethod?: Maybe<GQLPayPal>;
  physicalAddresses?: Maybe<Array<Maybe<GQLPhysicalAddress>>>;
  previewExtraCreditsAnnual?: Maybe<GQLPreviewPurchaseResponse>;
  /** The notification a user should see e.g. a banner */
  prioritizedNotification?: Maybe<GQLNotification>;
  products?: Maybe<Array<Maybe<GQLProduct>>>;
  publicAbResources?: Maybe<Scalars['JSON']['output']>;
  quiz?: Maybe<GQLQuiz>;
  recommendedPaymentMethodList?: Maybe<GQLRecommendedPaymentMethodList>;
  relatedArticles?: Maybe<GQLBlogArticleList>;
  /** Whether the user is a part of Signal Intake Experiment */
  shouldShowSignalIntake?: Maybe<Scalars['Boolean']['output']>;
  socialAccountDetails?: Maybe<GQLSocialAccountDetails>;
  subscription?: Maybe<GQLUserSubscription>;
  subscriptionProgram: GQLSubscriptionProgram;
  subscriptions?: Maybe<Array<Maybe<GQLUserSubscription>>>;
  /** Tax information */
  taxInformation?: Maybe<GQLTaxInformation>;
};

/** Represents current user's data */
export type GQLMyDataabResourceArgs = {
  key: Scalars['String']['input'];
};

/** Represents current user's data */
export type GQLMyDataactiveBannerForUnprioritizedExperienceGroupArgs = {
  groupId: Scalars['String']['input'];
  program?: InputMaybe<GQLProgram>;
};

/** Represents current user's data */
export type GQLMyDataalertArgs = {
  id: Scalars['String']['input'];
};

/** Represents current user's data */
export type GQLMyDatabannerArgs = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  locationName: Scalars['String']['input'];
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

/** Represents current user's data */
export type GQLMyDatablogArticleArgs = {
  articleName?: InputMaybe<Scalars['String']['input']>;
};

/** Represents current user's data */
export type GQLMyDatacalloutAnnualUpgradeArgs = {
  program: GQLProgram;
};

/** Represents current user's data */
export type GQLMyDatacheckUserEligibilityForGwsArgs = {
  eligibilityRequest: GQLGwsRequest;
  gwsType: GQLGwsType;
};

/** Represents current user's data */
export type GQLMyDatacommerceArgs = {
  commerceExperienceType?: InputMaybe<GQLCommerceExperienceType>;
};

/** Represents current user's data */
export type GQLMyDatacouponArgs = {
  promotionId: Scalars['String']['input'];
};

/** Represents current user's data */
export type GQLMyDatacurrentProgramCycleArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents current user's data */
export type GQLMyDatafeatureFlagArgs = {
  key: Scalars['String']['input'];
};

/** Represents current user's data */
export type GQLMyDataglambagChoiceEligibilityArgs = {
  request: GQLGetAvailableItemsForChoiceRequest;
};

/** Represents current user's data */
export type GQLMyDatainterstitialArgs = {
  slug: Scalars['String']['input'];
};

/** Represents current user's data */
export type GQLMyDataisCurrentCycleRollingArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents current user's data */
export type GQLMyDataisEligibleForUESExperienceArgs = {
  experienceType: Scalars['String']['input'];
};

/** Represents current user's data */
export type GQLMyDataisUserEligibleToSubscribeArgs = {
  period?: InputMaybe<Scalars['Int']['input']>;
  program: GQLProgram;
};

/** Represents current user's data */
export type GQLMyDatamembershipCostsArgs = {
  cadence: Scalars['Int']['input'];
  period: Scalars['Int']['input'];
  program: GQLProgram;
};

/** Represents current user's data */
export type GQLMyDatapreviewExtraCreditsAnnualArgs = {
  program: GQLProgram;
};

/** Represents current user's data */
export type GQLMyDataprioritizedNotificationArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents current user's data */
export type GQLMyDataproductsArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Represents current user's data */
export type GQLMyDatapublicAbResourcesArgs = {
  keys: Array<Scalars['String']['input']>;
};

/** Represents current user's data */
export type GQLMyDatarelatedArticlesArgs = {
  articleName?: InputMaybe<Scalars['String']['input']>;
};

/** Represents current user's data */
export type GQLMyDatasubscriptionArgs = {
  program?: InputMaybe<GQLProgram>;
};

/** Represents current user's data */
export type GQLMyDatasubscriptionProgramArgs = {
  program: GQLProgram;
};

/** Represents current user's data */
export type GQLMyDatataxInformationArgs = {
  address?: InputMaybe<GQLPhysicalAddressInput>;
};

export type GQLMyOrders = {
  myOrders?: Maybe<GQLBfaOrdersResponse>;
};

export type GQLMyOrdersmyOrdersArgs = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<InputMaybe<GQLBfaOrderItemType>>>;
};

/** Represents navbar configuration */
export type GQLNavigation = {
  isWithIcons: Scalars['Boolean']['output'];
  /** @deprecated Use node on myData.subscriptionProgram. */
  isWithRefreshments: Scalars['Boolean']['output'];
};

/** Represents a notification */
export type GQLNotification = {
  name: Scalars['String']['output'];
};

/** Represents asset's open graph meta data */
export type GQLOgMetaData = {
  description: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  imageAlt: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width: Scalars['Int']['output'];
};

export type GQLOptions = {
  alternativeButtons?: Maybe<Array<Maybe<GQLAlternativeButtons>>>;
  pauseOptions?: Maybe<Array<Maybe<GQLPauseOptions>>>;
};

/** Represents an order */
export type GQLOrder = {
  coupon?: Maybe<GQLOrderCoupon>;
  createdDate: Scalars['DateTime']['output'];
  discount?: Maybe<GQLMoney>;
  discountedSubtotal?: Maybe<GQLMoney>;
  handlingFee?: Maybe<GQLMoney>;
  id?: Maybe<Scalars['ID']['output']>;
  lines: Array<GQLOrderLine>;
  paymentMethods?: Maybe<Array<Maybe<GQLPaymentMethod>>>;
  shipping?: Maybe<GQLMoney>;
  shippingAddress?: Maybe<GQLPhysicalAddress>;
  status: Scalars['String']['output'];
  subtotal?: Maybe<GQLMoney>;
  tax?: Maybe<GQLMoney>;
  total: GQLMoney;
  trackingInfos?: Maybe<Array<Maybe<GQLTrackingInfo>>>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

/** Represents coupon information */
export type GQLOrderCoupon = {
  code?: Maybe<Scalars['String']['output']>;
  discountMagnitude?: Maybe<Scalars['String']['output']>;
  orderSubtotalWithDiscount?: Maybe<GQLMoney>;
  orderSubtotalWithoutDiscount?: Maybe<GQLMoney>;
  promotionName?: Maybe<Scalars['String']['output']>;
  promotionType?: Maybe<GQLPromotionType>;
};

/** Represents an order line */
export type GQLOrderLine = {
  discountAmount?: Maybe<Scalars['Float']['output']>;
  /** Discounted unit price */
  discountedPrice?: Maybe<Scalars['Float']['output']>;
  /** Unit price */
  price?: Maybe<Scalars['Float']['output']>;
  product: GQLProduct;
  quantity: Scalars['Int']['output'];
  subTotal: GQLMoney;
  taxAmount: Scalars['Float']['output'];
};

export enum GQLPackagePeriod {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}

/** Represents PageInformation */
export type GQLPageInformation = {
  currentPage: Scalars['Int']['output'];
  currentRangeEnd: Scalars['Int']['output'];
  currentRangeStart: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  showTotalItems: Scalars['Boolean']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type GQLPasswordManagementMutation = {
  changePassword: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
};

export type GQLPasswordManagementMutationchangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type GQLPasswordManagementMutationresetPasswordArgs = {
  email: Scalars['String']['input'];
};

export type GQLPauseOption = {
  cmsIdentifier?: Maybe<Scalars['String']['output']>;
  csLabel?: Maybe<Scalars['String']['output']>;
  csLabelSuffix?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type GQLPauseOptions = {
  cmsIdentifier?: Maybe<Scalars['String']['output']>;
  handlesButton?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<GQLPauseOption>>>;
  watchedField?: Maybe<Scalars['String']['output']>;
};

/** Represent a PayPal payment method using a PayPal Billing Agreement or Braintree */
export type GQLPayPal = GQLPaymentMethod & {
  cardType?: Maybe<GQLCreditCardType>;
  description: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastFour?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a generic payment method */
export type GQLPaymentMethod = {
  cardType?: Maybe<GQLCreditCardType>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastFour?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents payment method input information */
export type GQLPaymentMethodInput = {
  /** Optional: Paypal Data */
  paypal?: InputMaybe<GQLPaypalPaymentMethodInput>;
  /** Optional: Stripe Data */
  stripe?: InputMaybe<GQLStripePaymentMethodInput>;
  type: GQLPaymentMethodType;
};

export type GQLPaymentMethodStripeResponse = {
  id?: Maybe<Scalars['String']['output']>;
};

/** Represents the different payment method types BFA supports. Not all providers support all types. */
export enum GQLPaymentMethodType {
  APPLEPAY = 'APPLEPAY',
  CARD = 'CARD',
  GIFTCARD = 'GIFTCARD',
  PAYPAL = 'PAYPAL',
}

export type GQLPaymentMethodWithBraintreeAccount = {
  braintreePaypalAccount: GQLBraintreeDataAccount;
  businessId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type GQLPaymentRetryResponse = {
  status?: Maybe<GQLStatusChargeMeNowResponse>;
};

export type GQLPaymentsManagementMutation = {
  /** It saves a payment method as a fallback payment method for a user or removes it depending on the consent. */
  fallbackPaymentMethod?: Maybe<GQLFallbackPaymentMethodResponse>;
  paymentRetry?: Maybe<GQLPaymentRetryResponse>;
  /** throw an error If the user is not eligible for charge now, it updates the payment method. */
  updatePaymentMethodAndChargeNow?: Maybe<GQLUpdatePaymentMethodChargeMeNowResponse>;
};

export type GQLPaymentsManagementMutationfallbackPaymentMethodArgs = {
  fallbackPaymentMethodInput: GQLFallbackPaymentMethodInput;
};

export type GQLPaymentsManagementMutationpaymentRetryArgs = {
  subId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLPaymentsManagementMutationupdatePaymentMethodAndChargeNowArgs = {
  updatePaymentMethodInput?: InputMaybe<GQLUpdatePaymentMethodChargeMeNowInput>;
};

export type GQLPaymentsMutation = {
  chargeMeNow?: Maybe<Scalars['String']['output']>;
  deletePaymentMethod?: Maybe<Scalars['String']['output']>;
  initiateStripePaymentMethodCreation?: Maybe<Scalars['String']['output']>;
  saveNewPaymentMethod?: Maybe<GQLPaymentMethod>;
  updatePaymentMethodForSubscriptions?: Maybe<
    Array<Maybe<GQLUpdateSubscriptionPaymentMethodResponse>>
  >;
};

export type GQLPaymentsMutationchargeMeNowArgs = {
  subId: Scalars['String']['input'];
};

export type GQLPaymentsMutationdeletePaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type GQLPaymentsMutationsaveNewPaymentMethodArgs = {
  billingAddressId: Scalars['String']['input'];
  paymentMethod: GQLPaymentMethodInput;
};

export type GQLPaymentsMutationupdatePaymentMethodForSubscriptionsArgs = {
  paymentMethodId: Scalars['String']['input'];
  subscriptionIds: Array<InputMaybe<Scalars['String']['input']>>;
};

/** Represent a paypal payment method */
export type GQLPaypal = GQLPaymentMethod & {
  /** @deprecated Should use PayPal type instead */
  cardType?: Maybe<GQLCreditCardType>;
  /** @deprecated Should use PayPal type instead */
  description: Scalars['String']['output'];
  /** @deprecated Should use PayPal type instead */
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated Should use PayPal type instead */
  id: Scalars['ID']['output'];
  /** @deprecated Should use PayPal type instead */
  lastFour?: Maybe<Scalars['String']['output']>;
  /** @deprecated Should use PayPal type instead */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents payment method input information for Paypal */
export type GQLPaypalPaymentMethodInput = {
  bfaPaymentMethodId: Scalars['String']['input'];
};

export enum GQLPersonalizedConfig {
  BB_MONTHLY = 'BB_MONTHLY',
  GBX_STANDARD = 'GBX_STANDARD',
  GB_ANNUAL = 'GB_ANNUAL',
  GB_EOM = 'GB_EOM',
  GB_MONTHLY = 'GB_MONTHLY',
  GB_PLUS_ANNUAL = 'GB_PLUS_ANNUAL',
  GB_PLUS_EOM = 'GB_PLUS_EOM',
  GB_PLUS_MONTHLY = 'GB_PLUS_MONTHLY',
}

export type GQLPersonalizedSubscriptionDetail = {
  fulfillmentCycleId?: InputMaybe<Scalars['String']['input']>;
  gifts?: InputMaybe<Array<GQLGiftDetail>>;
  personalizedConfigId: GQLPersonalizedConfig;
  status: GQLSubscriptionStatus;
  type: GQLSubscriptionDetailType;
};

/** Represents phone number */
export type GQLPhoneNumberInput = {
  countryCode: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  smsOptIn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLPhoneNumberResponse = {
  error: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

/** Represents user address information */
export type GQLPhysicalAddress = {
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  lines: Array<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<GQLPostalCode>;
  preferredName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Represents user address information */
export type GQLPhysicalAddressInput = {
  /** City name */
  city: Scalars['String']['input'];
  /** Country name */
  country: Scalars['String']['input'];
  /** First name */
  firstName: Scalars['String']['input'];
  /** Last name */
  lastName: Scalars['String']['input'];
  /** First line is mandatory */
  lines: Array<Scalars['String']['input']>;
  /** phone */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Postal code. Zip code for US */
  postalCode: GQLPostalCodeInput;
  /** State / province name */
  state: Scalars['String']['input'];
};

/** Represents international postal code information (A.K.A. zipcode) */
export type GQLPostalCode = {
  /** Main code */
  code?: Maybe<Scalars['String']['output']>;
  /** Used in USA to identify geographic segment within postal code. Often added after a "-". Eg: 94401-3914 */
  plus4?: Maybe<Scalars['String']['output']>;
  /** Where ZIP+4 takes you down to a few households, ZIP+6 takes you down to the actual delivery point */
  plus6?: Maybe<Scalars['String']['output']>;
};

/** Represents international postal code information (A.K.A. zipcode) */
export type GQLPostalCodeInput = {
  /** Main code */
  code: Scalars['String']['input'];
  /** Used in USA to identify geographic segment within postal code. Often added after a "-". Eg: 94401-3914 */
  plus4?: InputMaybe<Scalars['String']['input']>;
  /** Where ZIP+4 takes you down to a few households, ZIP+6 takes you down to the actual delivery point */
  plus6?: InputMaybe<Scalars['String']['input']>;
};

export type GQLPreviewPurchaseResponse = {
  amount?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  taxes?: Maybe<Scalars['Int']['output']>;
};

export type GQLPrices = {
  discountPercentage?: Maybe<Scalars['Int']['output']>;
  discountPrice?: Maybe<GQLMoney>;
  offerEndDate?: Maybe<Scalars['DateTime']['output']>;
  offerStartDate?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<GQLMoney>;
  regularPrice?: Maybe<GQLMoney>;
};

export enum GQLPricingActivityType {
  ADDONS = 'ADDONS',
  COMMERCE = 'COMMERCE',
  ONEOFF = 'ONEOFF',
  REFRESHMENTS = 'REFRESHMENTS',
}

/** Represents a product */
export type GQLProduct = {
  aboutTheBrand?: Maybe<Scalars['String']['output']>;
  addedBorderColor?: Maybe<Scalars['String']['output']>;
  attributesInformation?: Maybe<Array<Maybe<GQLAttributeInformation>>>;
  /** @deprecated Use isAvailable instead */
  available?: Maybe<Scalars['Boolean']['output']>;
  badges?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  brand: GQLBrand;
  brandShade?: Maybe<Scalars['String']['output']>;
  bundleBrands?: Maybe<Array<Maybe<GQLBrand>>>;
  canonicalId?: Maybe<Scalars['ID']['output']>;
  category: Scalars['String']['output'];
  color?: Maybe<Scalars['String']['output']>;
  coloredBackgroundImage?: Maybe<GQLSimpleImage>;
  countryShippingRestrictions?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  defaultCadenceInMonths?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  details?: Maybe<Scalars['String']['output']>;
  discountPercentage?: Maybe<Scalars['Int']['output']>;
  effectivePriceForActivity?: Maybe<GQLMoney>;
  frequencyAdvice?: Maybe<Scalars['String']['output']>;
  futureShipmentImage?: Maybe<GQLSimpleImage>;
  highlights?: Maybe<Scalars['String']['output']>;
  highlightsImage?: Maybe<GQLSimpleImage>;
  hotTips?: Maybe<Scalars['String']['output']>;
  howToUseIt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ingredients?: Maybe<Scalars['String']['output']>;
  inventory?: Maybe<Scalars['Int']['output']>;
  inventoryByChannel?: Maybe<Scalars['Int']['output']>;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  isAvailableByActivity?: Maybe<Scalars['Boolean']['output']>;
  isClean?: Maybe<Scalars['Boolean']['output']>;
  isCrueltyFree?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  isHandlingFee: Scalars['Boolean']['output'];
  isNew: Scalars['Boolean']['output'];
  /** @deprecated It's deprecated because it only checks that user hasn't reviewed the product. userCanReviewProductInCycle should be used instead */
  isReviewableByUser: Scalars['Boolean']['output'];
  isSubscriptionProduct?: Maybe<Scalars['Boolean']['output']>;
  isVegan?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  marketingBadge?: Maybe<GQLMarketingBadge>;
  marketingReview?: Maybe<Scalars['String']['output']>;
  marketingReviewCount?: Maybe<Scalars['Int']['output']>;
  marketingReviewStars?: Maybe<Scalars['Float']['output']>;
  maxAllowedQuantity?: Maybe<Scalars['Int']['output']>;
  migrationJson?: Maybe<GQLMigrationJson>;
  msrp: GQLMoney;
  name: Scalars['String']['output'];
  offerEndTime?: Maybe<Scalars['DateTime']['output']>;
  offerPrice?: Maybe<GQLMoney>;
  offerStartTime?: Maybe<Scalars['DateTime']['output']>;
  oneOffPrice?: Maybe<GQLMoney>;
  parentProductId?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<GQLMoney>;
  pricesForActivity?: Maybe<GQLPrices>;
  productImages: Array<Maybe<GQLSimpleImage>>;
  /** @deprecated Use Brand.promoCode instead */
  promoCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Brand.promoDiscount instead */
  promoDiscount?: Maybe<Scalars['String']['output']>;
  refillProducts: Array<Maybe<GQLProduct>>;
  regularPrice?: Maybe<GQLMoney>;
  regularPriceForActivity?: Maybe<GQLMoney>;
  relatedProducts: Array<Maybe<GQLProduct>>;
  reviews: Array<Maybe<GQLProductReview>>;
  /** Short text explaning more things to love. Used on Emails */
  sellingOptions?: Maybe<Array<Maybe<GQLProductSellingOption>>>;
  shade?: Maybe<GQLShade>;
  shippingRestrictions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Temporary value for product display until shopper is deprecated */
  shopperDisplayId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Temporary value until shopper is deprecated */
  shopperId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Retailer concept is deprecated. Fields should move to Brand */
  shopperRetailerData?: Maybe<GQLShopperRetailerData>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  sizeInfo?: Maybe<Scalars['String']['output']>;
  subscriptionType: GQLProductSubscriptionType;
  tierPrice?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userCanReviewProductInCycle: Scalars['Boolean']['output'];
  variants?: Maybe<Array<Maybe<GQLProductVariant>>>;
  whatItDoes?: Maybe<Scalars['String']['output']>;
  whyYoullLoveIt?: Maybe<Scalars['String']['output']>;
};

/** Represents a product */
export type GQLProductbundleBrandsArgs = {
  max?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents a product */
export type GQLProducteffectivePriceForActivityArgs = {
  type?: InputMaybe<GQLPricingActivityType>;
};

/** Represents a product */
export type GQLProductinventoryByChannelArgs = {
  channel: GQLChannelType;
};

/** Represents a product */
export type GQLProductisAvailableByActivityArgs = {
  type?: InputMaybe<GQLProductActivityType>;
};

/** Represents a product */
export type GQLProductmarketingBadgeArgs = {
  previewForDate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<GQLProductActivityType>;
};

/** Represents a product */
export type GQLProductpricesForActivityArgs = {
  previewForDate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<GQLProductActivityType>;
};

/** Represents a product */
export type GQLProductregularPriceForActivityArgs = {
  type?: InputMaybe<GQLPricingActivityType>;
};

/** Represents a product */
export type GQLProductsizeInfoArgs = {
  language?: InputMaybe<GQLLanguageIds>;
};

/** Represents a product */
export type GQLProductuserCanReviewProductInCycleArgs = {
  month: Scalars['String']['input'];
  program: GQLProgram;
  year: Scalars['String']['input'];
};

/** Represents a product */
export type GQLProductvariantsArgs = {
  previewForDate?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<GQLProductActivityType>;
};

export enum GQLProductActivityType {
  ADDONS = 'ADDONS',
  COMMERCE = 'COMMERCE',
  ONEOFF = 'ONEOFF',
  REFRESHMENTS = 'REFRESHMENTS',
}

export type GQLProductAvailability = {
  availability: Scalars['Boolean']['output'];
  hasInventory: Scalars['Boolean']['output'];
  itemId: Scalars['String']['output'];
  marketingBadge?: Maybe<GQLProductAvailabilityMarketingBadge>;
  price: GQLProductAvailabilityPrice;
};

export type GQLProductAvailabilityMarketingBadge = {
  addCountdown?: Maybe<Scalars['Boolean']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type GQLProductAvailabilityPrice = {
  activity?: Maybe<GQLCommerceExperienceType>;
  businessId?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  effective?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  itemId?: Maybe<Scalars['String']['output']>;
  nonMember?: Maybe<Scalars['Float']['output']>;
  regular?: Maybe<Scalars['Float']['output']>;
};

/** Represents the product error. Lets consumers show more detailed info about the error */
export type GQLProductErrorInfo = {
  id: Scalars['String']['output'];
  isGwp: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GQLProductFilter = {
  multiselect: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  options: Array<GQLProductFilterOption>;
  type: GQLProductFilterType;
};

export type GQLProductFilterInput = {
  type: GQLProductFilterType;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQLProductFilterOption = {
  count?: Maybe<Scalars['Int']['output']>;
  isSelected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum GQLProductFilterType {
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  COLLECTIONS_AND_PROMOTIONS = 'COLLECTIONS_AND_PROMOTIONS',
  PREFERENCE = 'PREFERENCE',
  PRODUCT_SIZE = 'PRODUCT_SIZE',
  SUBCATEGORY = 'SUBCATEGORY',
}

export type GQLProductRail = {
  categoryName?: Maybe<Scalars['String']['output']>;
  filterAttributeId?: Maybe<Scalars['String']['output']>;
  filterType?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<GQLProduct>>>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents a product review done by an user */
export type GQLProductReview = {
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Between 0 and 5 */
  rating: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  user: GQLUserSummary;
};

/** Represents the content used in product review page */
export type GQLProductReviewContent = {
  shareWithMessenger: Scalars['Boolean']['output'];
};

export type GQLProductReviewQuizMutation = {
  /** Answers a quiz */
  answer?: Maybe<GQLProductReviewResponse>;
};

export type GQLProductReviewQuizMutationanswerArgs = {
  answers?: InputMaybe<GQLProductReviewQuizResponse>;
};

export type GQLProductReviewQuizResponse = {
  canonicalProductId?: InputMaybe<Scalars['String']['input']>;
  cycleId: Scalars['String']['input'];
  isBagReview: Scalars['Boolean']['input'];
  month?: InputMaybe<Scalars['String']['input']>;
  preferences: Array<GQLQuizPreference>;
  productId: Scalars['String']['input'];
  program: GQLProgram;
  quizId: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type GQLProductReviewResponse = {
  error: Scalars['Boolean']['output'];
  errorContent?: Maybe<Scalars['String']['output']>;
  errorTitle?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
};

export type GQLProductSearchInput = {
  filters?: InputMaybe<Array<GQLProductFilterInput>>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

/** Different forms to sell a product */
export enum GQLProductSellingOption {
  ONEOFF = 'ONEOFF',
  SUBSCRIBE = 'SUBSCRIBE',
}

/** Different forms to subscribe to the product */
export enum GQLProductSubscriptionType {
  BUNDLE = 'BUNDLE',
  KIT = 'KIT',
  STANDALONE = 'STANDALONE',
}

/** Represents a product variant */
export type GQLProductVariant = {
  configurableOptions?: Maybe<Array<Maybe<GQLConfigurableOption>>>;
  id: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  product?: Maybe<GQLProduct>;
};

/** States the different ipsy subscription programs */
export enum GQLProgram {
  BB = 'BB',
  CREATOR_SEED = 'CREATOR_SEED',
  FULLSIZE = 'FULLSIZE',
  GBX = 'GBX',
  GLAMBAG = 'GLAMBAG',
  REFRESHMENTS = 'REFRESHMENTS',
  ULTIMATE = 'ULTIMATE',
}

export type GQLProgramDisclaimer = {
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  image?: Maybe<GQLSimpleImage>;
  removeFromCart?: Maybe<Scalars['String']['output']>;
  seeMore?: Maybe<Scalars['String']['output']>;
  subHeader?: Maybe<Scalars['String']['output']>;
};

export enum GQLProgramModuleBagType {
  DEFAULT = 'DEFAULT',
  WELCOME_BAG = 'WELCOME_BAG',
}

/** Represents a type of discount */
export enum GQLPromotionType {
  FREE_SHIPPING = 'FREE_SHIPPING',
  ITEM_DISCOUNT_ABSOLUTE = 'ITEM_DISCOUNT_ABSOLUTE',
  ITEM_DISCOUNT_PERCENTAGE = 'ITEM_DISCOUNT_PERCENTAGE',
  ORDER_DISCOUNT_ABSOLUTE = 'ORDER_DISCOUNT_ABSOLUTE',
  ORDER_DISCOUNT_PERCENTAGE = 'ORDER_DISCOUNT_PERCENTAGE',
}

export type GQLPurchaseExtraCreditsResponse = {
  success: Scalars['Boolean']['output'];
  v1LifeCycleId?: Maybe<Scalars['String']['output']>;
  v1PaymentOrderId?: Maybe<Scalars['String']['output']>;
  v1SubscriptionId?: Maybe<Scalars['String']['output']>;
};

/** Represents the top level query type */
export type GQLQuery = {
  beautyQuizUserAnswers: Array<GQLBeautyQuizUserAnswer>;
  currentCycleBags?: Maybe<Array<GQLCycleBag>>;
  me: GQLMyData;
  product?: Maybe<GQLProduct>;
};

/** Represents the top level query type */
export type GQLQuerycurrentCycleBagsArgs = {
  program: GQLProgram;
};

/** Represents the top level query type */
export type GQLQueryproductArgs = {
  productId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a Quiz */
export type GQLQuiz = {
  /** Content of the quiz */
  content?: Maybe<GQLQuizContent>;
  /** Quiz abstract identifier */
  id: Scalars['ID']['output'];
};

/** Represents an answer for a question inside beaty quiz */
export type GQLQuizAnswer = {
  /** Optional field for the user input */
  answerInput?: Maybe<Scalars['String']['output']>;
  assets?: Maybe<GQLQuizAnswerAsset>;
  /** Free format configuration */
  displayAttributes?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  /** Optional image for the question */
  image?: Maybe<GQLSimpleImage>;
  /** content of the answer */
  title: Scalars['String']['output'];
};

/** Represents the assets for an answer */
export type GQLQuizAnswerAsset = {
  active?: Maybe<Scalars['JSON']['output']>;
  inactive?: Maybe<Scalars['JSON']['output']>;
  transition?: Maybe<Scalars['JSON']['output']>;
};

export enum GQLQuizCompletionStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export type GQLQuizContent = GQLQuizElementGroup | GQLQuizQuestion;

/** Group of elements of a quiz */
export type GQLQuizElementGroup = {
  /** This allows nesting of question */
  children: Array<GQLQuizContent>;
  id: Scalars['ID']['output'];
  /** I can be used as a title for a flow or a header in a group */
  title: Scalars['String']['output'];
};

export type GQLQuizMutation = {
  /** Answers a quiz */
  answer: Scalars['Boolean']['output'];
  signupAnswer: GQLQuizSignupResponse;
};

export type GQLQuizMutationanswerArgs = {
  answers?: InputMaybe<GQLQuizResponse>;
};

export type GQLQuizMutationsignupAnswerArgs = {
  signupInfo?: InputMaybe<GQLSignupQuizRequest>;
};

/** The preferences of a user for a given question */
export type GQLQuizPreference = {
  /** Optional: provide a hint what type of answer the preference might contain for data mapping purpose */
  answerType?: InputMaybe<Scalars['String']['input']>;
  /** An array of answers, in many cases it may be a single item */
  answers: Array<GQLQuizUserAnswer>;
  /** The id of the question */
  questionId: Scalars['ID']['input'];
};

/** A question that the user could answer */
export type GQLQuizQuestion = {
  /** List of anwsers posible for this question */
  answers: Array<GQLQuizAnswer>;
  /** Free format configuration */
  displayAttributes?: Maybe<Scalars['JSON']['output']>;
  /** Id of the answer if a user has already submitted one */
  existingAnswers?: Maybe<Array<GQLQuizAnswer>>;
  id: Scalars['ID']['output'];
  /** Optional image for the Question */
  image?: Maybe<GQLSimpleImage>;
  /** If this question allow multiple answers */
  isMultipleSelect: Scalars['Boolean']['output'];
  /** If question requires an answered */
  isRequired: Scalars['Boolean']['output'];
  /** The actual question */
  title: Scalars['String']['output'];
};

export type GQLQuizResponse = {
  /** Optional: completion status for Boxy/Magento quiz data */
  completionStatus?: InputMaybe<GQLQuizCompletionStatus>;
  preferences: Array<GQLQuizPreference>;
  quizId: Scalars['String']['input'];
  user?: InputMaybe<GQLUserInputQuizRetake>;
};

export type GQLQuizSignupResponse = {
  redirectUrl?: Maybe<Scalars['String']['output']>;
  result: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type GQLQuizUserAnswer = {
  /** The id of the anwser */
  answerId: Scalars['String']['input'];
  /** Optional: only used for free text questions */
  answerInput?: InputMaybe<Scalars['String']['input']>;
};

/** List of recommended payment methods */
export type GQLRecommendedPaymentMethodList = {
  paymentMethods?: Maybe<Array<Maybe<GQLPaymentMethod>>>;
};

export type GQLRedeemSubGiftCardResponse = {
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

/** Represents the content extracted from social media sites */
export type GQLRefreshmentsSocialMediaContent = {
  instagram: GQLInstagramSocialMediaContent;
};

/** Represents a responsive image */
export type GQLResponsiveImage = {
  desktop?: Maybe<GQLSimpleImage>;
  mobile: GQLSimpleImage;
};

/** Represents content/assets for a generic responsive text */
export type GQLResponsiveText = {
  desktop?: Maybe<Scalars['String']['output']>;
  mobile: Scalars['String']['output'];
};

export type GQLRetrieveTokenResponse = {
  id?: Maybe<Scalars['String']['output']>;
};

export type GQLRewardsPointsEvent = {
  cycleId: Scalars['String']['input'];
  month: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  program: GQLProgram;
  type: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export type GQLRewardsPointsMutation = {
  rewardsPointsTransaction?: Maybe<GQLRewardsPointsResponse>;
};

export type GQLRewardsPointsMutationrewardsPointsTransactionArgs = {
  event?: InputMaybe<GQLRewardsPointsEvent>;
};

export type GQLRewardsPointsResponse = {
  error: Scalars['Boolean']['output'];
};

/** Represents the shade of a product */
export type GQLShade = {
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Represents a shipment record */
export type GQLShipment = {
  /** Add-Ons purchase Order */
  addOnsOrder?: Maybe<GQLOrder>;
  bundles?: Maybe<Array<Maybe<GQLBundle>>>;
  /** The price the user paid for all the products in the shipment */
  subtotal: GQLMoney;
  /** The taxes the user paid for all the products in the shipment */
  taxes: GQLMoney;
  /** The total price (including taxes) the user paid for all the products in the shipment */
  total: GQLMoney;
  /** The external url of the tracking tool for this shipment */
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

/** Represents retailer data from shopper API */
export type GQLShopperRetailerData = {
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type GQLSignUpInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<GQLSignUpPhoneInput>;
};

export type GQLSignUpPhoneInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  smsOptIn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLSignupQuizPreference = {
  answerId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
};

export type GQLSignupQuizRequest = {
  fbInfo?: InputMaybe<GQLFacebookInfoInput>;
  newAnswers: Array<InputMaybe<GQLSignupQuizPreference>>;
  packageTypes?: InputMaybe<Scalars['String']['input']>;
  quizId: Scalars['String']['input'];
  user: GQLUserSignupInput;
};

/** Represents a website banner with structured content */
export type GQLSimpleBanner = GQLBanner & {
  kind: GQLSimpleBannerKind;
  /** An hyperlink that will redirect the user when pressing the banner */
  link?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** A text shown inside the banner */
  text: Scalars['String']['output'];
};

/** States the different kind of banners */
export enum GQLSimpleBannerKind {
  ERROR = 'ERROR',
  INFO = 'INFO',
}

/** Represents a sized image source */
export type GQLSimpleImage = {
  alt: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  src: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type GQLSkinnyBanner = {
  bannerCta?: Maybe<Scalars['String']['output']>;
  bannerMessage: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  modalCta: Scalars['String']['output'];
  modalDetails: Scalars['String']['output'];
  modalHeader: Scalars['String']['output'];
};

export type GQLSocialAccount = {
  accessToken: Scalars['String']['output'];
  accountId: Scalars['String']['output'];
  accountInfo?: Maybe<Scalars['JSON']['output']>;
  expiresIn?: Maybe<Scalars['Int']['output']>;
  isConnected: Scalars['Boolean']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type GQLSocialAccountDetails = {
  facebook?: Maybe<GQLSocialAccount>;
  google?: Maybe<GQLSocialAccount>;
  twitter?: Maybe<GQLSocialAccount>;
};

export type GQLSocialAccountInput = {
  accessToken: Scalars['String']['input'];
  /** the user id as provided by the given social platform */
  accountId: Scalars['String']['input'];
  accountInfo?: InputMaybe<Scalars['JSON']['input']>;
  accountType: Scalars['String']['input'];
  expiresIn?: InputMaybe<Scalars['Int']['input']>;
  isConnected: Scalars['Boolean']['input'];
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type GQLSocialAccountManagementMutation = {
  addSocialAccount: GQLSocialAccount;
  deleteSocialAccount?: Maybe<GQLSocialAccount>;
  updateSocialAccount: GQLSocialAccount;
};

export type GQLSocialAccountManagementMutationaddSocialAccountArgs = {
  socialAccountInput?: InputMaybe<GQLSocialAccountInput>;
};

export type GQLSocialAccountManagementMutationdeleteSocialAccountArgs = {
  accountType: Scalars['String']['input'];
};

export type GQLSocialAccountManagementMutationupdateSocialAccountArgs = {
  socialAccountInput?: InputMaybe<GQLSocialAccountInput>;
};

export enum GQLStatusChargeMeNowResponse {
  CHARGED = 'CHARGED',
  NOT_CHARGED = 'NOT_CHARGED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
}

/** Represents payment method input information for Stripe */
export type GQLStripePaymentMethodInput = {
  id: Scalars['String']['input'];
};

export type GQLSubscribeCheckoutPage = {
  allowedPaymentMethods?: Maybe<GQLAllowedPaymentMethods>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  freeBag?: Maybe<GQLFreeBagContent>;
  giftCard?: Maybe<GQLGiftCardContent>;
  giftWithSub?: Maybe<GQLGiftWithSubContent>;
  heroImage?: Maybe<GQLResponsiveImage>;
  iconBox?: Maybe<GQLIconBoxContent>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GQLSubscribeCheckoutPageRedirect = {
  name?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type GQLSubscribeCheckoutPageResult =
  | GQLSubscribeCheckoutPage
  | GQLSubscribeCheckoutPageRedirect;

/** Represents a subscription cycle and its available options */
export type GQLSubscriptionCycle = {
  assortment: Array<GQLProduct>;
  id?: Maybe<Scalars['String']['output']>;
};

export enum GQLSubscriptionDetailType {
  COMMERCE = 'COMMERCE',
  PERSONALIZED = 'PERSONALIZED',
}

export enum GQLSubscriptionFlow {
  ADDONS = 'ADDONS',
  UP = 'UP',
}

export type GQLSubscriptionItem = {
  addedBy?: Maybe<GQLSubscriptionItemOperationSource>;
  cadence?: Maybe<Scalars['Int']['output']>;
  correlationId?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  credits?: Maybe<Scalars['Int']['output']>;
  expirationPolicy?: Maybe<GQLSubscriptionItemExpirationPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<GQLMoney>;
  product?: Maybe<GQLProduct>;
  productId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<GQLSubscriptionItemStatus>;
  tentativeNextDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
};

export enum GQLSubscriptionItemExpirationPolicy {
  AFTER_FIRST_CYCLE = 'AFTER_FIRST_CYCLE',
  AFTER_FIRST_FULFILLMENT = 'AFTER_FIRST_FULFILLMENT',
  AFTER_FIRST_SHIPMENT = 'AFTER_FIRST_SHIPMENT',
}

export enum GQLSubscriptionItemOperationSource {
  OTHER = 'OTHER',
  SITE = 'SITE',
  USER = 'USER',
}

export enum GQLSubscriptionItemStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  CANCELLATION_ERROR = 'CANCELLATION_ERROR',
  CANCELLATION_IN_PROGRESS = 'CANCELLATION_IN_PROGRESS',
  DO_NOT_RENEW = 'DO_NOT_RENEW',
  PAUSED = 'PAUSED',
  WAITLIST = 'WAITLIST',
}

export type GQLSubscriptionManagement = {
  adjustFrequency?: Maybe<GQLSubscriptionManagementResponse>;
  cancelSubscription?: Maybe<GQLSubscriptionManagementResponse>;
  changePlan?: Maybe<GQLSubscriptionManagementResponse>;
  pauseSubscription?: Maybe<GQLSubscriptionManagementResponse>;
};

export type GQLSubscriptionManagementadjustFrequencyArgs = {
  cadence: Scalars['Int']['input'];
  credits: Scalars['Int']['input'];
  program: GQLProgram;
};

export type GQLSubscriptionManagementcancelSubscriptionArgs = {
  program: GQLProgram;
};

export type GQLSubscriptionManagementchangePlanArgs = {
  flow: GQLChangePlanFlow;
};

export type GQLSubscriptionManagementpauseSubscriptionArgs = {
  periods: Scalars['Int']['input'];
  program: GQLProgram;
};

export type GQLSubscriptionManagementException = {
  message: Scalars['String']['output'];
  type?: Maybe<GQLSubscriptionManagementExceptionType>;
};

export enum GQLSubscriptionManagementExceptionType {
  INVALID_INPUT = 'INVALID_INPUT',
  INVALID_SUBSCRIPTION = 'INVALID_SUBSCRIPTION',
  INVALID_USER = 'INVALID_USER',
  OPERATION_NOT_ALLOWED = 'OPERATION_NOT_ALLOWED',
  SUBSCRIPTION_NOT_FOUND = 'SUBSCRIPTION_NOT_FOUND',
  TRANSACTION_ERROR = 'TRANSACTION_ERROR',
}

export type GQLSubscriptionManagementResponse = {
  affectedSubscriptions: Array<Maybe<GQLUpdatedSubscription>>;
  error?: Maybe<GQLSubscriptionManagementException>;
  targetSubscription?: Maybe<GQLUpdatedSubscription>;
};

/** Represents a subscription program and its contents */
export type GQLSubscriptionProgram = {
  activeRelease?: Maybe<Scalars['String']['output']>;
  id: GQLProgram;
  product?: Maybe<GQLProduct>;
  products: Array<GQLProduct>;
  socialMedia?: Maybe<GQLRefreshmentsSocialMediaContent>;
  starterkitSlug?: Maybe<Scalars['String']['output']>;
  subscriptionCycle?: Maybe<GQLSubscriptionCycle>;
  /** isUserAbleToSubscribe: Boolean! */
  userCanSubscribe?: Maybe<Scalars['Boolean']['output']>;
  /** isWithRefreshments: Boolean! */
  userCanView?: Maybe<Scalars['Boolean']['output']>;
};

/** Represents a subscription program and its contents */
export type GQLSubscriptionProgramproductArgs = {
  productId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a subscription program and its contents */
export type GQLSubscriptionProgramsubscriptionCycleArgs = {
  month: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export enum GQLSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  CANCELLATION_ERROR = 'CANCELLATION_ERROR',
  CANCELLATION_IN_PROGRESS = 'CANCELLATION_IN_PROGRESS',
  DO_NOT_RENEW = 'DO_NOT_RENEW',
  PAUSED = 'PAUSED',
  WAITLIST = 'WAITLIST',
}

export type GQLSubscriptionUpsaleExperience = {
  addToCartActiveText?: Maybe<Scalars['String']['output']>;
  addToCartInactiveText?: Maybe<Scalars['String']['output']>;
  bannerText?: Maybe<Scalars['String']['output']>;
  buttonCTA?: Maybe<Scalars['String']['output']>;
  cmsIdentifier: Scalars['String']['output'];
  explanation?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  landingExplanatory?: Maybe<Scalars['String']['output']>;
  noThanksCTA?: Maybe<Scalars['String']['output']>;
  nullSelectionPopupButton?: Maybe<Scalars['String']['output']>;
  nullSelectionPopupDescription?: Maybe<Scalars['String']['output']>;
  nullSelectionPopupTitle?: Maybe<Scalars['String']['output']>;
  reviewSubscriptionExplanation?: Maybe<Scalars['String']['output']>;
  reviewSubscriptionHeader?: Maybe<Scalars['String']['output']>;
  showCornerAddToCart?: Maybe<Scalars['Boolean']['output']>;
  subscriptionsHeader?: Maybe<Scalars['String']['output']>;
  subscriptionsToOffer?: Maybe<Array<Maybe<GQLUpsaleExperienceProgram>>>;
};

export type GQLTaxInformation = {
  rate: Scalars['Float']['output'];
  taxables: GQLTaxableItemCategories;
};

export type GQLTaxableItemCategories = {
  goodsAndServices: Scalars['Boolean']['output'];
  handling: Scalars['Boolean']['output'];
  shipping: Scalars['Boolean']['output'];
};

export type GQLTimeline = {
  current: Scalars['String']['output'];
  events: Array<Maybe<GQLTimelineEvent>>;
};

export type GQLTimelineEvent = {
  cta?: Maybe<Scalars['String']['output']>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  displayDate?: Maybe<Scalars['String']['output']>;
  displayType?: Maybe<Scalars['String']['output']>;
  image?: Maybe<GQLSimpleImage>;
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  smsAsPrimaryCta?: Maybe<Scalars['Boolean']['output']>;
  smsCtaEvent?: Maybe<Scalars['String']['output']>;
  smsCtaLabel?: Maybe<Scalars['String']['output']>;
  smsCtaUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** Represents tracking information */
export type GQLTrackingInfo = {
  carrierId?: Maybe<Scalars['String']['output']>;
  kittingOrderId?: Maybe<Scalars['String']['output']>;
  shippingMethodId?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
};

/** Represents the tracking information */
export type GQLTrackingResponse = {
  carrier?: Maybe<Scalars['String']['output']>;
  productIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
};

export type GQLUpdatePaymentMethodChargeMeNowInput = {
  paymentMethodId: Scalars['String']['input'];
  subId: Scalars['String']['input'];
};

export type GQLUpdatePaymentMethodChargeMeNowResponse = {
  charged?: Maybe<GQLChargeMeNowResponse>;
  updated: Scalars['Boolean']['output'];
};

export type GQLUpdateSubscriptionPaymentMethodResponse = {
  cycleSequenceId?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  hasSuccessfulPayment?: Maybe<Scalars['Boolean']['output']>;
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  paymentMethod?: Maybe<GQLPaymentMethod>;
  program?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<GQLPhysicalAddress>;
  status?: Maybe<GQLSubscriptionStatus>;
  subscriptionId?: Maybe<Scalars['ID']['output']>;
};

export type GQLUpdatedSubscription = {
  cadence?: Maybe<Scalars['Int']['output']>;
  childSubIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  creditsValue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  parentSubId?: Maybe<Scalars['String']['output']>;
  program: GQLProgram;
  status: GQLSubscriptionStatus;
};

export type GQLUpsaleExperienceProgram = {
  bulletedList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  disclaimer?: Maybe<GQLProgramDisclaimer>;
  heroImageDesktop?: Maybe<GQLSimpleImage>;
  heroImageMobile?: Maybe<GQLSimpleImage>;
  program: GQLProgram;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents a user cycle and its available experiences */
export type GQLUserCycle = {
  assortment?: Maybe<GQLCycleAssortment>;
  bag?: Maybe<GQLBag>;
  chainedReviewExperience?: Maybe<GQLChainedReviewExperience>;
  commerceExperience?: Maybe<GQLCommerceExperience>;
  customizationExperience?: Maybe<GQLCustomizationExperience>;
  cycleId: Scalars['String']['output'];
  glamBagId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isRewardableBySharing: Scalars['Boolean']['output'];
  /** @deprecated use pastCycles on UserSubscription */
  lastLifeCycle?: Maybe<GQLLifecycle>;
  lastPaymentOrderId?: Maybe<Scalars['String']['output']>;
  migrationJson?: Maybe<GQLMigrationJson>;
  paymentOrder?: Maybe<GQLOrder>;
  shipment?: Maybe<GQLShipment>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  statusTime?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a user cycle and its available experiences */
export type GQLUserCyclecommerceExperienceArgs = {
  experienceName: GQLCommerceExperienceName;
};

/** Represents a user cycle and its available experiences */
export type GQLUserCyclecustomizationExperienceArgs = {
  experienceName: Scalars['String']['input'];
};

/** Represents a user cycle and its available experiences */
export type GQLUserCycleshipmentArgs = {
  includeHandlingFee?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents user information */
export type GQLUserDetails = {
  age?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inPaymentFailure: Scalars['Boolean']['output'];
  isFreeBagReferee?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  mobilePhoneCountryCode?: Maybe<Scalars['String']['output']>;
  passwordExpired?: Maybe<Scalars['Boolean']['output']>;
  referId?: Maybe<Scalars['String']['output']>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type GQLUserDetailsInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  phoneNumber?: InputMaybe<GQLPhoneNumberInput>;
  username: Scalars['String']['input'];
};

/** Represents a user experience that forms part of a user flow */
export type GQLUserExperience = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nextExperience?: Maybe<Scalars['String']['output']>;
};

export type GQLUserInputQuizRetake = {
  age: Scalars['String']['input'];
  email: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Represents mutations related to the users phone */
export type GQLUserPhoneNumberMutation = {
  addPhoneNumber?: Maybe<GQLPhoneNumberResponse>;
};

/** Represents mutations related to the users phone */
export type GQLUserPhoneNumberMutationaddPhoneNumberArgs = {
  phoneNumber: GQLPhoneNumberInput;
};

export type GQLUserSignupInput = {
  age: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsOptIn?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents a member subscription */
export type GQLUserSubscription = {
  alsoInYourBag?: Maybe<Array<GQLProduct>>;
  boostedBy?: Maybe<GQLUserSubscription>;
  cadence?: Maybe<Scalars['Int']['output']>;
  canPauseSubscription?: Maybe<Scalars['Boolean']['output']>;
  canResumeSubscription?: Maybe<Scalars['Boolean']['output']>;
  cancellationFlow?: Maybe<GQLCancellationFlow>;
  currentCycle?: Maybe<GQLUserCycle>;
  cycle?: Maybe<GQLUserCycle>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  extraCycleItems: Array<GQLExtraCycleItem>;
  id?: Maybe<Scalars['ID']['output']>;
  isChargeMeNowEligible?: Maybe<Scalars['Boolean']['output']>;
  isEomAvailable?: Maybe<Scalars['Boolean']['output']>;
  isMonthlyAvailable?: Maybe<Scalars['Boolean']['output']>;
  lastLifeCyclePaymentStatus?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextBillingDate?: Maybe<Scalars['String']['output']>;
  nextMonthEstimatedTaxes?: Maybe<Scalars['Float']['output']>;
  nextShipmentDate?: Maybe<Scalars['String']['output']>;
  pastCycles: Array<GQLUserCycle>;
  paymentFailureIndicator?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<GQLPaymentMethod>;
  paymentRetryEnabled?: Maybe<Scalars['Boolean']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  program?: Maybe<GQLProgram>;
  shippingAddress?: Maybe<GQLPhysicalAddress>;
  /** @deprecated Use starterKitOrder instead */
  starterKit?: Maybe<GQLProduct>;
  starterKitOrder?: Maybe<GQLOrder>;
  status?: Maybe<GQLSubscriptionStatus>;
  subscriptionItems: Array<GQLSubscriptionItem>;
  timeline?: Maybe<GQLTimeline>;
};

/** Represents a member subscription */
export type GQLUserSubscriptionalsoInYourBagArgs = {
  productId: Scalars['String']['input'];
};

/** Represents a member subscription */
export type GQLUserSubscriptioncycleArgs = {
  month?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a member subscription */
export type GQLUserSubscriptionpastCyclesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum GQLUserSubscriptionLifecycleStatus {
  CANCELED = 'CANCELED',
  CANCELLATION_ERROR = 'CANCELLATION_ERROR',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  DELIVERED = 'DELIVERED',
  DELIVERY_ERROR = 'DELIVERY_ERROR',
  FULFILLED = 'FULFILLED',
  FULFILLMENT_CREDIT_DECREMENT_ERROR = 'FULFILLMENT_CREDIT_DECREMENT_ERROR',
  FULFILLMENT_ERROR = 'FULFILLMENT_ERROR',
  FULFILLMENT_FAILED = 'FULFILLMENT_FAILED',
  FULFILLMENT_IN_PROGRESS = 'FULFILLMENT_IN_PROGRESS',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_FAILED_FINAL = 'PAYMENT_FAILED_FINAL',
  PAYMENT_RETRY_IN_PROGRESS = 'PAYMENT_RETRY_IN_PROGRESS',
  PERSONALIZATION_ERROR = 'PERSONALIZATION_ERROR',
  PERSONALIZATION_IN_PROGRESS = 'PERSONALIZATION_IN_PROGRESS',
  READY_TO_FULFILL = 'READY_TO_FULFILL',
  SHIPPED = 'SHIPPED',
  SHIPPING_ERROR = 'SHIPPING_ERROR',
}

export type GQLUserSubscriptionManagement = {
  assignBag?: Maybe<GQLAssignBagToUserSubscriptionLifecycleResponse>;
  cancelSubscription?: Maybe<GQLCancelSubscriptionResponse>;
  createSubscription?: Maybe<GQLCreateUserSubscriptionResponse>;
};

export type GQLUserSubscriptionManagementassignBagArgs = {
  request: GQLAssignBagToUserSubscriptionLifecycleRequest;
};

export type GQLUserSubscriptionManagementcancelSubscriptionArgs = {
  request: GQLCancelSubscriptionRequest;
};

export type GQLUserSubscriptionManagementcreateSubscriptionArgs = {
  request: GQLCreateUserSubscriptionRequest;
};

export type GQLUserSubscriptionProgramEligibility = {
  alternativeEligibleProgram?: Maybe<GQLProgram>;
  isEligible: Scalars['Boolean']['output'];
};

export type GQLUserSubscriptionSummary = {
  cadence?: Maybe<Scalars['Int']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  period: Scalars['Int']['output'];
  program: GQLProgram;
  status: GQLSubscriptionStatus;
};

/** Represents a summary of a user's account information */
export type GQLUserSummary = {
  avatar?: Maybe<GQLSimpleImage>;
  id: Scalars['ID']['output'];
  username: Scalars['String']['output'];
};

/** Represents content/assets for why choose section */
export type GQLWhyChoose = {
  images: Array<GQLSimpleImage>;
};
