import { Config } from '@bfa/next-app-config';
import { clientLogging, cookieService } from '@bfa/nextjs-common/services';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import CookieName from '../utils/cookies/cookieName';

export type BusinessId = 'ipsymx' | 'ipsy';

const BusinessIdContext = createContext<BusinessId>('ipsy');

export const useBusinessId = (): BusinessId => useContext(BusinessIdContext);

// Header business id is set by EGW and sent to spa-nextjs when rendering server side.
// When we trigger a client side navigation getInitialProps is retriggered on _app.tsx
// Because this happens on the browser, EGW does not intersede,
// and initialBusinessId is null.
// We persist the header business id in memory with useState for this reason.
export const BusinessIdProvider: FC<
  PropsWithChildren<{ initialBusinessId: BusinessId | null }>
> = ({ children, initialBusinessId }) => {
  const [stateBusinessId] = useState<BusinessId | null>(initialBusinessId);

  const cookieBusinessId = cookieService.getCookie(
    null,
    CookieName.BUSINESS_ID
  ) as BusinessId | undefined;

  // For local development, we can set the business id in the .env file.
  const envBusinessId = Config.public.businessId as BusinessId | '';

  let businessId = cookieBusinessId || stateBusinessId || envBusinessId;
  if (!businessId) {
    clientLogging.logError(
      Error('Business id not set, defaulting to business id ipsy')
    );
    businessId = 'ipsy';
  }

  return (
    <BusinessIdContext.Provider value={businessId}>
      {children}
    </BusinessIdContext.Provider>
  );
};
