import { UserSubscriptionsProperties } from '@bfa/amplitude-events/dist/types';

import { GQLProgram, GQLUserSubscription, Maybe } from '../../gql.generated';

const INVALID_PROGRAMS = [GQLProgram.CREATOR_SEED];

export const filterOutInvalidPrograms = (
  subscriptions: Maybe<GQLUserSubscription>[]
): GQLUserSubscription[] => {
  const presentSubscriptions = subscriptions.filter(
    (it): it is GQLUserSubscription => it !== null
  );

  return presentSubscriptions.filter(
    (it) => !it.program || !INVALID_PROGRAMS.includes(it.program)
  );
};

export const createSubObjForAmplitude = (
  subscriptions: GQLUserSubscription[]
) => {
  return subscriptions.reduce((acc, subscription) => {
    const program = subscription.program;

    if (program) {
      // Determine the purchase cadence based on the name
      const name = subscription.name;
      let purchaseCadence = '';
      if (name?.includes('Period 12')) {
        purchaseCadence = 'ANNUAL';
      } else if (name?.includes('Period 1')) {
        purchaseCadence = 'MONTHLY';
      }

      acc[program] = {
        status: subscription.status || '',
        paymentStatus: subscription.lastLifeCyclePaymentStatus || '',
        purchaseCadence,
      };
    }

    return acc;
  }, {} as UserSubscriptionsProperties);
};
