import createApolloClient from 'apolloClient';

import { AdobeExpCookieProps, AdobeExpDetailProps } from '../../../utils/types';
import PUBLISH_EXPERIMENT_EVENT_MUTATION from '../../mutations/PUBLISH_EXPERIMENT_EVENT_MUTATION';

//verify and publish adobe target experiment event if necessary
const usePublishExperimentEvent = (
  adobeExpDetailProps: AdobeExpDetailProps[],
  pastCohortAtValue: AdobeExpCookieProps
) => {
  const apolloClient = createApolloClient({});
  adobeExpDetailProps.forEach((adobeExpDetailProps) =>
    adobeExpDetailProps?.data?.responseTokens?.forEach(async (response) => {
      const cohortId = response['experience.id'];
      const experimentId = response['activity.id'];
      const cohortName = response['experience.name'];
      if (
        !pastCohortAtValue ||
        !pastCohortAtValue[experimentId] ||
        pastCohortAtValue[experimentId]?.cohortId != cohortId
      ) {
        await apolloClient.mutate({
          mutation: PUBLISH_EXPERIMENT_EVENT_MUTATION,
          variables: {
            cohortId: cohortId,
            experimentId: experimentId,
            cohortName: cohortName,
            url: window.location.pathname,
          },
        });
      }
    })
  );
};

export default usePublishExperimentEvent;
