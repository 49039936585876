/**
 * URLs for pages outside of the SPA, mainly used for external redirects.
 * Should match https://github.com/myglam/ipsy-web-core/blob/master/ipsy-web-core-plugin/src/groovy/com/ipsy/web/IpsyUrl.groovy.
 */
export const ABSOLUTE_URLS = {
  CHANGE_PASSWORD_RESET: '/account/general/?passwordIsReset=1',
  QUIZ: '/quiz/take/questions',
  START_QUIZ: '/quiz/take/questions',
  QUIZ_SIGNUP: '/quiz/take/questions/signup',
  QUIZ_RETAKE: '/quiz/retake/questions',
  QUIZ_RETAKE_SIGNUP: '/quiz/retake/questions/signup',
  GLAM_BAG: '/glambag',
  GLAM_BAG_CHOICE: '/choice/glambag',
  GLAM_BAG_HOME: '/glambag?homepage=true',
  NEW_CHECKOUT: '/checkout',
  REFRESHMENTS: '/refreshments',
  SUBSCRIBE: '/subscribe',
  SUB_CHOOSE_PAGE: '/subscribe/choose',
  SUB_SPA_CHOOSE_PAGE: '/subscribe/choose-package',
  SUB_CHECKOUT_PAGE: '/subscribe/checkout',
  JOIN_NOW: '/join-now',
  REACTIVATE: '/reactivate-subscription',
  FLASH_SALES: '/shop/i/ipsy-flash',
  SPLASH: '/',
  UP_GBX: '/ultra-personalization/gbx',
  UP_FULLSIZE: '/ultra-personalization/fullsize',
  LOGIN: '/?forceLogin=1&redirectUrl={URL}&reason=unauthorized',
  SIGNUP: '/signup',
  POINTS: '/points',
  EARN_POINTS_BAG_SHARING:
    '/refer-a-friend?refFrom=earn_more_points_after_bag_sharing&cid=earn_more_points_after_bag_sharing',
  MEMBERSHIP_CONFIRMATION: '/membership-confirmation',
  IGW_GBX_CONFIRMATION: '/gbx-upgrade/confirmation',
  IGW_CONFIRMATION: '/subscribe/confirm',
  GBX_CHECKOUT: '/gbx/checkout',
  GBX_ONE_CLICK: '/gbx/one-click',
  BB_LANDING: '/beauty-boost/organic/landing',
  BB_CHECKOUT: '/beauty-boost/organic/checkout',
  BB_OPTOUT_CONFIRMATION: '/beauty-boost/opt-out/confirmation',
  BB_OPTOUT: '/beauty-boost/opt-out',
  BB_MANAGE: '/beauty-boost/manage',
  ACCOUNT: '/account',
  ACCOUNT_GENERAL: '/account/general',
  PAYMENT_RETRY: '/account/general/billing/editPayment',
  ANNUAL_UPGRADE: '/annual-upgrade',
  ORDER_HISTORY_OLD: '/shop/account/orders',
  ORDER_HISTORY: '/account/orders',
  SHOP: '/shop',
  ASSETS_API: '/assets',
  BLOG_ARTICLE_BASE: '/blog',
  FORGOT_PASSWORD: '/forgot-password',
  ACCOUNT_SUB: '/account/general/subscription#/subscription',
  ACCOUNT_BILLING: '/account/general/subscription#/billing',
  ACCOUNT_PROFILE: '/account/profile',
  LOGIN_CONFIRMATION: '/login-confirmation',
  CANCELLATION: '/cancellation',
  RESET_PASSWORD_CONFIRMATION: '/?passwordIsReset=1',
  LOGGED_IN_SHOP: '/shop',
  REACTIVATE_SUBSCRIPTION_CONFIRMATION: '/reactivate-subscription/confirm',
};

export const ACCOUNT_ADD_ADDRESS = '/account/add-address';
export const ACCOUNT_SHIPPING_ADDRESS = '/account/select-address';
export const ACCOUNT_SELECT_ADDRESS = '/account/select-address';

export enum CAMPAIGN_NAMES {
  ADS = 'gift_with_subscription_ads',
  REFERRAL_GIFT = 'referral_gift',
  REFERRAL_FREE_CREDIT = 'referral_one_free_credit',
  FREEBAG = 'freebag',
}

export const SLASHED_PRICE = 'SLASHED_PRICE';

export enum SUBSCRIPTION_FLOWS {
  ACTIVATION = 'ACTIVATION',
  REACTIVATE = 'REACTIVATE',
  ADD = 'ADD',
  SWITCH = 'SWITCH',
  DOWNGRADE = 'DOWNGRADE', // Used when switching from BoxyCharm to Glambag
  UPGRADE = 'UPGRADE', // Used when switching from Glambag to BoxyCharm
  UP = 'UP',
  ADDONS = 'ADD-ONS',
  ORGANIC = 'ORGANIC',
  UES = 'UES',
}

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NAME_REGEX = /^[a-z ,.'-]+$/i;

export const NO_SPECIAL_CHAR_REGEX = /^[a-zA-Z0-9 !?.,'-]*$/i;

export enum REFERER_PAGE {
  GLAMBAG = 'GLAMBAG',
}

export const CURRENT_SHOP_PATH_KEY = 'CURRENT_SHOP_PATH_KEY';

export const ADDONS_MAX_CART_ITEMS = 15;
