import gql from 'graphql-tag';

const GET_GVS_VAR = gql`
  query getGvsVar($key: String!) {
    me {
      layout {
        gvsVar: globalVariable(key: $key)
      }
    }
  }
`;

export default GET_GVS_VAR;
