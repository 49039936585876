import gql from 'graphql-tag';

const PUBLISH_EXPERIMENT_EVENT_MUTATION = gql`
  mutation publishExperimentEvent(
    $cohortName: String!
    $cohortId: String!
    $experimentId: String!
    $url: String!
  ) {
    events {
      publishExperimentEvent(
        cohortName: $cohortName
        cohortId: $cohortId
        experimentId: $experimentId
        url: $url
      )
    }
  }
`;

export default PUBLISH_EXPERIMENT_EVENT_MUTATION;
