import { cookieService } from '@bfa/nextjs-common/services';
import { AppContext } from 'next/app';

import { CampaignResponse } from '../../api/iwc';

const DEFAULT_CAMPAIGN_COOKIE_ATTRS = {
  httpOnly: 'true', // cookie should not be modifiable on client
};

/**
 * Set cookies returned by campaign endpoint to response.
 * If a cookie already exists in response header, it's skipped.
 *
 * @param appCtx - App context.
 * @param response - Campaign response body.
 */
const setCampaignCookies = (appCtx: AppContext, response: CampaignResponse) => {
  const { ctx } = appCtx;

  if (!ctx.res) return;

  const existingCookieMap = cookieService.parseSetCookieHeader(
    ctx.res.getHeader('Set-Cookie'),
    {
      map: true,
    }
  );

  const campaignCookies = response.allCampaignData
    .map((campaignData) => campaignData.cookie_data || [])
    .reduce((allCookies, cookie) => allCookies.concat(cookie), []);

  campaignCookies.forEach((cookie) => {
    if (existingCookieMap[cookie.name]) return;

    const { name, value, max_age: maxAge } = cookie;

    // set as session cookie if maxAge is -1
    const cookieOptions: any = { ...DEFAULT_CAMPAIGN_COOKIE_ATTRS };
    if (maxAge !== -1) {
      cookieOptions.maxAge = maxAge;
    }
    if (ctx.req) {
      /**
       * Append cookie to request headers and make it
       * available to apollo client.
       */
      ctx.req.headers.cookie = [
        ctx.req.headers.cookie,
        `${name}=${value}`,
      ].join(';');
    }
    cookieService.setCookie(ctx, name, value, cookieOptions);
  });
};

export default setCampaignCookies;
