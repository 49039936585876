import { cookieService } from '@bfa/nextjs-common/services';
import { AppContext } from 'next/app';

import { CampaignResponse } from '../../api/iwc';
import { CAMPAIGN_NAMES } from '../../constants';
import CookieName from '../../utils/cookies/cookieName';

/**
 * A helper function to check if user is getting a referral gift.
 * Should set a cookie to response if true.
 *
 * @param appCtx - The next page context object.
 * @param responseBody - The response body object.
 */
export const setReferralGiftCookie = (
  appCtx: AppContext,
  responseBody: CampaignResponse
) => {
  const { ctx } = appCtx;
  const { allCampaignData } = responseBody;

  const referralGiftCampaign = allCampaignData.find(
    ({ name }) => name === CAMPAIGN_NAMES.REFERRAL_GIFT
  );

  if (!referralGiftCampaign) return;

  if (referralGiftCampaign.IPSY_REFERRAL_GIFT) {
    cookieService.setCookie(ctx, CookieName.IPSY_REFERRAL_GIFT, 'true');
  }
};
