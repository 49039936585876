import { adobeTarget } from '@bfa/nextjs-common/utils';

export const getViewName = (path: string): string => {
  if (path === '/') return 'home';
  else return path.replace(/^\//g, '').replace(/\//g, '-');
};

/**
 * Notify to Adobe Target that a page has changed,
 * calling triggerView function with the view name as parameter.
 * In this case, we notify the render of a new step in the quiz.
 *
 * @param viewName String with the label of the step. It is the step title without spaces
 */
export const triggerAdobeTargetView = (viewName: string): void =>
  typeof adobeTarget()?.triggerView === 'function' &&
  adobeTarget().triggerView(viewName, { page: true });
