import { isClient } from '../../../utils/isClient';
import { CANCELLATION_STATE_KEY } from '../constants';
import { CancellationNavigationData } from '../types';

const storeCancellationNavigation = (
  cancellationNavigation: CancellationNavigationData
): void => {
  if (isClient) {
    sessionStorage.setItem(
      CANCELLATION_STATE_KEY,
      JSON.stringify(cancellationNavigation)
    );
  }
};

const loadCancellationNavigation = ():
  | CancellationNavigationData
  | undefined => {
  if (isClient) {
    const storageData = sessionStorage.getItem(CANCELLATION_STATE_KEY);
    return storageData ? JSON.parse(storageData) : undefined;
  }
};

export { loadCancellationNavigation, storeCancellationNavigation };
