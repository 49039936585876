import { analyticsService } from '@bfa/nextjs-common/services';
import { logError } from '@bfa/nextjs-common/utils';
import { NextRouter } from 'next/router';

import { AnalyticsEvent } from '../types';
import { ANALYTICS_EVENT, AnalyticsEventKey } from './constants';

/**
 * Log an event to both GA and analytics service.
 *
 * @param eventInfo - An analytics event object.
 * @param eventInstrumentationName - EventName for instrumentation service.
 * @param capitalize - Contemplate special cases where naming doesn't have to transform.
 * @returns If event get fired successfully.
 */
export const logAnalyticsEvent = (
  eventInfo: AnalyticsEvent,
  eventInstrumentationName: string | null = null,
  capitalize = true
): boolean => {
  const analyticsEvent = ANALYTICS_EVENT[eventInfo.name as AnalyticsEventKey];
  if (!analyticsEvent) {
    logError(
      `[AnalyticsService.logEvent] Missing analytics event category and action: ${JSON.stringify(
        eventInfo
      )}`
    );
    return false;
  }

  return analyticsService.logEvent(
    {
      ...analyticsEvent,
      ...eventInfo,
    },
    eventInstrumentationName,
    capitalize
  );
};

/**
 * Reads deferred events from the current URL and logs them.
 *
 * @param href - Url to add deferred events to.
 * @param events - Events to add to url.
 * @returns Url with deferred events in query param.
 */
export const buildDeferredEventsUrl = (
  href: string,
  events: AnalyticsEvent | AnalyticsEvent[]
) => {
  const mappedEvents = (!Array.isArray(events) ? [events] : events).map(
    (event) => ({
      ...event,
      category: '',
      action: event.action ?? '',
    })
  );

  return analyticsService.buildDeferredEventsUrl(href, mappedEvents);
};

/**
 * Reads deferred events from the current URL and logs them.
 *
 * @param router - {@link NextRouter}.
 */
export const fireDeferredEvents = (router: NextRouter): void => {
  analyticsService.fireDeferredEvents(router, ANALYTICS_EVENT);
};

export const getLabel = (pageName: string) => {
  switch (pageName) {
    case 'EDIT_BILLING_PAGE':
      return 'Edit Billing Page';

    case 'COMMERCE_CHECKOUT':
      return 'Commerce Checkout Page';

    default:
      return 'Unknown Source Page';
  }
};
