import { ApolloClient } from '@apollo/client';
import { ClickstreamEventSchemaV0 } from '@bfa/nextjs-common/services/instrumentationService/schemas/v0/types';
import { NextPageContext } from 'next';
import { AppContext } from 'next/app';

import { Flows } from '../features/Checkout/types';
import { ExtendedGQLProduct } from '../features/ExtremeCustomization/pages/Addons/helpers/addonsEventsHelpers';
import { GQLCollectionProducts, GQLProduct } from '../gql.generated';
import { AnalyticsEventKey } from './analytics/constants';

export interface TagCollection {
  [indexer: string]: string;
}

export type PageMetaTags = {
  page: string;
  tags: TagCollection;
};

export type CustomAppContext = AppContext & {
  ctx: CustomPageContext;
  apolloClient: ApolloClient<any>;
  apolloState: any;
  deviceInfo: DeviceInfo;
};

export interface CustomPageContext extends NextPageContext {
  apolloClient: ApolloClient<any>;
  apolloState: any;
  deviceInfo: DeviceInfo;
}

export enum DeviceType {
  IPHONE = 'IPHONE',
  IPAD = 'IPAD',
  ANDROID_PHONE = 'ANDROID_PHONE',
  ANDROID_TABLET = 'ANDROID_TABLET',
  UNSPECIFIED_APPLE_DEVICE = 'UNSPECIFIED_APPLE_DEVICE',
  UNKNOWN_DEVICE = 'UNKNOWN_DEVICE',
}

export enum CartState {
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
}

export enum PlatformType {
  WEB_APP = 'WEB_APP',
  IOS_APP = 'IOS_APP',
  ANDROID_APP = 'ANDROID_APP',
}

export type DeviceInfo = {
  deviceType: DeviceType | string;
  platformType: PlatformType | string;
  os: string;
  osVersion: string;
  userAgent: string;
  name?: string;
  platformVersion?: string;
  platformOS?: string;
  deviceModelIdentifier?: string;
  deviceModelName?: string;
  idfa?: string;
  idfv?: string;
};

export type AnalyticsEvent = {
  name: AnalyticsEventKey;
  label: string;
  action?: string;
  shouldAddSessionDelta?: boolean;
  properties?: ClickstreamEventSchemaV0['eventPayload'];
  isExtremeAddons?: boolean;
};

export enum HeaderName {
  TRACKING_ID = 'ipsy-tracking-id',
  REQUEST_ID = 'ipsy-request-id',
  SESSION_ID = 'ipsy-session-id',
  USER_ID = 'ipsy-user-id',
  SID = 'x-ipsy-sid',
  FORWARDED_FOR = 'x-forwarded-for',
  EXPERIMENTS = 'x-ipsy-experiments',
  USER_AGENT = 'user-agent',
  AUTHORIZATION = 'x-ipsy-authorization',
  COOKIE = 'cookie',
  AT_SERVER_STATE = 'x-at-server-state',
}

export type HeadersType = {
  [HeaderName.TRACKING_ID]: string;
  [HeaderName.REQUEST_ID]: string;
  [HeaderName.SESSION_ID]: string;
  [HeaderName.USER_ID]: string | null;
  [HeaderName.SID]: string;
  [HeaderName.FORWARDED_FOR]: string;
};

export enum DeviceLayout {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export type Callback = () => void;

export type CmsImage = {
  src: string;
  alt: string;
  height?: number | null;
  width?: number | null;
};

export type CmsTextAsset = {
  value: string;
};

export type NonEmptyArray<T> = [T, ...(T | null)[]];
export interface GQLProductQuantity extends GQLProduct {
  quantity?: number;
}

export type toggleProductProps = (
  productInfo: ExtendedGQLProduct,
  collection: GQLCollectionProducts,
  quantity?: number
) => void;

// Utility type to make a type structure attributes optional
// and their nested attibutes optional as well
// Same as the native typescript Partial<T> but recursive
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type FBPixelEventInfo = {
  paymentType?: string;
  subscriptionCadence?: string;
  subscriptionProgram?: string;
};

/**
 * Generic structure used for the subscription checkout page
 */
export interface CmsGenericSubscriptionCheckoutPage {
  cmsidentifier: string;
  checkoutheader?: string;
  checkoutpaymentsubheader?: string;
  checkoutupgradeprogram?: string;
  checkoutprogramupgradeprice?: string;
  checkoutnewannualupgradeprice?: string;
  checkoutcancellationtext?: string;
  checkoutbuttoncta?: string;
  checkoutnotificationmonthlybulletpoint?: string;
  checkoutsubscriptiondisclaimer?: string;
  checkoutshippingdisclaimer?: string;
  checkoutshippinglearnmore?: string;
  checkoutinfoheader?: string;
  checkoutinfopointone?: string;
  checkoutinfopointtwo?: string;
  checkoutnopaymenterrormsg?: string;
  checkoutchargeimmediatelyoffdisclaimer?: string;
  analyticsEventLabelViewed?: string;
  analyticsEventLabelClicked?: string;
  analyticsEventLabelError?: string;
}

export type AdobeResponseTokenProps = {
  'activity.id': string;
  'experience.id': string;
  'experience.name': string;
};

export type AdobeExpDetailProps = {
  data: {
    responseTokens: AdobeResponseTokenProps[];
  };
};

export type AdobeExpCookieProps = {
  [expId: string]: {
    cohortId: string;
    lastReceivedDate: number;
  };
};

export type AdobeExpDetailsProps = {
  execution: AdobeExpDetailProps[];
};

export type AdobeExpProps = {
  detail: AdobeExpDetailsProps;
};

export type HandleSubmitFnParams = {
  lastSeenTotal: number | null;
  transactionRequestUuid: string | null;
  oneTimePaymentMethodId?: string | null;
  couponCode?: string;
  flow: Flows;
};

export type HandleSubmitFn = (params: HandleSubmitFnParams) => Promise<void>;
